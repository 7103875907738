import { addAssetToDatabase, getAssetsFromDatabase } from 'js/shared/lib/LocalDatabase';
import { AudioSource, VSCAssetAudioSourceName, VSCAssetImageSourceName } from 'js/types';

import config from '../../../../config/config';
import UserModel from '../UserModel';
import { getRendererAuth, getUID } from '../../TokenHelper';
import { appUrl } from '../../url';
import { makeServicesFetch } from '../../requestLimiter';
import { validateCognitoSession } from '../../authHelper';
import { rateLimitedfetch } from '../../rateLimitedFetch';

import { getServicesUrl, getObject, getOptions, isHttpFail, validateResponse } from './helpers';

const PLATFORM = navigator.userAgent.includes('X-FunScribe') ? 'mobile' : 'web';
const VERSION = `FUNSCRIBE:${import.meta.env.VITE_VSC_VERSION}:${PLATFORM}`;

class AppServices {
  constructor() {
    this._baseUrl = getServicesUrl();

    this.exchangeCognitoTokenForSparkolToken = this.exchangeCognitoTokenForSparkolToken.bind(this);
  }

  async exchangeCognitoTokenForSparkolToken(idToken) {
    const baseUrl = this?._baseUrl || getServicesUrl();

    const url = `${baseUrl}authorize`;
    const dontIncludeSparkolToken = true;
    const options = getOptions(
      'POST',
      JSON.stringify({
        version: VERSION,
        platform: PLATFORM,
        environment: import.meta.env.MODE,
        application: 'videoscribe-cloud'
      }),
      null,
      dontIncludeSparkolToken
    );

    const headers = { ...options.headers, 'x-spk-id-token': idToken };
    const result = await rateLimitedfetch(url, {
      ...options,
      headers
    });

    if (isHttpFail(result.status)) {
      const errorPayload = await result.json();
      const appServicesError = new Error(errorPayload.userMessage);
      appServicesError.httpStatusCode = result.status;
      appServicesError.errorCode = errorPayload.error;
      return Promise.reject(appServicesError);
    }

    const sparkolToken = result.headers.get('x-spk-auth-token');
    const sparkolTokenTtl = result.headers.get('x-spk-auth-token-ttl');
    const { user } = await result.json();

    return new UserModel(user, { sparkolToken, sparkolTokenTtl });
  }

  authenticate(email, password) {
    const url = `${this._baseUrl}session`;
    const options = getOptions(
      'POST',
      JSON.stringify({
        username: email,
        password,
        application: 'funscribe',
        environment: import.meta.env.MODE,
        platform: PLATFORM,
        version: VERSION
      }),
      null,
      true
    );

    return rateLimitedfetch(url, options)
      .then(r => validateResponse(r, `Error creating a user session.`))
      .then(async response => {
        const { user, userTokens } = await response.json();

        return new UserModel(user, {}, userTokens);
      });
  }

  deleteSession = () => {
    const url = `${this._baseUrl}session`;
    const options = getOptions('DELETE');
    return this.servicesFetchNoReload(url, options)
      .then(r => validateResponse(r, `Error deleting session`))
      .catch(error => console.error(error));
  };

  renderCloud = async ({ scribeId, visibility, segments, format, assets, videoDuration }) => {
    const url = `${this._baseUrl}sho-co/render/cloud`;
    const body = {
      application: 'videoscribe-cloud',
      segments,
      assets,
      source: scribeId,
      applicationUrl: appUrl(),
      visibility,
      format,
      videoDuration
    };
    const options = getOptions('POST', JSON.stringify(body));
    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error calling the renderer.`))
      .then(res => res.json());
  };

  abortRenderCloud = shortId => {
    const url = `${this._baseUrl}sho-co/render/cloud/${shortId}`;

    const options = getOptions('DELETE');
    return this.servicesFetch(url, options).then(r => validateResponse(r));
  };

  fetchRenderProgress = shortId => {
    const url = `${this._baseUrl}sho-co/render/cloud/${shortId}/progress`;
    const options = getOptions('GET');
    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error fetching render progress.`))
      .then(res => res.json());
  };

  getVideoDownloadUrl = (shortId, format) => {
    const url = `${this._baseUrl}sho-co/render/cloud/download/url/${shortId}/${format}`;
    const options = getOptions('GET');
    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error fetching the render URL.`))
      .then(res => res.json());
  };

  getUserForRender(projectId) {
    const { rendererUser: uid } = getRendererAuth();
    const url = `${this._baseUrl}user/${uid}?projectId=${projectId}`;
    const options = getOptions('GET');

    return this.servicesFetch(url, options)
      .then(response => validateResponse(response, `Error fetching render user details for user id ${uid}`))
      .then(response => response.json())
      .then(user => {
        return new UserModel(user, {}, {});
      });
  }

  setCustomColors = async colorArray => {
    const key = 'customColors';
    const value = colorArray;
    return this.setAppData(key, value);
  };

  getCustomColors = async () => {
    const key = 'customColors';
    return this.getAppData(key);
  };

  // assets
  getAppData(key) {
    const uid = getUID();
    const url = `${this._baseUrl}user/${uid}/app-data/videoscribe-cloud/${key}`;
    const options = getOptions('GET', undefined, undefined, false);

    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error fetching app data.`))
      .then(getObject);
  }

  setAppData(key, value) {
    const uid = getUID();
    const url = `${this._baseUrl}user/${uid}/app-data/videoscribe-cloud/${key}`;
    const options = getOptions('PUT', JSON.stringify(value));
    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error setting app data.`))
      .then(r => r.json());
  }

  getDownloads() {
    const url = `${this._baseUrl}user/${getUID()}/downloads`;
    const options = getOptions('GET');

    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error fetching downloads.`))
      .then(getObject);
  }

  bulkLoadAssets = async (assetIds, thumb, signedUrlsFromPreview) => {
    const signedUrls = signedUrlsFromPreview ?? (await this.getSignedUrls(assetIds));

    const blobs = await Promise.all(
      signedUrls.map(signedUrl => {
        const url = thumb ? signedUrl.thumbs[0]?.url ?? signedUrl.url : signedUrl.url;
        return window.fetch(url).then(response => {
          if (!response.ok) throw new Error('Unable to load asset ', signedUrl.assetId);
          return response.blob();
        });
      })
    );

    const results = signedUrls.map((signedUrl, index) => {
      const assetId = thumb ? `${signedUrl.assetId}-thumb` : signedUrl.assetId.toString();
      return {
        assetId,
        assetBlob: blobs[index]
      };
    });

    await Promise.all(
      results.map(result => {
        return addAssetToDatabase(result.assetId, result.assetBlob);
      })
    );

    return results;
  };

  blobUrlCache = new Map();

  mapAssetToBlobUrl = asset => {
    const blobUrl = this.blobUrlCache.get(asset.assetId) ?? URL.createObjectURL(asset.assetBlob);
    this.blobUrlCache.set(asset.assetId, blobUrl);

    return {
      assetId: asset.assetId,
      blobUrl
    };
  };

  getAssetUrls = async (assetIds, thumb, signedUrls) => {
    const assetIdsSearch = assetIds.map(id => (thumb ? `${id}-thumb` : id.toString()));

    const assetsInDatabase = (await getAssetsFromDatabase(assetIdsSearch)).filter(Boolean);
    const assetIdsInDatabase = assetsInDatabase.map(asset => asset.assetId);
    const assetIdsNotInDatabase = assetIdsSearch.filter(assetId => !assetIdsInDatabase.includes(assetId.toString()));
    const assetIdsToBulkLoad = thumb
      ? assetIdsNotInDatabase.map(id => id.replaceAll('-thumb', ''))
      : assetIdsNotInDatabase;

    const assetsFromNetwork = assetIdsNotInDatabase.length
      ? (await this.bulkLoadAssets(assetIdsToBulkLoad, thumb, signedUrls)).map(asset => this.mapAssetToBlobUrl(asset))
      : [];
    const assetsFromDatabase = assetsInDatabase.map(asset => this.mapAssetToBlobUrl(asset));

    return [...assetsFromNetwork, ...assetsFromDatabase];
  };

  getAssetUrl = async (assetId, thumb) => {
    const assets = await this.getAssetUrls([assetId], thumb);
    const asset = assets[0];

    return asset.blobUrl;
  };

  deleteAsset = assetId => {
    const url = `${this._baseUrl}project-asset/${assetId}`;
    const options = getOptions('DELETE');

    return this.servicesFetch(url, options);
  };

  getAssetsList = assetType => {
    const url = `${this._baseUrl}project-asset?type=${assetType}`;
    const options = getOptions('GET');

    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error fetching your project assets list.`))
      .then(getObject);
  };

  // the projectId is included in the request to ensure a render doesn't receive
  // a response for a different render
  getFontsList = scribeId => {
    const url = `${this._baseUrl}project-asset?type=font&projectId=${scribeId}`;
    const options = getOptions('GET');

    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error fetching your fonts list.`))
      .then(getObject);
  };

  getSignedUrls = async assetIds => {
    const url = `${this._baseUrl}project-assets?assetIds=${assetIds.join(',')}`;
    const options = getOptions('GET');

    return rateLimitedfetch(url, options)
      .then(r => validateResponse(r, `Error requesting your image assets`))
      .then(getObject)
      .then(async ({ signedUrls }) => {
        return signedUrls;
      });
  };

  _uploadAsset = ({ file, filename, source, sourceIdentifier, isPremium }, abortSignal) => {
    const url = `${this._baseUrl}project-asset`;
    const addSourceInfo =
      Object.values(VSCAssetAudioSourceName).includes(source) ||
      Object.values(VSCAssetImageSourceName).includes(source);

    const formData = new FormData();
    formData.append('assetFile', file, filename);

    if (addSourceInfo && source) formData.set('source', source);
    if (addSourceInfo && sourceIdentifier) formData.set('sourceIdentifier', sourceIdentifier);
    if (addSourceInfo && isPremium) formData.set('isPremium', 'true');

    const options = { ...getOptions('POST', formData, 'auto'), signal: abortSignal };

    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error uploading your project asset.`))
      .then(getObject);
  };

  /**
   *
   * @param {{ file: Blob, filename: string, source?: string, sourceIdentifier?: string }} asset
   * @param {AbortSignal} [abortSignal]
   * @returns
   */
  uploadAsset = async ({ file, filename, source, sourceIdentifier, isPremium }, abortSignal) => {
    const sourceNameMap = {
      [AudioSource.RECORDED]: VSCAssetAudioSourceName.USER_RECORDING,
      [AudioSource.UPLOADED]: VSCAssetAudioSourceName.USER_UPLOAD,
      [AudioSource.AI_GENERATED]: VSCAssetAudioSourceName.SPARKOL_AI_GENERATED,
      library: VSCAssetImageSourceName.SPARKOL_IMAGE
    };

    const sourceName = sourceNameMap[source] ?? source;

    const assetId = await this._uploadAsset(
      { file, filename, source: sourceName, sourceIdentifier, isPremium },
      abortSignal
    );

    // Populate asset database on upload
    addAssetToDatabase(assetId, file);

    return assetId;
  };

  updateAssetMetadata = async (assetId, metadata) => {
    const url = `${this._baseUrl}project-asset/${assetId}`;
    const options = getOptions('PATCH', JSON.stringify(metadata));

    return this.servicesFetch(url, options)
      .then(r => validateResponse(r))
      .then(res => res.json());
  };

  getCursors = () => {
    const url = `${this._baseUrl}library/hands?results=1000`;
    const options = getOptions('GET');

    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error fetching scribe cursors.`))
      .then(res => res.json())
      .then(res => res.items);
  };

  getCursorsForPreview = () => {
    const url = `${this._baseUrl}library/hands?results=1000`;
    const options = getOptions('GET');

    return window
      .fetch(url, options)
      .then(r => validateResponse(r, `Error fetching scribe cursors.`))
      .then(res => res.json())
      .then(res => res.items);
  };

  getAudioLibrary = () => {
    const url = `${this._baseUrl}library/music`;
    const options = getOptions('GET');
    return window
      .fetch(url, options)
      .then(r => validateResponse(r))
      .then(res => res.json())
      .then(res => res.items);
  };

  createProjectFromTemplate = templateId => {
    const url = `${this._baseUrl}project-data/from-template/${templateId}`;
    const options = getOptions('POST');

    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error creating project from template.`))
      .then(res => res.json());
  };

  createTemplateFromProject = async projectId => {
    const { accessToken } = await validateCognitoSession();

    const url = `${this._baseUrl}template/from-project/${projectId}`;
    const options = getOptions('POST', undefined, undefined, undefined, accessToken.jwtToken);
    return this.servicesFetchNoReload(url, options)
      .then(r => validateResponse(r, 'Error creating template from project'))
      .then(res => res.json());
  };

  getProjectForPreview = async publicSharingId => {
    const url = `${this._baseUrl}share-project/${publicSharingId}`;

    const options = {
      method: 'GET',
      headers: { 'x-spk-app-name': 'videoscribe-cloud' }
    };
    return window
      .fetch(url, options)
      .then(r => {
        return validateResponse(r);
      })
      .then(res => res.json());
  };

  addSignedUrlsToCache = async signedUrls => {
    const assetIds = signedUrls.map(sUrl => sUrl.assetId);
    const cachedUrls = await this.getAssetUrls(assetIds, false, signedUrls);
    return cachedUrls;
  };

  getProjectSharingId = async projectId => {
    const { accessToken } = await validateCognitoSession();
    const url = `${this._baseUrl}share-project/${projectId}`;

    const options = getOptions('PUT', undefined, undefined, undefined, accessToken.jwtToken);
    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, 'Error creating preview link'))
      .then(res => res.json());
  };

  createProjectAssetFromLibrary = async (library, libraryItemId) => {
    const url = `${this._baseUrl}project-asset`;
    const options = getOptions(
      'POST',
      JSON.stringify({
        library,
        id: libraryItemId
      }),
      'application/json'
    );

    return this.servicesFetch(url, options)
      .then(r => validateResponse(r))
      .then(res => res.json());
  };

  getAcceptedTerms = async () => {
    const url = `${this._baseUrl}user/${getUID()}/config/accepted-terms`;
    const options = getOptions('GET');

    return this.servicesFetch(url, options)
      .then(r => validateResponse(r))
      .then(res => res.json());
  };

  sendAcceptedTerms = async () => {
    const url = `${this._baseUrl}user/${getUID()}/config/accepted-terms`;
    const options = getOptions('POST', JSON.stringify({ acceptedTerms: true }));

    return this.servicesFetch(url, options).then(r => validateResponse(r));
  };

  sendAcceptedMarketing = async () => {
    const url = `${this._baseUrl}user/${getUID()}/accept-marketing`;
    const options = getOptions('POST');

    return this.servicesFetch(url, options).then(r => validateResponse(r));
  };

  servicesFetch = makeServicesFetch({
    limit: config.SERVICES_REQUEST_RATE_LIMIT,
    maxConcurrent: config.SERVICES_REQUEST_CONCURRENCY,
    reloadOnFail: true,
    getUserByToken: this.exchangeCognitoTokenForSparkolToken
  });

  servicesFetchNoReload = makeServicesFetch({
    limit: config.SERVICES_REQUEST_RATE_LIMIT,
    maxConcurrent: config.SERVICES_REQUEST_CONCURRENCY,
    reloadOnFail: false,
    getUserByToken: this.exchangeCognitoTokenForSparkolToken
  });

  generateImages = async (model, prompt, genQuantity) => {
    const url = `${this._baseUrl}image-generator/execute`;
    const body = {
      model,
      prompt,
      noGenerations: genQuantity
    };
    const options = getOptions('POST', JSON.stringify(body));
    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error calling image generator.`))
      .then(res => res.json());
  };

  fetchImageGenerationProgress = executionId => {
    const url = `${this._baseUrl}image-generator/execution/${executionId}/status`;
    const options = getOptions('GET');
    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error fetching image generation progress.`))
      .then(res => res.json());
  };

  generateVoiceover = async (text, locale, gender, voiceName) => {
    const url = `${this._baseUrl}voiceover-generator/execute`;
    const body = {
      lang: locale,
      voice: voiceName,
      gender,
      text
    };
    const options = getOptions('POST', JSON.stringify(body));
    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error calling voiceover generator.`))
      .then(res => res.json());
  };

  fetchVoiceoverGenerationProgress = executionId => {
    const url = `${this._baseUrl}voiceover-generator/execution/${executionId}`;
    const options = getOptions('GET');
    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error fetching voiceover generation progress.`))
      .then(res => res.json());
  };

  putProjectScript = (projectId, projectScript) => {
    const url = `${this._baseUrl}project-script/${projectId}`;
    const options = getOptions('PUT', JSON.stringify({ projectScript }));
    return this.servicesFetch(url, options).then(r => validateResponse(r, `Error updating project script.`));
  };

  getProjectScript = projectId => {
    const url = `${this._baseUrl}project-script/${projectId}`;
    const options = getOptions('GET');
    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error fetching project script.`))
      .then(res => res.json());
  };

  deleteProjectScript = projectId => {
    const url = `${this._baseUrl}project-script/${projectId}`;
    const options = getOptions('DELETE');
    return this.servicesFetch(url, options).then(r => validateResponse(r, `Error deleting project script.`));
  };

  generateScript = async (prompt, lengthInSeconds, numberOfScenes, styles) => {
    const url = `${this._baseUrl}script-generator/execute`;
    const body = {
      prompt,
      lengthInSeconds,
      numberOfScenes,
      styles
    };
    const options = getOptions('POST', JSON.stringify(body));
    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error calling script generator.`))
      .then(res => res.json());
  };

  getLimits = async () => {
    const url = `${this._baseUrl}limits`;
    const options = getOptions('GET');
    return this.servicesFetch(url, options)
      .then(r => validateResponse(r, `Error fetching limits.`))
      .then(res => res.json());
  };
}

export const appServices = new AppServices();
