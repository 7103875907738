import { setElementsAnimationDrawType } from 'js/actionCreators/animationConfigActions';
import { DRAW_TYPE_ILLUSTRATE, selectableDrawTypeOptions } from 'js/config/animationOptions';
import { DrawType, ElementAnimationStage, ExistingScribeModel, VSImageShapeOrTextElementModel } from 'js/types';
import React from 'react';
import { useDispatch } from 'react-redux';

import ScribelyIconButton from '../ScribelyIconButton';

import { AnimationStagePanelOptionsGrid } from './AnimationStagePanel';

interface DrawTypeMenuProps {
  element?: VSImageShapeOrTextElementModel;
  elements?: Array<VSImageShapeOrTextElementModel>;
  scribe: ExistingScribeModel;
  onSelection: () => void;
  stageKey: ElementAnimationStage;
}

export const getSelectedDrawTypeValue = (elements: Array<VSImageShapeOrTextElementModel>) => {
  const allSameValue =
    !!elements.length &&
    elements.every(element => {
      return (
        (element.entranceAnimation?.config?.drawType ?? DRAW_TYPE_ILLUSTRATE) ===
        (elements[0].entranceAnimation?.config?.drawType ?? DRAW_TYPE_ILLUSTRATE)
      );
    });

  return allSameValue ? elements[0].entranceAnimation?.config?.drawType ?? DRAW_TYPE_ILLUSTRATE : 'mixed';
};

const DrawTypeMenu = ({ element, elements = [], scribe, onSelection, stageKey }: DrawTypeMenuProps) => {
  const dispatch = useDispatch();

  const elementsAsArray = !!element ? [element] : elements;

  const handleButtonClick = (drawType: DrawType) => {
    onSelection();
    dispatch(
      setElementsAnimationDrawType({
        elementIds: elementsAsArray.map(el => el.id),
        stage: stageKey,
        projectId: scribe.id,
        drawType
      })
    );
  };

  const currentSelectedValue = getSelectedDrawTypeValue(elementsAsArray);

  return (
    <AnimationStagePanelOptionsGrid>
      {selectableDrawTypeOptions.map(option => {
        return (
          <ScribelyIconButton
            outline={currentSelectedValue === option.value}
            key={option.id}
            icon={<option.Icon />}
            onClick={() => handleButtonClick(option.value)}
          >
            {option.label}
          </ScribelyIconButton>
        );
      })}
    </AnimationStagePanelOptionsGrid>
  );
};

export default DrawTypeMenu;
