import { detect } from 'detect-browser';
import { fabric } from 'fabric';
import { IEvent } from 'fabric/fabric-impl';
import { gsap } from 'gsap';
import shouldTextUseFallbackDrawing from 'js/shared/helpers/fallbackDrawingChecker';
import {
  CAMERA_COLOR,
  defaultCanvasColor,
  EDITOR_CANVAS_CONTROL_VISIBILITY,
  EDITOR_CANVAS_SELECTION_OPTIONS,
  MAX_CAM_ZOOM,
  MIN_CAM_ZOOM
} from 'js/config/defaults';
import { noCameras } from 'js/shared/helpers/elementFilters';
import { UpdatedTextElementConfig } from 'js/actionCreators/elementActions';
import {
  ElementType,
  ScribeCameraElement,
  ScribeScene,
  ScribeSettings,
  VSElementModel,
  TextElementCharBounds,
  CustomisableTextStyleProps
} from 'js/types';
import clamp from 'lodash.clamp';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import round from 'lodash.round';
import * as PIXI from 'pixi.js';
import { Point, Rectangle } from 'pixi.js';
import { UserWithPermissions } from 'js/permissions/permissionsHelpers';

import {
  ALIGN_ACTION_BOTTOM,
  ALIGN_ACTION_H_CENTER,
  ALIGN_ACTION_LEFT,
  ALIGN_ACTION_RIGHT,
  ALIGN_ACTION_TOP,
  ALIGN_ACTION_V_CENTER,
  AlignmentAction
} from '../EditPanels/AlignmentControls';
import { DISTRIBUTE_ACTION_HORIZONTAL, DistributionAction } from '../EditPanels/DistributionControls';
import Maths from '../Timeline/PixiTimeline/Utils/Maths';

import ActiveSelection from './ActiveSelection';
import CameraElement from './CameraElement';
import {
  assertCanvasElementType,
  assertIsTextCanvasElement,
  assertElementType,
  assertIsActiveSelection,
  assertIsCameraCanvasElement,
  assertIsElement,
  CanvasElement,
  getAlignmentRectangleFromBounds,
  isCameraElement,
  isImageElement,
  isShapeElement,
  isTextElement,
  ViewableCanvasElement
} from './helpers/canvasElementHelpers';
import createImageElement from './helpers/createImageElement';
import createShapeElement from './helpers/createShapeElement';
import { elementDimensionsHaveChanged } from './helpers/elementDimensionsHaveChanged';
import generateGridlines from './helpers/generateGridlines';
import getGroupVisibleProperties from './helpers/getGroupVisibleProperties';
import getLockedProperties from './helpers/getLockedProperties';
import initControlsAddons from './helpers/initControlsAddons';
import { isSVGElement } from './helpers/isSVGElement';
import ProjectFrame from './ProjectFrame';
import TextElement from './TextElement';

interface ReactableCanvasProps {
  user: UserWithPermissions;
  scene: ScribeScene;
  elements: Array<VSElementModel>;
  activeElements: Array<string>;
  projectSettings: ScribeSettings;
  gridlines: boolean;
  firstLoad: boolean;
  canvasDragOn: boolean;
  editorTransform?: number[];
  isPixiTimeline?: boolean;
  isLeftPanelOpen?: boolean;
}

interface CameraElementScalingPositions {
  leftPositions: Array<number>;
  rightPositions: Array<number>;
  topPositions: Array<number>;
  bottomPositions: Array<number>;
}

interface MaxScales {
  maxY: number;
  maxX: number;
  minX: number;
  minY: number;
}

interface ScaleLimits {
  lastGoodX: number;
  lastGoodY: number;
  minScaleX: number;
  minScaleY: number;
  lastGoodScaleX: number;
  lastGoodScaleY: number;
  tlScales: MaxScales;
  trScales: MaxScales;
  blScales: MaxScales;
  brScales: MaxScales;
}

export const EXPANDED_CANVAS_MULTIPLE = 12;
export const EXPANDED_CANVAS_SIZE = { width: 1920, height: 1920 };
const browser = detect();
const isMac = browser?.os?.toLowerCase().includes('mac');
const selectionKeys = isMac ? ['metaKey', 'shiftKey'] : ['ctrlKey', 'shiftKey'];

const CANVAS_CAMERA_INDEX_OFFSET = 1; // TODO: change this to 2 when we add scene cameras
const INITIAL_ACTIVE_SELECTION_SCALE = 1;
const PROPERTIES_FLOATING_POINT_PRECISION = 5;
export interface ReactableCanvasConfig {
  initialViewportSize: { width: number; height: number };
  onVisualUpdate: (thumb: string) => void;
  onUpdateScene: (elements: Array<VSElementModel>, thumb: string) => void;
  onSetActiveElements: (elementIds: Array<string>) => void;
  canvasSize: {
    width: number;
    height: number;
  };

  maxCanvasMultiplyer: number;
  maxScrollMultiplyer: number;
  onUpdateZoom: (zoom: number) => void;
  onUpdateMinZoom: (minZoom: number) => void;
  onUpdateScrollbars: (dimensions: {
    width: number;
    height: number;
    verticalOffset: number;
    horizontalOffset: number;
  }) => void;
  onPinActiveCameras: () => void;
  onUnpinActiveCameras: () => void;
  onSetFirstLoad: (value: boolean) => void;
  onUpdatePosOrZoom: (vpTransform: number[]) => void;
  onUpdateTextfieldDimensions: (updatedElementsConfig: Array<UpdatedTextElementConfig>) => void;
  onSetSelectedTextColor: (color: string | null | undefined) => void;
  handleSetIsTextboxEditing: (isEnabled: boolean) => void;
  onPremiumIndicatorClick: () => void;
}

export const DEFAULT_MIN_ZOOM = 0.25;
const ZOOM_DELTA_MULTIPLIER = 0.999;

const ZOOM_SPEED_MULTIPLIER = 0.005;
const HANDLE_VALUES = ['tl', 'mt', 'ml', 'mb', 'br', 'mr', 'bl', 'tr'];

// We adjust the base index to take into account elements on the canvas that don't move like gradient backgrounds
// so that we can manage the stacking order
const BACKGROUND_STACK_INDEX = 0;
const BASE_INDEX_ADJUSTMENT = BACKGROUND_STACK_INDEX + 1;

const assertCanvasElement = (
  object: fabric.Object & { id?: string | undefined; elementType?: ElementType }
): object is CanvasElement => {
  return !!object.id && !!object.elementType;
};

function getGroupMinScale(scaledDimension: number, limit: number) {
  // phl / (height * scaleY) = gScaleY
  const minScaleLimit = limit / scaledDimension;
  return minScaleLimit;
}
export default class ProjectCanvas extends fabric.Canvas {
  user: UserWithPermissions;
  interacting = false;
  sceneObjects: Array<fabric.Object> = [];
  maxCanvasMultiplyer: number;
  maxScrollMultiplyer: number;
  frame: ProjectFrame;
  props: ReactableCanvasProps;
  onVisualUpdate: (thumb: string) => void;
  onUpdateScene: (elements: Array<VSElementModel>, thumb: string) => void;
  onSetActiveElements: (elementIds: Array<string>) => void;
  onUpdateScrollbars: (dimensions: {
    width: number;
    height: number;
    verticalOffset: number;
    horizontalOffset: number;
  }) => void;
  canvasSize: {
    width: number;
    height: number;
  };
  onUpdateZoom: (zoom: number) => void;
  onUpdateMinZoom: (minZoom: number) => void;
  onPinActiveCameras: () => void;
  onSetFirstLoad: (value: boolean) => void;
  onUnpinActiveCameras: () => void;
  onUpdatePosOrZoom: (vpTransform: number[]) => void;
  elements?: Array<CanvasElement>;
  gridlines?: fabric.Group;
  isDragging: boolean;
  firstLoad: boolean;
  draggingStaged: boolean;
  checkForAutoScroll: boolean;
  autoscrollDragging: boolean;
  autoScrollTarget: { x: number; y: number } | null;
  firstLoadShowAll = true;
  lastPosX?: number;
  lastPosY?: number;
  contentBounds: {
    left: number;
    top: number;
    width: number;
    height: number;
  } = {
    left: 0,
    top: 0,
    width: 0,
    height: 0
  };
  textElementEditing: TextElement | undefined;
  selectableStates: Array<boolean> = [];
  gestureStartScale = 0;
  gestureFocusX = 0;
  gestureFocusY = 0;
  scaleLimits?: ScaleLimits;
  scalingFrom?: string;
  initialPositionTL?: fabric.Point;
  initialPositionBR?: fabric.Point;
  zoomTweenTargets = { x: 0, y: 0, zoom: 0 };
  renderGridlines = false;
  canvasDragOn = false;
  canvasBounds: PIXI.Rectangle;
  screenshotCanvas = document.createElement('canvas');
  minZoom = DEFAULT_MIN_ZOOM;
  editorTransform: number[] | undefined;
  addElementsPromise?: Promise<void>;
  firstLoadPinCamera: boolean;
  stashedElementIDs: Array<string> = [];
  initialViewportSize: { width: number; height: number };
  domElement: HTMLCanvasElement;
  domElementBounds: DOMRect;
  boundStartAutoDragging: ((event: MouseEvent) => void) | undefined;
  boundCancelAutoScrollDrag: ((event: MouseEvent) => void) | undefined;
  draggingHandle = false;
  isPixiTimeline: boolean | undefined = undefined;
  wasPixiTimeline: boolean | undefined = undefined;
  isLeftPanelOpen: boolean | undefined = undefined;
  wasLeftPanelOpen: boolean | undefined = undefined;
  onUpdateTextfieldDimensions: (updatedElementsConfig: Array<UpdatedTextElementConfig>) => void;
  onSetSelectedTextColor: (color: string | null | undefined) => void;
  handleSetIsTextboxEditing: (isEnabled: boolean) => void;
  onPremiumIndicatorClick: () => void;

  updatingElements = false;
  bufferedPropsCalls: ReactableCanvasProps[];

  constructor(element: HTMLCanvasElement, props: ReactableCanvasProps, config: ReactableCanvasConfig) {
    const {
      initialViewportSize,
      onVisualUpdate,
      onUpdateScene,
      onSetActiveElements,
      onUpdateZoom,
      onUpdateMinZoom,
      onUpdateScrollbars,
      onPinActiveCameras,
      onSetFirstLoad,
      onUnpinActiveCameras,
      onUpdatePosOrZoom,
      onUpdateTextfieldDimensions,
      onSetSelectedTextColor,
      handleSetIsTextboxEditing,
      onPremiumIndicatorClick
    } = config;

    initControlsAddons();

    super(element, {
      width: initialViewportSize.width,
      height: initialViewportSize.height,
      selectionKey: selectionKeys,
      preserveObjectStacking: true,
      controlsAboveOverlay: true,
      backgroundColor: '#e4e9eb',
      skipOffscreen: true,
      uniScaleKey: 'none'
    });
    this.user = props.user;
    this.initialViewportSize = initialViewportSize;
    this.firstLoadShowAll = true;
    this.firstLoadPinCamera = true;
    this.maxCanvasMultiplyer = config.maxCanvasMultiplyer;
    this.maxScrollMultiplyer = config.maxScrollMultiplyer;
    this.canvasSize = config.canvasSize;
    this.domElement = this.getElement();
    this.domElementBounds = this.domElement.getBoundingClientRect();
    this.autoscrollDragging = false;
    this.checkForAutoScroll = false;
    this.autoScrollTarget = null;
    if (!props.scene) throw new Error('No active scene found in the project');
    this.frame = new ProjectFrame(
      EXPANDED_CANVAS_SIZE.width + EXPANDED_CANVAS_SIZE.width * this.maxCanvasMultiplyer * 2,
      EXPANDED_CANVAS_SIZE.height + EXPANDED_CANVAS_SIZE.height * this.maxCanvasMultiplyer * 2,
      props.scene.settings,
      -EXPANDED_CANVAS_SIZE.width * this.maxCanvasMultiplyer,
      -EXPANDED_CANVAS_SIZE.height * this.maxCanvasMultiplyer
    );
    this.renderGridlines = props.gridlines;
    this.firstLoad = props.firstLoad;
    this.canvasDragOn = props.canvasDragOn;
    this.canvasBounds = new PIXI.Rectangle(
      -(EXPANDED_CANVAS_SIZE.width * this.maxCanvasMultiplyer),
      -(EXPANDED_CANVAS_SIZE.height * this.maxCanvasMultiplyer),
      EXPANDED_CANVAS_SIZE.width + EXPANDED_CANVAS_SIZE.width * this.maxCanvasMultiplyer * 2,
      EXPANDED_CANVAS_SIZE.height + EXPANDED_CANVAS_SIZE.height * this.maxCanvasMultiplyer * 2
    );

    this.props = cloneDeep(props);
    this.bufferedPropsCalls = [];

    this.manageMouseMove = this.manageMouseMove.bind(this);
    this.manageKeyDown = this.manageKeyDown.bind(this);
    this.manageKeyUp = this.manageKeyUp.bind(this);
    this.updateZoomLevel = this.updateZoomLevel.bind(this);
    this.handleGestureStart = this.handleGestureStart.bind(this);
    this.handleGestureChange = this.handleGestureChange.bind(this);

    this.manageDragRelease = this.manageDragRelease.bind(this);

    this.onVisualUpdate = onVisualUpdate;
    this.onUpdateScene = onUpdateScene;
    this.onSetActiveElements = onSetActiveElements;
    this.onUpdateZoom = onUpdateZoom;
    this.onUpdatePosOrZoom = onUpdatePosOrZoom;
    this.onUpdateZoom = zoom => {
      this.fire('viewport:update');
      onUpdateZoom(zoom);
    };
    this.onUpdateScrollbars = dims => {
      this.fire('viewport:update');
      onUpdateScrollbars(dims);
    };
    this.onUpdateMinZoom = onUpdateMinZoom;
    this.onPinActiveCameras = onPinActiveCameras;
    this.onSetFirstLoad = onSetFirstLoad;
    this.onUnpinActiveCameras = onUnpinActiveCameras;
    this.onUpdateTextfieldDimensions = onUpdateTextfieldDimensions;
    this.onSetSelectedTextColor = onSetSelectedTextColor;
    this.handleSetIsTextboxEditing = handleSetIsTextboxEditing;
    this.onPremiumIndicatorClick = onPremiumIndicatorClick;

    this.fireMiddleClick = true;
    this.isDragging = false;
    this.draggingStaged = false;

    this.on('object:modified', this.handleObjectModified);
    this.on('selection:created', this.handleSelectionChanged);
    this.on('selection:updated', this.handleSelectionChanged);
    this.on('selection:cleared', this.handleSelectionChanged);
    this.on('object:modified', this.manageEventedOnIntersectingObjects);
    this.on('object:moving', this.limitCameraMovement);

    this.on('selection:created', this.manageEventedOnIntersectingObjects);
    this.on('selection:updated', this.manageEventedOnIntersectingObjects);
    this.on('selection:cleared', this.manageEventedOnIntersectingObjects);

    // Handle object scaling
    this.on('selection:created', this.handleSelectionCreated);
    this.on('selection:cleared', this.handleSelectionCleared);
    this.on('object:scaling', this.handleObjectScaling);
    this.on('object:rotating', this.handleObjectRotating);
    this.on('mouse:down', this.handleCornerGrab);
    this.on('mouse:up', this.handleCornerReleased);

    this.on('mouse:wheel', this.manageMouseWheel);
    this.on('mouse:down', this.manageMousePress);
    this.on('mouse:up', this.manageDragRelease);

    window.addEventListener('mousemove', this.manageMouseMove);
    window.addEventListener('keydown', this.manageKeyDown);
    window.addEventListener('keyup', this.manageKeyUp);

    const canvasElement = this.getSelectionElement();

    canvasElement.addEventListener('gesturestart', this.handleGestureStart);
    canvasElement.addEventListener('gesturechange', this.handleGestureChange);

    this.boundStartAutoDragging = this.startAutoDragging.bind(this);
    this.boundCancelAutoScrollDrag = this.cancelAutoScrollDrag.bind(this);

    this.domElement.parentElement?.addEventListener('mouseleave', this.boundStartAutoDragging);
    this.domElement.parentElement?.addEventListener('mouseenter', this.boundCancelAutoScrollDrag);

    window.addEventListener('mousemove', this.updateAutoScrollTarget.bind(this));
    this.on('mouse:up', this.cancelAutoScrollDrag);

    if (this.props.editorTransform) {
      this.editorTransform = cloneDeep(this.props.editorTransform);

      this.setViewportTransform(this.editorTransform);

      this.updateZoomLevel(this.editorTransform[0]);
    } else {
      this.zoomToFitAll();
    }
    this.updateMinZoom();
  }

  startAutoDragging(event: MouseEvent) {
    if (this.checkForAutoScroll && this.isDragging === false) {
      this.updateAutoScrollTarget(event);

      this.autoscrollDragging = true;
      this.lastPosX = event.clientX;
      this.lastPosY = event.clientY;
      this.handleAutoScroll();
    }
  }
  limitScrollSpeed(scrollSpeed: number): number {
    const baseSpeed = 0.8;
    const zoomMultiplier = 1 / 1.5;
    return scrollSpeed * (baseSpeed - Math.min(baseSpeed - 0.1, this.getZoom() * zoomMultiplier));
  }

  handleAutoScroll() {
    if (this.autoscrollDragging) {
      if (this.autoScrollTarget) {
        const centerPoint = { x: this.getVpCenter().x, y: this.getVpCenter().y };
        const canvasBounds = this.getCanvasObjectCoordinates();

        let scrollSpeed = 1;
        let direction: { x: number; y: number } = { x: 0, y: 0 };

        const isBeyondRightBoundary =
          this.autoScrollTarget.x > this.domElementBounds.left + this.domElementBounds.width;
        const isBeyondLeftBoundary = this.autoScrollTarget.x < this.domElementBounds.left;
        const isBeyondTopBoundary = this.autoScrollTarget.y < this.domElementBounds.top;
        const isBeyondBottomBoundary =
          this.autoScrollTarget.y > this.domElementBounds.top + this.domElementBounds.height;
        if (isBeyondRightBoundary) {
          if (centerPoint.x + scrollSpeed > canvasBounds.right + EXPANDED_CANVAS_SIZE.width * this.getZoom()) return;
          scrollSpeed += (this.autoScrollTarget.x - (this.domElementBounds.left + this.domElementBounds.width)) / 2;
          scrollSpeed = this.limitScrollSpeed(scrollSpeed);
          direction = { x: scrollSpeed, y: 0 };
        } else if (isBeyondLeftBoundary) {
          if (centerPoint.x - scrollSpeed < canvasBounds.left - EXPANDED_CANVAS_SIZE.width * this.getZoom()) return;
          scrollSpeed += (this.domElementBounds.left - this.autoScrollTarget.x) / 2;
          scrollSpeed = this.limitScrollSpeed(scrollSpeed);
          direction = { x: -scrollSpeed, y: 0 };
        }

        if (isBeyondTopBoundary) {
          if (centerPoint.y + scrollSpeed < canvasBounds.top - EXPANDED_CANVAS_SIZE.height) return;
          scrollSpeed -= (this.autoScrollTarget.y - this.domElementBounds.top) / 2;
          scrollSpeed = this.limitScrollSpeed(scrollSpeed);
          direction = { x: 0, y: -scrollSpeed };
        } else if (isBeyondBottomBoundary) {
          if (centerPoint.y + scrollSpeed > canvasBounds.bottom + EXPANDED_CANVAS_SIZE.height) return;
          scrollSpeed -= (this.domElementBounds.top + this.domElementBounds.height - this.autoScrollTarget.y) / 2;
          scrollSpeed = this.limitScrollSpeed(scrollSpeed);
          direction = { x: 0, y: scrollSpeed };
        }

        const selected = this.getActiveObject();
        const viewportTransform = this.viewportTransform;
        const isCamera = assertIsCameraCanvasElement(selected);

        if (!selected) {
          //autoscrolls when dragging an empty selection area
          this.fakeMouseMove();
        }
        if (
          selected &&
          selected.top &&
          selected.left &&
          selected.scaleX &&
          selected.width &&
          selected.scaleY &&
          selected.height
        ) {
          if (this.draggingHandle) {
            //autoscrolls when dragging a handle has been disabled until we can fix the diagonal handle bug
            return;
          }

          if (this.textElementEditing) {
            //do not autoscroll beyond the edge of the text element being edited
            //because fabric would leave artifacts on the canvas
            if (
              (direction.x > 0 &&
                selected.left + selected.width * selected.scaleX <=
                  this.getVpCenter().x + this.getWidth() / 2 / this.getZoom()) ||
              (direction.x < 0 && selected.left >= this.getVpCenter().x - this.getWidth() / 2 / this.getZoom()) ||
              (direction.y > 0 &&
                selected.top + selected.height * selected.scaleY <=
                  this.getVpCenter().y + this.getHeight() / 2 / this.getZoom()) ||
              (direction.y < 0 && selected.top >= this.getVpCenter().y - this.getHeight() / 2 / this.getZoom())
            ) {
              return;
            }
          }

          if (!this.draggingHandle && !this.textElementEditing) {
            //autoscrolls when dragging a selection
            selected.top = selected.top + direction.y;
            selected.left = selected.left + direction.x;
          }

          this.renderAll();
        }

        if (!viewportTransform) return;

        if (selected && this.autoScrollTarget && selected.left && selected.width && selected.scaleX) {
          this.limitCameraMovement({
            target: selected,
            e: { clientX: this.autoScrollTarget.x, clientY: this.autoScrollTarget.y } as MouseEvent
          } as IEvent);
          if (isCamera) {
            //do not autoscroll beyond the maximum camera position bounds (the camera is locked to the canvas)
            if (
              (direction.x > 0 && this.getVpCenter().x + this.getWidth() / 2 / this.getZoom() > canvasBounds.right) ||
              (direction.x < 0 && this.getVpCenter().x - this.getWidth() / 2 / this.getZoom() < canvasBounds.left) ||
              (direction.y > 0 && this.getVpCenter().y + this.getHeight() / 2 / this.getZoom() > canvasBounds.bottom) ||
              (direction.y < 0 && this.getVpCenter().y - this.getHeight() / 2 / this.getZoom() < canvasBounds.top)
            ) {
              return;
            }
          }
        }

        viewportTransform[4] -= direction.x * this.getZoom();
        viewportTransform[5] -= direction.y * this.getZoom();
        this.setViewportTransform(viewportTransform);
        this.updateScrollbars();
      }

      requestAnimationFrame(this.handleAutoScroll.bind(this));
    }
  }

  fakeMouseMove() {
    const canvasElement = this.getElement();
    if (canvasElement && this.autoScrollTarget) {
      const event = new MouseEvent('mousemove', {
        clientX: this.autoScrollTarget.x,
        clientY: this.autoScrollTarget.y,
        bubbles: true,
        cancelable: true
      });
      canvasElement.dispatchEvent(event);
    }
  }

  updateAutoScrollTarget(event: MouseEvent) {
    if (this.autoscrollDragging) {
      this.autoScrollTarget = { x: event.clientX, y: event.clientY };
    }
  }

  cancelAutoScrollDrag() {
    this.autoscrollDragging = false;
  }

  dispose() {
    const viewportTransform = this.viewportTransform;
    if (viewportTransform) {
      this.onUpdatePosOrZoom(viewportTransform);
    }

    const canvasElement = this.getSelectionElement();
    canvasElement.removeEventListener('gesturestart', this.handleGestureStart);
    canvasElement.removeEventListener('gesturechange', this.handleGestureChange);

    window.removeEventListener('mousemove', this.manageMouseMove);
    window.removeEventListener('keydown', this.manageKeyDown);
    window.removeEventListener('keyup', this.manageKeyUp);
    window.removeEventListener('mousemove', this.updateAutoScrollTarget);
    if (this.boundStartAutoDragging) {
      this.domElement.parentElement?.removeEventListener('mouseleave', this.boundStartAutoDragging);
    }

    if (this.boundCancelAutoScrollDrag) {
      this.domElement.parentElement?.removeEventListener('mouseenter', this.boundCancelAutoScrollDrag);
    }
    return super.dispose();
  }

  handleGestureStart(event: GestureEvent) {
    event.preventDefault();

    const canvasElement = this.getSelectionElement();
    const { x, y } = canvasElement.getBoundingClientRect();

    this.gestureStartScale = this.getZoom();
    this.gestureFocusX = event.clientX - x;
    this.gestureFocusY = event.clientY - y;
  }

  handleGestureChange(event: GestureEvent) {
    event.preventDefault();

    const zoom = this.clampedZoom(this.gestureStartScale * event.scale);

    this.zoomToPoint(new fabric.Point(this.gestureFocusX, this.gestureFocusY), zoom);
    this.clampBoundsAfterZoomChange();
    this.updateZoomLevel(zoom);
  }

  deselectStageElementsBeforeDragging() {
    this.discardActiveObject();
    this.selection = false;
    this.forEachObject(el => {
      this.selectableStates.push(!!el.selectable);
      el.selectable = false;
    });
    this.renderAll();
  }

  manageKeyDown(event: KeyboardEvent) {
    if (event.code === 'Space' && !this.draggingStaged && !this.textElementEditing) {
      this.draggingStaged = true;
      this.deselectStageElementsBeforeDragging();
      if (!this.isDragging) {
        this.setCursor('grab');
        return;
      }
    }

    if ((isMac && event.metaKey) || event.ctrlKey) {
      if (event.code === 'Equal') {
        event.preventDefault();
        this.updateZoomLevel(this.getZoom() * 1.1);
      }

      if (event.code === 'Minus') {
        event.preventDefault();
        this.updateZoomLevel(this.getZoom() / 1.1);
      }
    }
  }

  manageKeyUp(event: KeyboardEvent) {
    if (event.code === 'Space' && !this.textElementEditing) {
      this.draggingStaged = false;
      this.selection = true;
      this.forEachObject((el, index) => {
        el.selectable = this.selectableStates[index];
      });
      this.selectableStates = [];
      this.updateActiveSelection();
      this.renderAll();
      if (!this.isDragging) {
        this.setCursor('default');
      }
    }
  }

  /**
   * Handler for mouse up events
   */
  public manageDragRelease(e: IEvent) {
    this.checkForAutoScroll = false;
    const isEditingText = assertIsTextCanvasElement(e.target) && e.target.isEditing;
    if (isEditingText) return;
    const viewportTransform = this.viewportTransform;
    if (viewportTransform) {
      this.setViewportTransform(viewportTransform);
      this.updateScrollbars();

      const cancelDragging = false;
      this.setPanning(cancelDragging);
    }
  }

  private getCenteringOffsets(): { widthOffset: number; heightOffset: number } {
    this.storeContentBounds();
    const expandedCanvasHeight = Math.max(
      EXPANDED_CANVAS_SIZE.height * this.getZoom() * EXPANDED_CANVAS_MULTIPLE,
      this.contentBounds.height
    );
    const heightOffset = expandedCanvasHeight < this.getHeight() ? (expandedCanvasHeight - this.getHeight()) / 2 : 0;

    const expandedCanvasWidth = Math.max(
      EXPANDED_CANVAS_SIZE.width * this.getZoom() * EXPANDED_CANVAS_MULTIPLE,
      this.contentBounds.width
    );

    const widthOffset = expandedCanvasWidth < this.getWidth() ? (expandedCanvasWidth - this.getWidth()) / 2 : 0;
    return { widthOffset, heightOffset };
  }
  /**
   * Handler for mouse move events.
   * @param event
   */
  private manageMouseMove(event: MouseEvent) {
    if (this.draggingStaged || this.canvasDragOn) this.setCursor('grab');
    if (!this.isDragging) return;

    const viewportTransform = this.viewportTransform;
    if (!viewportTransform) return;
    if (this.textElementEditing) {
      this.textElementEditing.exitEditing();
      this.textElementEditing = undefined;
    }

    this.setCursor('grabbing');

    this.lastPosX = this.lastPosX ?? event.clientX;
    this.lastPosY = this.lastPosY ?? event.clientY;

    const newXTargetViewportTransform = viewportTransform[4] + event.clientX - this.lastPosX;
    const newYTargetViewportTransform = viewportTransform[5] + event.clientY - this.lastPosY;
    const { x, y } = this.clampCoordinates({
      x: newXTargetViewportTransform,
      y: newYTargetViewportTransform
    });

    if (x !== undefined) {
      viewportTransform[4] = x;
    }

    if (y !== undefined) {
      viewportTransform[5] = y;
    }
    this.requestRenderAll();

    this.lastPosX = event.clientX;
    this.lastPosY = event.clientY;

    this.updateScrollbars();
  }

  private canvasFullyZoomedOut(): boolean {
    return false;
  }
  /**
   * Handler for mouse down events.
   * @param event
   */
  private manageMousePress(event: fabric.IEvent<MouseEvent>) {
    if (event.button === 1) this.checkForAutoScroll = true;
    if (event.button === 2 || (this.draggingStaged && event.button === 1) || this.canvasDragOn) {
      event.e.preventDefault();
      if (this.canvasFullyZoomedOut()) return;
      const startDragging = true;
      this.setPanning(startDragging, event.e.clientX, event.e.clientY);
      return;
    }
  }

  /**
   * This will toggle the dragging state of the canvas and update the last moved to coordinates.
   *
   * If we have finished dragging we want to clear the last dragged position.
   *
   * @param isDragging
   * @param x
   * @param y
   */
  public setPanning(isDragging: boolean, x?: number, y?: number) {
    if (isDragging) {
      this.setCursor('grabbing');
    }

    this.isDragging = isDragging;
    this.selection = !isDragging;

    this.lastPosX = x;
    this.lastPosY = y;
  }

  /**
   * Will clamp the given coordinates to prevent a user scrolling more than 3 times outside the project frame.
   *
   * @param x
   * @param y
   * @returns
   */
  public clampCoordinates = ({ x, y }: { x?: number; y?: number }) => {
    const zoom = this.getZoom();
    const canvasWidth = EXPANDED_CANVAS_SIZE.width;
    const canvasHeight = EXPANDED_CANVAS_SIZE.height;
    const viewport = this.calcViewportBoundaries();
    const viewportRect = new PIXI.Rectangle(
      viewport.tl.x,
      viewport.tl.y,
      viewport.br.x - viewport.tl.x,
      viewport.br.y - viewport.tl.y
    );
    this.storeContentBounds();
    const rect = {
      left: this.contentBounds.left,
      top: this.contentBounds.top,
      right: this.contentBounds.width + this.contentBounds.left,
      bottom: this.contentBounds.height + this.contentBounds.top
    };
    const padding = 100;
    rect.top -= padding;
    rect.bottom += padding;
    rect.left -= padding;
    rect.right += padding;
    //this is very confusing, but basically have two lots of bounds, the content and the canvas, and the the scrollbars are based on the negative of those.
    // but we also need to take in to account of the aspect ratio of the canvas and the viewport, so we dont jump around if things are out of bounds in an axit not being scrolled.
    const minX = Math.min(-canvasWidth * this.maxScrollMultiplyer, rect.left);
    const maxX = Math.max(canvasWidth * (this.maxScrollMultiplyer + 1), rect.right);

    const minY = Math.min(-canvasHeight * this.maxScrollMultiplyer, rect.top);
    const maxY = Math.max(canvasHeight * (this.maxScrollMultiplyer + 1), rect.bottom);

    const scrollBounds = new PIXI.Rectangle(minX, minY, maxX - minX, maxY - minY);

    let newX = x;
    if (newX !== undefined) {
      if (scrollBounds.width >= viewportRect.width) {
        if (newX > -scrollBounds.left * zoom) {
          newX = -scrollBounds.left * zoom;
        }
        if (newX < (-scrollBounds.right + viewportRect.width) * zoom) {
          newX = (-scrollBounds.right + viewportRect.width) * zoom;
        }
      } else {
        if (minX > viewportRect.left || maxX < viewportRect.right) {
          newX = -(((scrollBounds.left + scrollBounds.right) / 2 - viewportRect.width / 2) * zoom);
        }
      }
    }

    let newY = y;
    if (newY !== undefined) {
      if (scrollBounds.height >= viewportRect.height) {
        if (newY > -scrollBounds.top * zoom) {
          newY = -scrollBounds.top * zoom;
        }
        if (newY < (-scrollBounds.bottom + viewportRect.height) * zoom) {
          newY = (-scrollBounds.bottom + viewportRect.height) * zoom;
        }
      } else {
        if (minY > viewportRect.top || maxY < viewportRect.bottom) {
          newY = -((scrollBounds.top + scrollBounds.bottom) / 2) * zoom + (viewportRect.height * zoom) / 2;
        }
      }
    }

    return { x: newX, y: newY };
  };

  /**
   * Handler for mouse wheel events, will scroll based on a mouse wheel / track-pad movement.
   * It will also zoom in and out of the canvas if the correct key is pressed.
   *
   * @param event
   * @returns
   */
  public manageMouseWheel(event: fabric.IEvent<WheelEvent>) {
    event.e.preventDefault();
    event.e.stopPropagation();

    const mouseYDelta = event.e.deltaY;
    let zoom = this.getZoom();

    const viewportTransform = this.viewportTransform;

    if (!viewportTransform) return;

    if (this.textElementEditing) {
      this.textElementEditing.exitEditing();
      this.textElementEditing = undefined;
    }

    if (event.e.metaKey || event.e.ctrlKey) {
      const multiplier = isMac ? ZOOM_SPEED_MULTIPLIER : ZOOM_SPEED_MULTIPLIER / 10;
      zoom = this.clampedZoom(zoom - mouseYDelta * multiplier);

      this.zoomToPoint(new fabric.Point(event.e.offsetX, event.e.offsetY), zoom);

      this.clampBoundsAfterZoomChange();
      this.onUpdateZoom(zoom);

      return;
    } else if (event.e.shiftKey) {
      if (this.canvasFullyZoomedOut()) return;
      let mouseDelta = mouseYDelta;
      const mouseDeltaX = event.e.deltaX;

      if (mouseDelta === 0 && isMac && mouseDeltaX !== 0) {
        mouseDelta = mouseDeltaX;
      }

      const newXTargetViewportTransform = viewportTransform[4] + -mouseDelta * ZOOM_DELTA_MULTIPLIER ** zoom;

      const newXInsideScrollLimits = this.clampCoordinates({
        x: newXTargetViewportTransform,
        y: viewportTransform[5]
      }).x;

      if (newXInsideScrollLimits !== undefined) {
        viewportTransform[4] = newXInsideScrollLimits;
      }

      this.setViewportTransform(viewportTransform);
      this.updateScrollbars();

      return;
    } else {
      if (this.canvasFullyZoomedOut()) return;
      const newXTargetViewportTransform = viewportTransform[4] + -event.e.deltaX * ZOOM_DELTA_MULTIPLIER ** zoom;
      const newYTargetViewportTransform = viewportTransform[5] + -mouseYDelta * ZOOM_DELTA_MULTIPLIER ** zoom;

      const { x, y } = this.clampCoordinates({
        x: newXTargetViewportTransform,
        y: newYTargetViewportTransform
      });

      if (x !== undefined) {
        viewportTransform[4] = x;
      }
      if (y !== undefined) {
        viewportTransform[5] = y;
      }
      this.setViewportTransform(viewportTransform);
      this.updateScrollbars();
    }
  }

  calculateMinTransform(): number[] {
    const rect = this.calculateElementBounds();
    const newViewportTransform = this.calculateViewportTransform(rect.width, rect.height, {
      x: rect.left,
      y: rect.top
    });
    if (newViewportTransform[0] < 0) {
      newViewportTransform[0] = newViewportTransform[3] = DEFAULT_MIN_ZOOM;
    }

    return newViewportTransform;
  }

  calculateElementBounds(): PIXI.Rectangle {
    const rect = {
      left: Number.MAX_SAFE_INTEGER,
      right: Number.MIN_SAFE_INTEGER,
      top: Number.MAX_SAFE_INTEGER,
      bottom: Number.MIN_SAFE_INTEGER
    };

    if (!this.elements || this.elements?.length !== 0) {
      this.elements?.forEach(element => {
        const fabBounds = element.getBoundingRect(true, false);
        const elementBounds = new PIXI.Rectangle(fabBounds.left, fabBounds.top, fabBounds.width, fabBounds.height);
        rect.left = Math.min(rect.left, elementBounds.left);
        rect.right = Math.max(rect.right, elementBounds.right);
        rect.top = Math.min(rect.top, elementBounds.top);
        rect.bottom = Math.max(rect.bottom, elementBounds.bottom);
      });
    }
    const padding = 100;
    rect.top -= padding;
    rect.bottom += padding;
    rect.left -= padding;
    rect.right += padding;
    const width = rect.right - rect.left;
    const height = rect.bottom - rect.top;
    return new PIXI.Rectangle(rect.left, rect.top, width, height);
  }

  updateMinZoom() {
    const canvasBounds = this.getCanvasObjectCoordinates();
    const canvasBoundsAsPixiRect = new PIXI.Rectangle(
      canvasBounds.left,
      canvasBounds.top,
      canvasBounds.right - canvasBounds.left,
      canvasBounds.bottom - canvasBounds.top
    );
    const elementBounds = this.calculateElementBounds();
    elementBounds.enlarge(canvasBoundsAsPixiRect);
    const newViewportTransform = this.calculateViewportTransform(elementBounds.width, elementBounds.height, {
      x: elementBounds.left,
      y: elementBounds.top
    });
    this.minZoom = newViewportTransform[0];
    this.onUpdateMinZoom(this.minZoom);
  }

  clampedZoom(zoom: number): number {
    return clamp(zoom, this.minZoom, MAX_CAM_ZOOM + 1);
  }

  setViewToObject(objWidth: number, objHeight: number, objX: number, objY: number) {
    const viewportWidth = this.getWidth();
    const viewportHeight = this.getHeight();
    const objectWidth = objWidth;
    const objectHeight = objHeight;

    let targetZoom = 0;
    if (objectWidth > objectHeight) {
      if (viewportWidth > viewportHeight && objectWidth * (viewportHeight / objectHeight) < viewportWidth) {
        targetZoom = viewportHeight / objectHeight;
      } else {
        targetZoom = viewportWidth / objectWidth;
      }
    } else {
      if (viewportHeight > viewportWidth && objectHeight * (viewportHeight / objectHeight) < viewportHeight) {
        targetZoom = viewportWidth / objectWidth;
      } else {
        targetZoom = viewportHeight / objectHeight;
      }
    }

    targetZoom = Math.min(targetZoom, MAX_CAM_ZOOM + 1);
    const canvasWidthOffset = viewportWidth / 2 - (objectWidth / 2) * targetZoom;
    const canvasHeightOffset = viewportHeight / 2 - (objectHeight / 2) * targetZoom;
    const viewportTransform = this.viewportTransform;
    const zoomTweenParams = { x: 0, y: 0, zoom: this.getZoom() };
    this.zoomTweenTargets = { x: 0, y: 0, zoom: targetZoom };
    if (viewportTransform) {
      zoomTweenParams.x = viewportTransform[4];
      zoomTweenParams.y = viewportTransform[5];
      this.zoomTweenTargets.x = -objX * targetZoom + canvasWidthOffset;
      this.zoomTweenTargets.y = -objY * targetZoom + canvasHeightOffset;
    }
    gsap.to(zoomTweenParams, {
      duration: 0.5,
      ease: 'power2.out',
      x: this.zoomTweenTargets.x,
      y: this.zoomTweenTargets.y,
      zoom: this.zoomTweenTargets.zoom,
      onUpdate: () => {
        this.updateZoomLevel(zoomTweenParams.zoom);
        const viewportTransform = this.viewportTransform;
        if (viewportTransform) {
          viewportTransform[4] = zoomTweenParams.x;
          viewportTransform[5] = zoomTweenParams.y;

          this.setViewportTransform(viewportTransform);
        }
      },
      onComplete: () => {
        this.updateScrollbars();
      }
    });
  }

  public async setViewToActiveObject() {
    const target = this.getActiveObject();
    await this.discardActiveObjectAndWaitForNextEventLoop();
    if (target && target.width && target.height) {
      const fabBounds = target.getBoundingRect(true, false);
      if (target.width / MAX_CAM_ZOOM > fabBounds.width) {
        fabBounds.left = fabBounds.left - (target.width / MAX_CAM_ZOOM - fabBounds.width) / 2;
      }
      if (target.height / MAX_CAM_ZOOM > fabBounds.height) {
        fabBounds.top = fabBounds.top - (target.height / MAX_CAM_ZOOM - fabBounds.height) / 2;
      }
      this.setViewToObject(fabBounds.width, fabBounds.height, fabBounds.left, fabBounds.top);
      this.updateActiveSelection();
      this.renderAll();
    }
  }

  public setViewToCamera(element: ScribeCameraElement, viewportData: { width: number; height: number }) {
    if (element) {
      this.setViewToObject(
        viewportData.width / element.scale,
        viewportData.height / element.scale,
        element.x,
        element.y
      );
    }
  }

  clampBoundsAfterZoomChange() {
    const transform = this.viewportTransform;

    if (transform) {
      const { x, y } = this.clampCoordinates({ x: transform[4], y: transform[5] });
      const viewportTransform = this.viewportTransform;
      if (viewportTransform) {
        if (x !== undefined) viewportTransform[4] = x;
        if (y !== undefined) viewportTransform[5] = y;

        const offsets = this.getCenteringOffsets();
        if (x !== undefined && offsets.widthOffset !== 0)
          viewportTransform[4] = x + offsets.widthOffset + (EXPANDED_CANVAS_SIZE.width / 2) * this.getZoom();
        if (y !== undefined && offsets.heightOffset !== 0)
          viewportTransform[5] = y + offsets.heightOffset + (EXPANDED_CANVAS_SIZE.height / 2) * this.getZoom();
      }
      this.updateScrollbars();
    }
  }

  /**
   * @param zoomLevel number between the minimum zoom where it is possible to still have scrollbars given the viewport width and 4
   */
  public updateZoomLevel(zoomLevel: number) {
    const newZoomLevel = this.clampedZoom(zoomLevel);

    const newPointToZoomOn = new fabric.Point(this.getCenter().left, this.getCenter().top);
    this.zoomToPoint(newPointToZoomOn, newZoomLevel);

    const selectedElementIds = this.props.activeElements;
    if (selectedElementIds && selectedElementIds.length > 0) {
      const activeObject = this.getActiveObject();
      if (activeObject && activeObject.aCoords) {
        const centerX = activeObject.aCoords.tl.x + (activeObject.aCoords.tr.x - activeObject.aCoords.tl.x) / 2;
        const centerY = activeObject.aCoords.tl.y + (activeObject.aCoords.br.y - activeObject.aCoords.tl.y) / 2;
        this.setViewportPosition(centerX, centerY, true);
      }
    }

    this.clampBoundsAfterZoomChange();
    this.onUpdateZoom(newZoomLevel);
  }

  /**
   * Checks if the given fabric object center is in view, if not it will center the viewport around the object, without changing the zoom/scale
   *
   * @param fabricObject
   * @returns
   */
  public setFabricObjectIntoView(fabricObject?: fabric.Object) {
    if (!fabricObject) {
      return;
    }

    const { x, y } = fabricObject.getCenterPoint();

    this.setViewportPosition(x, y);
  }

  /**
   * This method will take absolute coordinates, and then check if we are already seeing those coordinates.
   * If we can already see the coordinates it won't reposition the viewport.
   * Otherwise we will place the coordinates in the center of the viewport.
   *
   * @param absoluteX absolute coordinate that we can scroll to
   * @param absoluteY absolute coordinate that we can scroll to
   */
  public setViewportPosition(absoluteX: number, absoluteY: number, alwaysUpdate = false) {
    const zoom = this.getZoom();

    const viewportTransform = this.viewportTransform;
    if (viewportTransform) {
      // Check if coordinates are already in view, if so we don't need to update the viewport
      const topBoundary = this.vptCoords?.tl.y ?? 0;
      const bottomBoundary = this.vptCoords?.br.y ?? EXPANDED_CANVAS_SIZE.height;

      const yCoordinateInsideView = absoluteY >= topBoundary && absoluteY <= bottomBoundary;

      const leftBoundary = this.vptCoords?.tl.x ?? 0;
      const rightBoundary = this.vptCoords?.br.x ?? EXPANDED_CANVAS_SIZE.width;

      const xCoordinateInsideView = absoluteX >= leftBoundary && absoluteX <= rightBoundary;

      if (xCoordinateInsideView && yCoordinateInsideView && !alwaysUpdate) {
        return;
      }

      const x = -absoluteX * zoom;
      const y = -absoluteY * zoom;

      const canvasWidthOffset = this.getWidth() / 2;
      const canvasHeightOffset = this.getHeight() / 2;

      viewportTransform[4] = x + canvasWidthOffset;
      viewportTransform[5] = y + canvasHeightOffset;

      this.setViewportTransform(viewportTransform);
      this.updateScrollbars();
    }
  }

  /**
   * This method will take the elements that were passed in as props when the instance was initiaca
   * and add them to the canvas.
   *
   * Because we cannot use async class constructors we need to add the elements after we have
   * initialised an instance of the canvas.
   *
   */
  public async addElements() {
    const elements = await this.createElements(this.props.elements);
    this.elements = elements;

    this.add(this.frame, ...this.elements);
    this.moveTo(this.frame, BACKGROUND_STACK_INDEX);

    this.applyElementsStackingOrder();
    this.updateCameraIndexes();

    const cameraElements = this.elements.filter(el => el.elementType === 'Camera');
    cameraElements.forEach(camEl => {
      if (camEl && assertCanvasElementType('Camera', camEl)) {
        camEl.handleSelection();
      }
    });

    this.updateScrollbars({ storeContentBounds: true });
    if (!this.editorTransform) {
      const viewportTransform = this.viewportTransform;
      if (viewportTransform) {
        this.zoomToFitAll();
        this.onUpdatePosOrZoom(viewportTransform);
      }
    } else {
      this.setViewportTransform(this.editorTransform);
      this.updateZoomLevel(this.getZoom());
    }
    this.updateActiveSelection();

    if (this.firstLoadPinCamera === true) {
      setTimeout(() => {
        this.setInitialSelection();
        this.firstLoadPinCamera = false;
      }, 100);
    }
  }

  private handleSceneUpdated() {
    if (this.elements) {
      const payloadElements = this.elements.map(el => el.toVscElement());
      const thumbnail = this.renderAndGenerateThumb();

      this.onUpdateScene(payloadElements, thumbnail);
    }
  }

  private handleObjectModified() {
    this.handleSceneUpdated();
  }

  /**
   * This is to override the fabric.Object.getActiveObjects() method to return the correct type.
   *
   * @returns An Array of Canvas Elements
   */
  getActiveObjects(): Array<CanvasElement> {
    const elements = super.getActiveObjects();
    if (elements.every(assertIsElement)) {
      return elements;
    } else throw new Error('Invalid object found');
  }

  private handleCornerGrab(event: IEvent) {
    const target = event.target;

    if (!target) return;

    const transform = event.transform;
    if (!transform || !transform.corner) return;

    if (HANDLE_VALUES.includes(transform.corner)) this.scalingFrom = transform.corner;

    if (assertIsActiveSelection(target) || assertIsCameraCanvasElement(target)) {
      this.initialPositionTL = target._getLeftTopCoords().clone();
      this.initialPositionBR = new fabric.Point(
        target._getLeftTopCoords().x + (target.width ? target.width : 0),
        target._getLeftTopCoords().y + (target.height ? target.height : 0)
      );
    }
  }

  private handleCornerReleased(event: IEvent) {
    const target = event.target;
    this.draggingHandle = false;
    this.scalingFrom = undefined;
    this.initialPositionTL = undefined;
    this.initialPositionBR = undefined;
    if (!target) return;
    target.originX = 'left';
    target.originY = 'top';
  }

  private handleSelectionChanged(event: IEvent) {
    if (!event.e) return; // if theres no mouse event then the handler was triggered by a programmatic change to the active selection
    if (this.draggingStaged || this.isDragging) return;

    const selected = this.getActiveObjects();
    const selectedIds = selected.map(el => el.id);
    this.onSetActiveElements(selectedIds);
  }

  private handleSelectionCreated() {
    const target = this.getActiveObject();

    const editorCanvasCoords = this.getCanvasObjectCoordinates();
    if (!target || !this.elements || !editorCanvasCoords) return;

    const canvasMinWidth = EXPANDED_CANVAS_SIZE.width / MAX_CAM_ZOOM;
    const canvasMinHeight = EXPANDED_CANVAS_SIZE.height / MAX_CAM_ZOOM;

    if (assertIsActiveSelection(target) || assertIsCameraCanvasElement(target)) {
      if (
        target.top === undefined ||
        target.left === undefined ||
        target.scaleX === undefined ||
        target.scaleY === undefined
      )
        return;

      const selectedElementIds = this.props.activeElements;

      const selectedElements = this.elements.filter(
        el => selectedElementIds.includes(el.id) && el.elementType === 'Camera'
      );

      if (!selectedElements.length) return;

      target.lockScalingFlip = true;

      const height = target.getScaledHeight();
      const width = target.getScaledWidth();

      let topCoord: number;
      let leftCoord: number;
      let bottomCoord: number;
      let rightCoord: number;

      if (assertIsCameraCanvasElement(target)) {
        topCoord = target.top;

        bottomCoord = target.top + height;

        leftCoord = target.left;

        rightCoord = leftCoord + width;
      } else {
        const scalingPositions: CameraElementScalingPositions = {
          leftPositions: [],
          rightPositions: [],
          topPositions: [],
          bottomPositions: []
        };

        target.getObjects().forEach(currentElement => {
          if (assertIsCameraCanvasElement(currentElement)) {
            const top = currentElement.top;
            if (top !== undefined) {
              scalingPositions.topPositions.push(top);

              const height = currentElement.getScaledHeight();
              scalingPositions.bottomPositions.push(height + top);
            }

            const left = currentElement.left;
            if (left !== undefined) {
              scalingPositions.leftPositions.push(left);
              const width = currentElement.getScaledWidth();
              scalingPositions.rightPositions.push(width + left);
            }
          }
        });

        topCoord = Math.min(...scalingPositions.topPositions);
        bottomCoord = Math.max(...scalingPositions.bottomPositions);

        leftCoord = Math.min(...scalingPositions.leftPositions);
        rightCoord = Math.max(...scalingPositions.rightPositions);
      }

      const distanceFromLeftEdge = editorCanvasCoords.left - leftCoord;
      const distanceFromRightEdge = editorCanvasCoords.right - rightCoord;
      const distanceFromTopEdge = editorCanvasCoords.top - topCoord;
      const distanceFromBottomEdge = editorCanvasCoords.bottom - bottomCoord;

      const topMaxHeight = target.top + height - topCoord;
      const topMaxScaleY = topMaxHeight / height;

      const topMinHeight = topCoord - target.top;
      const topMinScaleY = topMinHeight / height;

      const bottomMaxHeight = bottomCoord - target.top;
      const bottomMaxScaleY = bottomMaxHeight / height;

      const bottomMinHeight = target.top + height - bottomCoord;
      const bottomMinScaleY = bottomMinHeight / height;

      const leftMaxWidth = target.left + width - leftCoord;
      const leftMaxScaleX = leftMaxWidth / width;

      const leftMinWidth = leftCoord - target.left;
      const leftMinScaleX = leftMinWidth / width;

      const rightMaxWidth = rightCoord - target.left;
      const rightMaxScaleX = rightMaxWidth / width;

      const rightMinWidth = target.left + width - rightCoord;
      const rightMinScaleX = rightMinWidth / width;

      // Top Left Box Scales
      const tlScales = {
        maxX: INITIAL_ACTIVE_SELECTION_SCALE + distanceFromLeftEdge / width / leftMaxScaleX,
        maxY: INITIAL_ACTIVE_SELECTION_SCALE + distanceFromTopEdge / height / topMaxScaleY,
        minX: INITIAL_ACTIVE_SELECTION_SCALE - distanceFromRightEdge / width / rightMinScaleX,
        minY: INITIAL_ACTIVE_SELECTION_SCALE - distanceFromBottomEdge / height / bottomMinScaleY
      };

      // Top Right Box Scales
      const trScales = {
        maxX: INITIAL_ACTIVE_SELECTION_SCALE + distanceFromRightEdge / width / rightMaxScaleX,
        maxY: INITIAL_ACTIVE_SELECTION_SCALE + distanceFromTopEdge / height / topMaxScaleY,
        minX: INITIAL_ACTIVE_SELECTION_SCALE - distanceFromLeftEdge / width / leftMinScaleX,
        minY: INITIAL_ACTIVE_SELECTION_SCALE - distanceFromBottomEdge / height / bottomMinScaleY
      };

      //Bottom Left Box Scales
      const blScales = {
        maxX: INITIAL_ACTIVE_SELECTION_SCALE + distanceFromLeftEdge / width / leftMaxScaleX,
        maxY: INITIAL_ACTIVE_SELECTION_SCALE + distanceFromBottomEdge / height / bottomMaxScaleY,
        minX: INITIAL_ACTIVE_SELECTION_SCALE - distanceFromRightEdge / width / rightMinScaleX,
        minY: INITIAL_ACTIVE_SELECTION_SCALE - distanceFromTopEdge / height / topMinScaleY
      };

      // Bottom Right Box Scales
      const brScales = {
        maxX: INITIAL_ACTIVE_SELECTION_SCALE + distanceFromRightEdge / width / rightMaxScaleX,
        maxY: INITIAL_ACTIVE_SELECTION_SCALE + distanceFromBottomEdge / height / bottomMaxScaleY,
        minX: INITIAL_ACTIVE_SELECTION_SCALE - distanceFromLeftEdge / width / leftMinScaleX,
        minY: INITIAL_ACTIVE_SELECTION_SCALE - distanceFromTopEdge / height / topMinScaleY
      };

      const minScaleX = Math.max(
        ...selectedElements.map(el => {
          return getGroupMinScale(el.getScaledWidth(), canvasMinWidth);
        })
      );

      const minScaleY = Math.max(
        ...selectedElements.map(el => {
          return getGroupMinScale(el.getScaledHeight(), canvasMinHeight);
        })
      );

      const scaleLimits = {
        minScaleX,
        minScaleY,
        lastGoodX: target.left,
        lastGoodY: target.top,
        lastGoodScaleX: target.scaleX,
        lastGoodScaleY: target.scaleY,
        tlScales,
        trScales,
        blScales,
        brScales
      };

      this.scaleLimits = scaleLimits;
    }
  }

  private handleObjectRotating() {
    this.isDragging = false;
    this.checkForAutoScroll = false;
  }

  private handleObjectScaling(event: fabric.IEvent<MouseEvent>) {
    this.draggingHandle = true;
    if (!event.target) return;
    if (!event.target?.isType('group')) return;
    const target = event.target as CameraElement;

    target.setCoords();
    const editorCanvasBounds = this.getCanvasObjectCoordinates();
    if (
      editorCanvasBounds === undefined ||
      target.scaleX === undefined ||
      target.scaleY === undefined ||
      target.originX === undefined ||
      target.originY === undefined ||
      target.top === undefined ||
      target.left === undefined ||
      target.width === undefined ||
      target.height === undefined
    )
      return;
    if (!this.scalingFrom) return;

    const targetBoundsRect = new PIXI.Rectangle(
      target.left,
      target.top,
      target.getScaledWidth(),
      target.getScaledHeight()
    );

    let maxWidth = EXPANDED_CANVAS_SIZE.width / MIN_CAM_ZOOM;
    const minWidth = EXPANDED_CANVAS_SIZE.width / MAX_CAM_ZOOM;
    let maxHeight = EXPANDED_CANVAS_SIZE.height / MIN_CAM_ZOOM;
    let hasScaled = false;
    if (targetBoundsRect.width < minWidth) {
      target.scaleToWidth(minWidth, true);
      hasScaled = true;
    }
    if (targetBoundsRect.width > maxWidth) {
      target.scaleToWidth(maxWidth, true);
      hasScaled = true;
    }
    if (targetBoundsRect.left < editorCanvasBounds.left) {
      maxWidth = Math.min(maxWidth, targetBoundsRect.right - editorCanvasBounds.left);
      target.scaleToWidth(maxWidth, true);
      hasScaled = true;
    }
    if (targetBoundsRect.right > editorCanvasBounds.right) {
      maxWidth = Math.min(maxWidth, editorCanvasBounds.right - targetBoundsRect.left);
      target.scaleToWidth(maxWidth, true);
      hasScaled = true;
    }
    if (targetBoundsRect.bottom > editorCanvasBounds.bottom) {
      maxHeight = editorCanvasBounds.bottom - targetBoundsRect.top;
      target.scaleToHeight(maxHeight, true);
      hasScaled = true;
    }
    if (targetBoundsRect.top < editorCanvasBounds.top) {
      maxHeight = targetBoundsRect.bottom - editorCanvasBounds.top;
      target.scaleToHeight(maxHeight, true);
      hasScaled = true;
    }

    if (hasScaled) {
      if (this.initialPositionBR && this.initialPositionTL) {
        if (this.scalingFrom === 'tl') {
          target.setPositionByOrigin(
            new fabric.Point(this.initialPositionBR?.x, this.initialPositionBR?.y),
            'right',
            'bottom'
          );
        } else if (this.scalingFrom === 'bl') {
          target.setPositionByOrigin(
            new fabric.Point(this.initialPositionBR?.x, this.initialPositionTL?.y),
            'right',
            'top'
          );
        } else if (this.scalingFrom === 'tr') {
          target.setPositionByOrigin(
            new fabric.Point(this.initialPositionTL?.x, this.initialPositionBR?.y),
            'left',
            'bottom'
          );
        } else if (this.scalingFrom === 'br') {
          target.setPositionByOrigin(
            new fabric.Point(this.initialPositionTL?.x, this.initialPositionTL?.y),
            'left',
            'top'
          );
        }
      }
    }
  }

  private handleSelectionCleared() {
    this.scaleLimits = undefined;
  }

  /**
   * This method will take an array of project elements and return an array of canvas elements
   *
   * @param elements (An array of project elements)
   * @returns An array of CanvasElements
   */
  private async createElements(elements: Array<VSElementModel>) {
    const textElements = elements.filter(isTextElement).map(element => {
      return new TextElement(element);
    });

    const shapeElements = elements.filter(isShapeElement).map(element => {
      return createShapeElement(element);
    });

    const imageElements = await Promise.all(
      elements.filter(isImageElement).map(element => {
        return createImageElement(element);
      })
    );

    const cameraElements = elements
      .filter(isCameraElement)
      .map((element, index) => new CameraElement(element, this.canvasSize, index + CANVAS_CAMERA_INDEX_OFFSET));

    return [...textElements, ...shapeElements, ...imageElements, ...cameraElements];
  }

  /**
   * Causes the Fabric canvas to render and call the onVisualUpdate to update the thumbnail of the scene
   */
  private renderAndGenerateThumb() {
    this.renderAll();

    // prettier-ignore
    const [
      horizontalScale,
      , // vertical skew not used
      , // horizontal skew not used
      verticalScale,
      horizontalOffset,
      verticalOffset
    ] = this.viewportTransform ?? [1, 0, 0, 1, 0, 0];

    const targetScreenshotWidth = 420;
    const dataUrlWidth = EXPANDED_CANVAS_SIZE.width * horizontalScale;
    let multiplier = targetScreenshotWidth / dataUrlWidth;

    const gridWasVisible = this.renderGridlines;
    this.renderGridlines = false;
    this.renderAll();

    const cameraElements =
      this.elements?.filter((el): el is CameraElement => assertCanvasElementType('Camera', el)) ?? [];
    const nonCameraElements =
      this.elements?.filter((el): el is ViewableCanvasElement => !assertCanvasElementType('Camera', el)) ?? [];

    cameraElements.forEach(camera => {
      camera.visible = false;
      camera.dirty = true;
    });

    let screenshotConfig;

    if (nonCameraElements.length > 0) {
      const mappedNonCameraElements = this.mapBoundingBoxesToObjects(nonCameraElements);
      const screenshotPadding = 20;
      const elementsInCanvasBounds = mappedNonCameraElements.filter(mappedElement => {
        const outsideRight = mappedElement.boundingRect.left > this.canvasBounds.right;
        const outsideLeft = mappedElement.boundingRect.right < this.canvasBounds.left;
        const outsideTop = mappedElement.boundingRect.bottom < this.canvasBounds.top;
        const outsideBottom = mappedElement.boundingRect.top > this.canvasBounds.bottom;

        return !(outsideTop || outsideRight || outsideBottom || outsideLeft);
      });

      const elementBounds = elementsInCanvasBounds.reduce(
        (bounds, mappedElement) => {
          return {
            left: Math.max(
              Math.min(bounds.left, mappedElement.boundingRect.left - screenshotPadding),
              this.canvasBounds.left
            ),
            top: Math.max(
              Math.min(bounds.top, mappedElement.boundingRect.top - screenshotPadding),
              this.canvasBounds.top
            ),
            right: Math.min(
              Math.max(bounds.right, mappedElement.boundingRect.right + screenshotPadding),
              this.canvasBounds.right
            ),
            bottom: Math.min(
              Math.max(bounds.bottom, mappedElement.boundingRect.bottom + screenshotPadding),
              this.canvasBounds.bottom
            )
          };
        },
        {
          top: elementsInCanvasBounds[0].boundingRect.top,
          left: elementsInCanvasBounds[0].boundingRect.left,
          right: elementsInCanvasBounds[0].boundingRect.right,
          bottom: elementsInCanvasBounds[0].boundingRect.bottom
        }
      );
      const boundsWidth = elementBounds.right - elementBounds.left;
      const boundsHeight = elementBounds.bottom - elementBounds.top;
      const width = boundsWidth * horizontalScale;
      const height = boundsHeight * verticalScale;

      multiplier = targetScreenshotWidth / width;
      screenshotConfig = {
        width,
        height,
        top: verticalOffset + elementBounds.top * verticalScale,
        left: horizontalOffset + elementBounds.left * horizontalScale
      };
    } else {
      screenshotConfig = {
        width: dataUrlWidth,
        height: this.canvasSize.height * verticalScale,
        top: verticalOffset,
        left: horizontalOffset
      };
    }

    const canvas = this.toCanvasElement(multiplier, screenshotConfig);
    this.screenshotCanvas.width = targetScreenshotWidth;
    this.screenshotCanvas.height = targetScreenshotWidth;

    const screenshotCanvasCtx = this.screenshotCanvas.getContext('2d');
    if (screenshotCanvasCtx) {
      screenshotCanvasCtx.clearRect(0, 0, targetScreenshotWidth, targetScreenshotWidth);
      screenshotCanvasCtx.fillStyle = this.props.scene.settings.backgroundColor ?? defaultCanvasColor;
      screenshotCanvasCtx.fillRect(0, 0, targetScreenshotWidth, targetScreenshotWidth);

      const aspectRatio = canvas.width / canvas.height;
      let scaledWidth;
      let scaledHeight;
      if (aspectRatio > 1) {
        scaledWidth = targetScreenshotWidth;
        scaledHeight = targetScreenshotWidth / aspectRatio;
      } else {
        scaledWidth = targetScreenshotWidth * aspectRatio;
        scaledHeight = targetScreenshotWidth;
      }

      const destX = (targetScreenshotWidth - scaledWidth) / 2;
      const destY = (targetScreenshotWidth - scaledHeight) / 2;

      screenshotCanvasCtx.drawImage(canvas, destX, destY, scaledWidth, scaledHeight);
    }

    const thumbnail = this.screenshotCanvas.toDataURL();

    cameraElements.forEach(camera => {
      camera.visible = true;
      camera.dirty = true;
    });

    this.renderGridlines = gridWasVisible;
    this.renderAll();

    this.props.scene.thumbnailImage = thumbnail;
    return thumbnail;
  }

  /**
   * Ensure that the canvas elements are in the same stacking order as the elementIds array
   */
  public applyElementsStackingOrder() {
    this._objects.sort((a, b) => {
      if (assertCanvasElement(a) && assertCanvasElement(b)) {
        const aid = a.id;
        const bid = b.id;
        const indexA = this.props.elements.findIndex(el => el.id === aid);
        const indexB = this.props.elements.findIndex(el => el.id === bid);
        if (indexA > indexB) return 1;
        else if (indexA < indexB) return -1;
      }
      return 0;
    });
    this.forEachObject(fabricObject => {
      if (assertCanvasElement(fabricObject)) {
        const fabricObjectId = fabricObject.id;
        const index = this.props.elements.findIndex(el => el.id === fabricObjectId);
        // Ensure cameras are always on top
        if (this.props.elements[index]?.type === 'Camera') {
          fabricObject.moveTo(this.props.elements.length - 1 + BASE_INDEX_ADJUSTMENT);
        } else {
          fabricObject.moveTo(index - 1 + BASE_INDEX_ADJUSTMENT);
        }
      }
    });
  }

  setInitialSelection() {
    if (!this.elements) return;
    if (this.firstLoad) {
      this.onSetFirstLoad(false);
    }
  }

  private getCurrentEditingTextElementAndSelection() {
    const textElementWasEditing = this.textElementEditing;
    const textSelectionStartIndex = this.textElementEditing?.selectionStart ?? 0;
    const textSelectionEndIndex = this.textElementEditing?.selectionEnd ?? 0;

    return { textElementWasEditing, textSelectionStartIndex, textSelectionEndIndex };
  }

  private applyTextElementEditingState(
    textElement: TextElement,
    selectionStartIndex: number,
    selectionEndIndex: number
  ) {
    textElement.enterEditing();
    textElement.setSelectionStart(selectionStartIndex);
    textElement.setSelectionEnd(selectionEndIndex);
  }

  private updateActiveSelection() {
    if (this.elements) {
      if (this.canvasDragOn) {
        const selected = this.getActiveObjects();
        this.stashedElementIDs = selected.map(el => el.id);
        this.deselectStageElementsBeforeDragging();
        return;
      }

      // Store the editing text instance and selection indexes so they can be reapplied after update
      const {
        textElementWasEditing,
        textSelectionStartIndex,
        textSelectionEndIndex
      } = this.getCurrentEditingTextElementAndSelection();

      this.discardActiveObject();

      const cameraElements = this.elements.filter(el => el.elementType === 'Camera');
      cameraElements.forEach(camEl => {
        if (camEl && assertCanvasElementType('Camera', camEl)) {
          camEl.handleDeselection();
        }
      });

      const selectedElement = this.elements.filter(el => this.props.activeElements.includes(el.id));
      if (selectedElement.length > 1) {
        const doesSelectionContainLockedElements = selectedElement.some(el => el.locked);
        const doesSelectionContainHiddenElements = selectedElement.some(el =>
          'hidden' in el ? el.hidden === true : false
        );
        const selectedCameraElements = selectedElement.filter(el => el.elementType === 'Camera');

        const areAllSelectedElementsHidden = selectedElement.every(el => ('hidden' in el ? el.hidden === true : false));
        const areAllSelectedElementsCameras = selectedElement.every(el => el.elementType === 'Camera');

        const shouldLockSelection = doesSelectionContainLockedElements;
        const containsHiddenElements = doesSelectionContainHiddenElements;

        const groupProperties = {
          ...getLockedProperties(shouldLockSelection),
          ...getGroupVisibleProperties(shouldLockSelection, containsHiddenElements)
        };

        const noCamerasSelected = selectedCameraElements.length === 0;

        const newSelection = new ActiveSelection(selectedElement, {
          canvas: this,
          ...EDITOR_CANVAS_SELECTION_OPTIONS,
          ...groupProperties
        });

        newSelection.setControlsVisibility({
          ...EDITOR_CANVAS_CONTROL_VISIBILITY.group,
          mtr: noCamerasSelected,
          lockIndicator: shouldLockSelection
        });

        if (areAllSelectedElementsHidden) {
          newSelection.setControlsVisibility({
            ...EDITOR_CANVAS_CONTROL_VISIBILITY.hidden
          });
          newSelection.borderColor = 'transparent';
        }

        if (areAllSelectedElementsCameras) {
          newSelection.borderColor = CAMERA_COLOR;
          newSelection.cornerColor = CAMERA_COLOR;
          newSelection.borderScaleFactor = 2;
        }

        selectedCameraElements.forEach(el => {
          this.bringToFront(el);
        });

        this.setActiveObject(newSelection);
      } else if (selectedElement.length === 1) {
        if (selectedElement[0].elementType === 'Camera') {
          if (selectedElement[0] && assertCanvasElementType('Camera', selectedElement[0])) {
            selectedElement[0].handleSelection();
            this.bringToFront(selectedElement[0]);
          }
        }
        this.setActiveObject(selectedElement[0]);

        if (!!textElementWasEditing && textElementWasEditing === selectedElement[0]) {
          this.applyTextElementEditingState(textElementWasEditing, textSelectionStartIndex, textSelectionEndIndex);
        }
      } else {
        this.discardActiveObject();
      }
    }
  }

  private manageEventedOnIntersectingObjects() {
    // The property `evented` handles whether an object on the canvas will receive events
    // like `mousedown`, `selected` etc. If the object is higher than the active selection and they intersect
    // then we want to set `evented` to false on them allowing the active object to maintain movement below other objects.

    // Firstly, reset all evented to true to start fresh in each event handle
    const allObjects = this.getObjects();
    allObjects.forEach(fabricObject => {
      if (fabricObject.selectable === false) {
        fabricObject.evented = false;
      } else {
        fabricObject.evented = true;
      }
    });

    // Check if active selection is only one element and bail if not
    const activeObjects = this.getActiveObjects();
    if (activeObjects.length !== 1) return;

    // Find all objects higher in the stack (higher z-order) and if they intersect
    // with the active selection then set `evented` to false
    const activeObject = activeObjects[0];

    const activeObjectIndex = allObjects.findIndex(fabricObject => activeObject === fabricObject);
    const allInactiveObjectsHigherInStack = allObjects.slice(activeObjectIndex + 1);
    const intersectingObjects = allInactiveObjectsHigherInStack.filter(fabricObject =>
      activeObject.intersectsWithObject(fabricObject)
    );
    intersectingObjects.forEach(fabricObject => (fabricObject.evented = false));
  }

  private clampCameraCoordinates({
    left,
    top,
    width,
    height,
    minLeft = 0,
    minTop = 0
  }: {
    left: number;
    top: number;
    width: number;
    height: number;
    minLeft?: number;
    minTop?: number;
  }): { top: number; left: number } {
    const canvasCoords = this.getCanvasObjectCoordinates();

    const maxLeft = (canvasCoords?.right || 0) - width;
    const maxTop = (canvasCoords?.bottom || 0) - height;
    const topClamped = clamp(top, minTop, maxTop);
    const leftClamped = clamp(left, minLeft, maxLeft);

    return { left: leftClamped, top: topClamped };
  }

  private limitCameraMovement(event: IEvent) {
    if (!event.e) return; // if theres no mouse event then the handler was triggered by a programmatic change to the active selection

    const selected = event.target;
    if (!selected) return;

    selected.setCoords();

    if (assertIsCameraCanvasElement(selected)) {
      if (!selected.aCoords) return;
      const tlCoords = selected.aCoords.tl;
      const height = selected.getScaledHeight();
      const width = selected.getScaledWidth();

      const { top, left } = this.clampCameraCoordinates({
        left: tlCoords.x,
        top: tlCoords.y,
        width,
        height,
        minTop: this.frame.top,
        minLeft: this.frame.left
      });

      selected.top = top;
      selected.left = left;
    } else if (assertIsActiveSelection(selected)) {
      const selectedWidth = selected.getScaledWidth();
      const selectedHeight = selected.getScaledHeight();
      const xOffset = selectedWidth / 2;
      const yOffset = selectedHeight / 2;
      const elements = selected.getObjects();
      if (elements.every(el => assertIsElement(el) && el.elementType !== 'Camera')) return;

      const topCoords: Array<number> = [];
      const leftCoords: Array<number> = [];
      const rightCoords: Array<number> = [];
      const bottomCoords: Array<number> = [];

      elements.forEach(el => {
        if (assertIsCameraCanvasElement(el)) {
          if (!el.width || !el.height || !el.aCoords) return;
          topCoords.push(yOffset + el.aCoords.tl.y);
          leftCoords.push(xOffset + el.aCoords.tl.x);
          rightCoords.push(xOffset + el.aCoords.tl.x + el.getScaledWidth());
          bottomCoords.push(yOffset + el.aCoords.tl.y + el.getScaledHeight());
        }
      });

      const right = Math.max(...rightCoords);
      const bottom = Math.max(...bottomCoords);
      if (selected.left === undefined || selected.top === undefined) return;
      const newCoords = this.clampCameraCoordinates({
        left: selected.left,
        top: selected.top,
        width: right,
        height: bottom,
        minLeft: this.frame.left,
        minTop: this.frame.top
      });
      selected.top = newCoords.top;
      selected.left = newCoords.left;
    }
  }

  /*
    Update custom text styles on selected text elements on the canvas
    Used when we need to retrieve the updated value from Fabric to store
  */
  public updateCustomTextStyle<K extends keyof CustomisableTextStyleProps>(
    styleName: K,
    value: CustomisableTextStyleProps[K]
  ) {
    const activeObject = this.getActiveObject();
    const activeTextElements = (activeObject instanceof fabric.ActiveSelection
      ? activeObject.getObjects()
      : [activeObject]
    ).filter(assertIsTextCanvasElement) as Array<TextElement>;

    activeTextElements.forEach(el => {
      // As we are disabling custom styling to right-to-left texts
      // style updates will be applied to all characters
      const isTextUseFallbackDrawing = shouldTextUseFallbackDrawing(el.text ?? '');
      const isElementEditing = el.isEditing;
      const isUpdateTextColor = styleName === 'fill';
      if (isUpdateTextColor && isElementEditing) this.onSetSelectedTextColor(value);
      const currentSelectionIndex = el.getCurrentSelectionIndex();
      const selectionStartIndex = isElementEditing && !isTextUseFallbackDrawing ? currentSelectionIndex?.start ?? 0 : 0;
      const selectionEndIndex =
        isElementEditing && !isTextUseFallbackDrawing ? currentSelectionIndex?.end ?? 0 : el.text?.length ?? 0;
      el.setSelectionStyles({ [styleName]: value }, selectionStartIndex, selectionEndIndex);
    });

    this.requestRenderAll();
    this.handleObjectModified();
  }

  public async updateProps(newProps: ReactableCanvasProps) {
    if (this.updatingElements !== true) {
      this.user = newProps.user;
      this.updatingElements = true;
      if (newProps.hasOwnProperty('isPixiTimeline') && newProps.isPixiTimeline !== this.isPixiTimeline) {
        this.isPixiTimeline = newProps.isPixiTimeline;
      }
      if (newProps.hasOwnProperty('isLeftPanelOpen') && newProps.isLeftPanelOpen !== this.isLeftPanelOpen) {
        this.isLeftPanelOpen = newProps.isLeftPanelOpen;
      }
      if (isEqual(newProps, this.props) || !this.elements) {
        this.updatingElements = false;
        this.checkBufferedPropsCalls();
        return;
      }

      const elementsHaveChanged = !isEqual(this.props.elements, newProps.elements);
      const sceneHasChanged = !isEqual(this.props.scene, newProps.scene);
      const oldProps = this.props;
      this.props = cloneDeep(newProps);

      this.frame.updateProps(newProps.scene.settings);
      this.renderGridlines = newProps.gridlines;

      if (this.canvasDragOn !== newProps.canvasDragOn) {
        this.canvasDragOn = newProps.canvasDragOn;
        if (newProps.canvasDragOn) {
          this.setCursor('grab');
        } else {
          this.setCursor('auto');
        }
      }

      const deletions = this.elements.filter(element => !newProps.elements.find(el => element.id === el.id));
      deletions.forEach(el => this.remove(el));
      this.elements = this.elements.filter(el => !deletions.includes(el));

      const updatedImageElements: Array<VSElementModel> = [];
      const canvasElementsToRemove: Array<CanvasElement> = [];
      let groupMovementSet = false;
      const updatedTextElements: Array<TextElement> = [];
      this.elements.forEach(canvasElement => {
        const elementProps = this.props.elements.find(propEl => propEl.id === canvasElement.id);
        if (canvasElement.group && !groupMovementSet) {
          if (elementProps) {
            const diff = new Point(canvasElement.element.x - elementProps?.x, canvasElement.element.y - elementProps.y);
            if (
              diff.y !== 0 &&
              canvasElement.group?.top !== undefined &&
              Math.round(canvasElement.group?.top * 100) === Math.round(canvasElement.element.y * 100)
            ) {
              canvasElement.group.top = canvasElement.group.top - diff.y;
              groupMovementSet = true;
            }
            if (
              diff.x !== 0 &&
              canvasElement.group?.left !== undefined &&
              Math.round(canvasElement.group?.left * 100) === Math.round(canvasElement.element.x * 100)
            ) {
              canvasElement.group.left = canvasElement.group.left - diff.x;
              groupMovementSet = true;
            }
          }
        }
        if (canvasElement.elementType === 'Text' && assertCanvasElementType('Text', canvasElement)) {
          if (elementProps && assertElementType('Text', elementProps)) {
            const oldElement = noCameras(oldProps.elements).find(el => el.id === elementProps.id);

            if (oldElement && elementDimensionsHaveChanged(oldElement, elementProps)) {
              // Fabric object scale was resetting after update, so we are recreating the instances.
              canvasElementsToRemove.push(canvasElement);
              if (canvasElement.text !== elementProps.text) {
                elementProps.text = canvasElement.text;
              }
              updatedImageElements.push(elementProps);
            } else {
              canvasElement.updateProps(elementProps);
              // Get the updated dimensions derived from fabric if they have changed
              // Currently, the changes can be triggered within the canvas or from the editor
              if (typeof canvasElement.width === 'number' && typeof canvasElement.height === 'number') {
                const hasDimensionChanged =
                  canvasElement.width !== elementProps.width || canvasElement.height !== elementProps.height;
                const hasCharBoundChanged = !isEqual(canvasElement.__charBounds, elementProps.charBounds);
                if (hasDimensionChanged || hasCharBoundChanged) updatedTextElements.push(canvasElement);
              }
            }
          }
        }

        if (canvasElement.elementType === 'Shape' && assertCanvasElementType('Shape', canvasElement)) {
          if (elementProps && assertElementType('Shape', elementProps)) {
            canvasElement.updateProps(elementProps);
          }
        }

        if (canvasElement.elementType === 'Image' && assertCanvasElementType('Image', canvasElement)) {
          if (elementProps && assertElementType('Image', elementProps)) {
            const oldElement = noCameras(oldProps.elements).find(el => el.id === elementProps.id);

            const urlChange =
              oldElement && assertElementType('Image', oldElement) && oldElement._imageUrl !== elementProps._imageUrl;

            if (
              !!urlChange ||
              (isSVGElement(elementProps) && oldElement && elementDimensionsHaveChanged(oldElement, elementProps))
            ) {
              canvasElementsToRemove.push(canvasElement);
              updatedImageElements.push(elementProps);
            } else {
              canvasElement.updateProps(elementProps);
            }
          }
        }

        if (canvasElement.elementType === 'Camera' && assertCanvasElementType('Camera', canvasElement)) {
          if (elementProps && assertElementType('Camera', elementProps)) {
            canvasElement.updateProps(elementProps);
          }
        }
      });

      if (updatedTextElements.length === 0) {
        const additions = newProps.elements.filter(propEl => {
          if (!this.elements) return false;
          return !this.elements.find(el => el.id === propEl.id);
        });

        const newElements = await this.createElements([...additions, ...updatedImageElements]);

        const elementIdsToRemove = canvasElementsToRemove.map(el => el.id);

        this.elements = this.elements?.filter(element => !elementIdsToRemove.includes(element.id));

        const existingElementIds: Array<string> = this.elements.map(el => {
          return el.id;
        });

        const newElementsToAdd: Array<CanvasElement> = [];

        newElements.forEach(newEl => {
          if (!existingElementIds.includes(newEl.id)) {
            newElementsToAdd.push(newEl);
          }
        });

        this.elements = [...this.elements, ...newElementsToAdd];

        this.add(...newElementsToAdd);

        // Store the editing text instance and selection indexes so they can be reapplied after update
        const {
          textElementWasEditing,
          textSelectionStartIndex,
          textSelectionEndIndex
        } = this.getCurrentEditingTextElementAndSelection();

        canvasElementsToRemove.forEach(el => this.remove(el));

        this.updateCameraIndexes();

        // We need to update active selection for a second time to handle group text resize
        this.updateActiveSelection();

        if (elementsHaveChanged || sceneHasChanged) {
          this.onVisualUpdate(this.renderAndGenerateThumb());
        }

        this.updateScrollbars({ storeContentBounds: true });

        if (oldProps.canvasDragOn && !newProps.canvasDragOn) {
          this.onSetActiveElements(this.stashedElementIDs);
          this.stashedElementIDs = [];
        }

        this.applyElementsStackingOrder();

        if (textElementWasEditing && newProps.activeElements.includes(textElementWasEditing.id)) {
          const newTextBoxInstance = newElementsToAdd.find(el => el.id === textElementWasEditing.id);
          if (newTextBoxInstance && assertIsTextCanvasElement(newTextBoxInstance)) {
            this.applyTextElementEditingState(newTextBoxInstance, textSelectionStartIndex, textSelectionEndIndex);
          }
        }
      } else {
        this.onUpdateTextfieldDimensions(
          updatedTextElements.map(el => ({
            elementId: el.id,
            width: el.width as number,
            height: el.height as number,
            charBounds: el.__charBounds as TextElementCharBounds,
            lineWidths: el.__lineWidths as Array<number>
          }))
        );
      }
      this.updatingElements = false;
      this.checkBufferedPropsCalls();
    } else {
      this.bufferedPropsCalls.push({ ...newProps });
    }
  }
  private checkBufferedPropsCalls() {
    if (this.bufferedPropsCalls.length > 0) {
      const nextProps = this.bufferedPropsCalls.shift();
      if (nextProps) {
        this.updateProps(nextProps);
      }
    }
  }

  /**
   * When there has been a canvas resize event we need to update the fabric canvas so it knows it's new dimensions.
   *
   * On first load it will size the project frame into the size of the html canvas element.
   * On other resize events it will only update the fabric canvas with it's new dimensions.
   *
   * @param entries
   */
  public updateSize = (entries: Array<ResizeObserverEntry>) => {
    const wrapper = entries[0];
    if (wrapper) {
      const { width: containerWidth, height: containerHeight } = wrapper.contentRect;
      if (this.firstLoadShowAll) {
        if (this.editorTransform) {
          this.setViewportTransform(this.editorTransform);
          this.updateZoomLevel(this.getZoom());
        } else {
          this.setDimensions({ width: containerWidth, height: containerHeight });
          // Viewport transforms are defined by the canvas 2D API
          // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/transform
          const newViewportTransform = this.calculateViewportTransform(
            EXPANDED_CANVAS_SIZE.width,
            EXPANDED_CANVAS_SIZE.height
          );
          this.setDimensions({ width: containerWidth, height: containerHeight });
          // Viewport transforms are defined by the canvas 2D API
          // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/transform
          this.setViewportTransform(newViewportTransform);
          this.onUpdateZoom(newViewportTransform[0]);
        }
        this.firstLoadShowAll = false;
      } else {
        this.setDimensions({ width: containerWidth, height: containerHeight });
        if (this.isPixiTimeline !== this.wasPixiTimeline || this.isLeftPanelOpen !== this.wasLeftPanelOpen) {
          // don't update the zoom level if we are switching between timeline types
        } else {
          // the updateSize method is called when the canvas is resized, so we need to update the zoom level
          this.updateZoomLevel(this.getZoom());
        }
      }
      this.wasPixiTimeline = this.isPixiTimeline;
      this.wasLeftPanelOpen = this.isLeftPanelOpen;
      this.renderAll();
      this.updateScrollbars();
    }
  };

  /**
   * This method will reset the canvas position and zoom.
   */
  resetViewport() {
    const width = this.getWidth();
    const height = this.getHeight();
    const newViewportTransform = this.calculateViewportTransform(width, height);
    this.setViewportTransform(newViewportTransform);
    this.onUpdateZoom(newViewportTransform[0]);
    this.renderAll();
    this.updateScrollbars();
  }

  async zoomToFitAll() {
    await this.discardActiveObjectAndWaitForNextEventLoop();
    const newViewportTransform = this.calculateMinTransform();
    this.updateMinZoom();
    this.setViewportTransform(newViewportTransform);
    this.onUpdateZoom(newViewportTransform[0]);
    this.updateActiveSelection();
    this.renderAll();
    this.updateScrollbars();
  }

  viewportBounds() {
    const currentZoom = this.getZoom();
    const viewportBounderies = this.calcViewportBoundaries();

    const viewportRect = new PIXI.Rectangle(
      viewportBounderies.tl.x * currentZoom,
      viewportBounderies.tl.y * currentZoom,
      (viewportBounderies.br.x - viewportBounderies.tl.x) * currentZoom,
      (viewportBounderies.br.y - viewportBounderies.tl.y) * currentZoom
    );
    return viewportRect;
  }
  /**
   * Will calculate the viewport transform matrix based on a given width and height.
   *
   * @param width
   * @param height
   * @returns
   */
  calculateViewportTransform(width: number, height: number, tl = { x: 0, y: 0 }) {
    const viewportRect = this.viewportBounds();

    const hScale = viewportRect.width / width;
    const vScale = viewportRect.height / height;

    const scale = Math.min(hScale, vScale);
    const horizontalOffset = viewportRect.width / 2 - (tl.x + width / 2) * scale;
    const verticalOffset = viewportRect.height / 2 - (tl.y + height / 2) * scale;

    return [scale, 0, 0, scale, horizontalOffset, verticalOffset];
  }

  updateCameraIndexes() {
    if (this.elements) {
      const orderedCameras: CameraElement[] = [];
      this.props.scene.elementIds.forEach(id => {
        const cameraEl = this.elements?.find(el => el.id === id && el.elementType === 'Camera');

        if (cameraEl && assertCanvasElementType('Camera', cameraEl)) {
          orderedCameras.push(cameraEl);
        }
      });

      let currentIndex = CANVAS_CAMERA_INDEX_OFFSET;
      orderedCameras.forEach(element => {
        element.updateCameraIndex(currentIndex);

        currentIndex++;
      });
    }
  }
  /**
   * Will group all the content on the canvas, and stores the bounding rectangle object
   */
  public storeContentBounds() {
    const objects = this.getObjects();
    const rectangle = objects.reduce(
      (rect, current) => {
        if (current.group) {
          const { top, height, left, width } = current.group.getBoundingRect(true);
          return {
            top: Math.min(rect.top, top),
            left: Math.min(rect.left, left),
            bottom: Math.max(rect.bottom, top + height),
            right: Math.max(rect.right, left + width)
          };
        } else {
          if (current.type === 'rect') {
            return rect;
          }

          const { top, height, left, width } = current.getBoundingRect(true);
          return {
            top: Math.min(rect.top, top),
            left: Math.min(rect.left, left),
            bottom: Math.max(rect.bottom, top + height),
            right: Math.max(rect.right, left + width)
          };
        }
      },
      { top: 0, bottom: 0, left: 0, right: 0 }
    );

    this.contentBounds = {
      top: rectangle.top,
      left: rectangle.left,
      height: rectangle.bottom - rectangle.top,
      width: rectangle.right - rectangle.left
    };
  }

  /**
   * Calculates the dimensions of the scrollbars we need, based on the stored content bounds and our current viewport
   */
  private updateScrollbars(options?: { storeContentBounds?: boolean }) {
    // Calculate & Store the content dimensions and only update them when updateProps is called
    if (options?.storeContentBounds) {
      this.storeContentBounds();
    }

    if (!this.vptCoords) return;
    this.calcViewportBoundaries();

    const canvasCoordinates = this.getCanvasObjectCoordinates();
    if (!canvasCoordinates) return;

    if (canvasCoordinates.left > this.contentBounds.left) {
      canvasCoordinates.left = this.contentBounds.left;
    }

    if (canvasCoordinates.right < this.contentBounds.width + this.contentBounds.left) {
      canvasCoordinates.right = this.contentBounds.left + this.contentBounds.width;
    }

    if (this.contentBounds.top < canvasCoordinates.top) {
      canvasCoordinates.top = this.contentBounds.top;
    }

    if (this.contentBounds.top + this.contentBounds.height > canvasCoordinates.bottom) {
      canvasCoordinates.bottom = this.contentBounds.top + this.contentBounds.height;
    }

    const { top, bottom, left, right } = canvasCoordinates;
    const zoom = this.getZoom();

    const verticalSize = (bottom - top) * zoom;
    const verticalOffsetPercentage = Maths.rangeValue(top, bottom, this.vptCoords.tl.y);
    const horizontalSize = (right - left) * zoom;
    const horizontalOffsetPercentage = Maths.rangeValue(left, right, this.vptCoords.tl.x);

    const update = {
      width: horizontalSize,
      height: verticalSize,
      verticalOffset: verticalOffsetPercentage,
      horizontalOffset: horizontalOffsetPercentage
    };

    this.onUpdateScrollbars(update);
  }

  public setScrollbarDrag({ mouseX, mouseY, size }: { mouseX?: number; mouseY?: number; size: number }) {
    const viewportTransform = this.viewportTransform;
    if (!viewportTransform) return;

    const canvasCoordinates = this.getCanvasObjectCoordinates();

    if (!canvasCoordinates) return;
    const { top, bottom, left, right } = canvasCoordinates;

    if (this.textElementEditing) {
      this.textElementEditing.exitEditing();
    }

    const zoom = this.getZoom();

    if (mouseX) {
      const pixelDistance = ((right - left) / size) * zoom;

      this.lastPosX = this.lastPosX ?? mouseX;

      const difference = (mouseX - this.lastPosX) * pixelDistance;

      const newXTargetViewportTransform = viewportTransform[4] - difference;

      const { x } = this.clampCoordinates({ x: newXTargetViewportTransform });

      if (x !== undefined) {
        viewportTransform[4] = x;
      }
    }

    if (mouseY) {
      const pixelDistance = ((bottom - top) / size) * zoom;

      this.lastPosY = this.lastPosY ?? mouseY;

      const difference = (mouseY - this.lastPosY) * pixelDistance;

      const newYTargetViewportTransform = viewportTransform[5] - difference;

      const { y } = this.clampCoordinates({ y: newYTargetViewportTransform });

      if (y !== undefined) {
        viewportTransform[5] = y;
      }
    }

    this.setViewportTransform(viewportTransform);

    this.lastPosX = mouseX;
    this.lastPosY = mouseY;

    this.updateScrollbars();
  }

  /**
   * The offsets represent the desired center position of our viewport.
   *
   *They are a percentage of the distance between the top of the content/viewport and the bottom of the content/viewport
   * We need to take this offset percentage and turn it into a viewport transform
   *
   */
  public setScrollbars({ offsetY, offsetX }: { offsetY?: number; offsetX?: number }) {
    // As this event is likely going to be called on an mouse button up event, we don't want to fire it if we are dragging.
    if (this.lastPosX !== undefined || this.lastPosY !== undefined || this.isDragging) {
      this.lastPosX = undefined;
      this.lastPosY = undefined;
      this.isDragging = false;
      return;
    }

    const viewportTransform = this.viewportTransform;
    const viewportCoords = this.vptCoords;

    if (!viewportTransform || !viewportCoords) return;

    const coords = this.getCanvasObjectCoordinates();
    if (!coords) return;

    const zoom = this.getZoom();

    const { top, bottom, left, right } = coords;

    if (offsetX) {
      const scrollBarTrackWidth = right - left;

      const canvasWidthOffset = this.getWidth() / 2;

      const newXCoord = (scrollBarTrackWidth * offsetX + left) * zoom;

      viewportTransform[4] = -newXCoord + canvasWidthOffset;
    }

    if (offsetY) {
      const scrollBarTrackHeight = bottom - top;

      const canvasHeightOffset = this.getHeight() / 2;

      const newYCoord = (scrollBarTrackHeight * offsetY + top) * zoom;

      viewportTransform[5] = -newYCoord + canvasHeightOffset;
    }

    this.setViewportTransform(viewportTransform);
    this.updateScrollbars();
  }

  /**
   * Uses the stored content bounds and compares against the viewport to return the top, bottom, left and right coordinates.
   *
   */
  public getCanvasObjectCoordinates() {
    const canvasCoordinates = {
      top: -EXPANDED_CANVAS_SIZE.height * this.maxScrollMultiplyer,
      bottom: EXPANDED_CANVAS_SIZE.height * (this.maxScrollMultiplyer + 1),
      left: -EXPANDED_CANVAS_SIZE.width * this.maxScrollMultiplyer,
      right: EXPANDED_CANVAS_SIZE.width * (this.maxScrollMultiplyer + 1)
    };

    return canvasCoordinates;
  }

  async discardActiveObjectAndWaitForNextEventLoop() {
    this.discardActiveObject();
    // Discarding the active object changes grouped elements positions from relative to absolute
    // but any updates straight after would happen in the same js event loop and the positions will still be
    // relative. This timeout allows us to wait for the next js event loop before continuing.
    await new Promise(resolve => {
      window.setTimeout(resolve, 1);
    });
  }

  mapBoundingBoxesToObjects(objects: Array<fabric.Object>) {
    return objects.map(fabricObject => {
      const getAbsolute = true;
      const shouldCalculate = true;
      const boundingRect = fabricObject.getBoundingRect(getAbsolute, shouldCalculate);
      return {
        fabricObject,
        boundingRect: new Rectangle(boundingRect.left, boundingRect.top, boundingRect.width, boundingRect.height)
      };
    });
  }

  public async alignSelectedVisibleUnlockedElements(action: AlignmentAction) {
    const activeSelection = this.getActiveObject();

    if (!(activeSelection instanceof fabric.ActiveSelection)) return;

    const fabricObjects = activeSelection.getObjects();
    const visibleFabricObjects = fabricObjects.filter(fabricObject => fabricObject.visible);

    const objectsAndAbsoluteBoundingBoxes = this.mapBoundingBoxesToObjects(visibleFabricObjects);
    const groupBounds = getAlignmentRectangleFromBounds(objectsAndAbsoluteBoundingBoxes.map(box => box.boundingRect));

    const unlockedElementsWithDistanceToGroupBounds = objectsAndAbsoluteBoundingBoxes
      .filter(box => !box.fabricObject.lockMovementX)
      .map(box => {
        const toTop = box.boundingRect.top - groupBounds.top;
        const toBottom = groupBounds.bottom - box.boundingRect.bottom;
        const toLeft = box.boundingRect.left - groupBounds.left;
        const toRight = groupBounds.right - box.boundingRect.right;
        const toHCenter = groupBounds.hCenter - box.boundingRect.left - box.boundingRect.width / 2;
        const toVCenter = groupBounds.vCenter - box.boundingRect.top - box.boundingRect.height / 2;

        return {
          ...box,
          toTop,
          toBottom,
          toLeft,
          toRight,
          toHCenter,
          toVCenter
        };
      });

    // We discard the active object (selection group) so the positional values are absolute when we adjust them
    // And we have to wait for the next event loop to wait for the change from relative to absolute positions to take effect
    // otherwise there is a race condition between setting the values when the objects are in relative space or absolute space.
    await this.discardActiveObjectAndWaitForNextEventLoop();

    switch (action) {
      case ALIGN_ACTION_TOP: {
        unlockedElementsWithDistanceToGroupBounds.forEach(element => {
          element.fabricObject.top = round(
            (element.fabricObject.top ?? 0) - element.toTop,
            PROPERTIES_FLOATING_POINT_PRECISION
          );
        });
        break;
      }
      case ALIGN_ACTION_BOTTOM: {
        unlockedElementsWithDistanceToGroupBounds.forEach(element => {
          element.fabricObject.top = round(
            (element.fabricObject.top ?? 0) + element.toBottom,
            PROPERTIES_FLOATING_POINT_PRECISION
          );
        });
        break;
      }
      case ALIGN_ACTION_RIGHT: {
        unlockedElementsWithDistanceToGroupBounds.forEach(element => {
          element.fabricObject.left = round(
            (element.fabricObject.left ?? 0) + element.toRight,
            PROPERTIES_FLOATING_POINT_PRECISION
          );
        });
        break;
      }
      case ALIGN_ACTION_LEFT: {
        unlockedElementsWithDistanceToGroupBounds.forEach(element => {
          element.fabricObject.left = round(
            (element.fabricObject.left ?? 0) - element.toLeft,
            PROPERTIES_FLOATING_POINT_PRECISION
          );
        });
        break;
      }
      case ALIGN_ACTION_H_CENTER: {
        unlockedElementsWithDistanceToGroupBounds.forEach(element => {
          element.fabricObject.left = round(
            (element.fabricObject.left ?? 0) + element.toHCenter,
            PROPERTIES_FLOATING_POINT_PRECISION
          );
        });
        break;
      }
      case ALIGN_ACTION_V_CENTER: {
        unlockedElementsWithDistanceToGroupBounds.forEach(element => {
          element.fabricObject.top = round(
            (element.fabricObject.top ?? 0) + element.toVCenter,
            PROPERTIES_FLOATING_POINT_PRECISION
          );
        });
        break;
      }

      default:
        break;
    }

    // Cause the editor to send back updated values to React
    this.fire('object:modified');

    // We re-apply the active selection and render
    // to make sure the elements are still selected after the transform
    this.updateActiveSelection();
    this.renderAll();
  }

  async distributeSelectedVisibleUnlockedElements(action: DistributionAction) {
    const activeSelection = this.getActiveObject();

    if (!(activeSelection instanceof fabric.ActiveSelection)) return;

    const fabricObjects = activeSelection.getObjects();
    const visibleFabricObjects = fabricObjects.filter(fabricObject => fabricObject.visible);

    await this.discardActiveObjectAndWaitForNextEventLoop();

    const objectsAndAbsoluteBoundingBoxes = visibleFabricObjects.map(fabricObject => {
      const getAbsolute = true;
      const shouldCalculate = true;
      const boundingRect = fabricObject.getBoundingRect(getAbsolute, shouldCalculate);
      return {
        fabricObject,
        boundingRect: new Rectangle(boundingRect.left, boundingRect.top, boundingRect.width, boundingRect.height)
      };
    });

    const sortByDimension = action === DISTRIBUTE_ACTION_HORIZONTAL ? 'left' : 'top';
    const endObjectSortByDimension = action === DISTRIBUTE_ACTION_HORIZONTAL ? 'right' : 'bottom';
    const lengthDimension = action === DISTRIBUTE_ACTION_HORIZONTAL ? 'width' : 'height';

    const sortedObjects = [...objectsAndAbsoluteBoundingBoxes].sort((a, b) => {
      if (a.boundingRect[sortByDimension] < b.boundingRect[sortByDimension]) {
        return -1;
      }
      if (a.boundingRect[sortByDimension] > b.boundingRect[sortByDimension]) {
        return 1;
      }
      return 0;
    });

    const startObject = sortedObjects[0];
    const endObject = sortedObjects
      .slice(1) // remove the start object
      .sort((a, b) => {
        if (a.boundingRect[endObjectSortByDimension] > b.boundingRect[endObjectSortByDimension]) {
          return -1;
        }
        if (a.boundingRect[endObjectSortByDimension] < b.boundingRect[endObjectSortByDimension]) {
          return 1;
        }
        return 0;
      })[0];
    const objectsToDistribute = sortedObjects.filter(obj => {
      const isEdgeElement = [startObject, endObject].includes(obj);
      const isLockedElement = !!obj.fabricObject.lockMovementX;
      if (isEdgeElement || isLockedElement) return false;

      return true;
    });

    const distanceBetweenStartEnd =
      endObject.boundingRect[sortByDimension] - startObject.boundingRect[endObjectSortByDimension];
    const objectsToDistributeLength = objectsToDistribute.reduce((accumulator, object) => {
      return accumulator + object.boundingRect[lengthDimension];
    }, 0);
    const remainingSpace = distanceBetweenStartEnd - objectsToDistributeLength;
    const gapSize = remainingSpace / (objectsToDistribute.length + 1);

    objectsToDistribute.forEach((obj, index, objs) => {
      const prevObj = index === 0 ? startObject : objs[index - 1];
      const prevObjectUpdatedBounds = prevObj.fabricObject.getBoundingRect(true, true);

      obj.fabricObject.set({
        [sortByDimension]:
          prevObjectUpdatedBounds[sortByDimension] + prevObjectUpdatedBounds[lengthDimension] + gapSize,
        dirty: true
      });
    });

    this.fire('object:modified');

    this.updateActiveSelection();
    this.renderAll();
  }

  renderCanvas(ctx: CanvasRenderingContext2D, objects: fabric.Object[]): fabric.Canvas {
    if (ctx === null) {
      return this;
    }
    super.renderCanvas(ctx, objects);

    if (this.renderGridlines) {
      generateGridlines(ctx, this.canvasSize, this.viewportTransform);
    }

    return this;
  }

  getViewportRectAndCenter() {
    const viewportRect = this.calcViewportBoundaries();
    if (!viewportRect) return;
    const tl = viewportRect.tl;
    const br = viewportRect.br;
    const vpRect = new PIXI.Rectangle(tl.x, tl.y, br.x - tl.x, br.y - tl.y);
    const viewportCenter = new PIXI.Point(vpRect.x + vpRect.width / 2, vpRect.y + vpRect.height / 2);

    return { viewportRectangle: vpRect, viewportCenter };
  }
}
