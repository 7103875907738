import {
  call,
  put,
  take,
  takeEvery,
  select,
  SelectEffect,
  CallEffect,
  PutEffect,
  TakeEffect,
  takeLatest
} from 'redux-saga/effects';
import {
  getUserFonts as getUserFontsAction,
  GET_USER_FONTS_SUCCESS,
  UPLOAD_FONT_FILES,
  getUserFontsSuccess,
  GET_USER_FONTS_REQUESTED,
  showUploadCustomFontsModal,
  uploadProgressStatus,
  LOAD_USER_FONTS,
  loadUserFonts as loadUserFontsAction,
  loadUserFontsSuccess,
  updateModalStage,
  uploadConfirmationStatus,
  deleteUserFontSuccess,
  DELETE_USER_FONT_REQUESTED,
  showDeleteCustomFontsModal,
  LoadUserFontsAction,
  UploadFontFilesAction,
  DeleteUserFontAction,
  GetUserFontsRequestedAction,
  DELETE_USER_FONT_SUCCESS,
  UPDATE_BITMAP_FONTS,
  UpdateBitmapFontsAction,
  updateBitmapFontsDone
} from 'js/actionCreators/fontActions';
import { loadUserSelectableFonts } from 'js/shared/helpers/preloadScribeFonts';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { showError } from 'js/actionCreators/uiActions';
import { sendErrorToSentry } from 'js/logging';
import { ALLOWED_FONT_TYPES, MAX_SIMULTANEOUS_FILE_UPLOAD } from 'js/config/defaults';
import allFonts from 'js/shared/resources/fonts';
import { getUserFontName, getUserFontFileExtension } from 'js/shared/helpers/getUserFontName';
import cloneDeep from 'lodash.clonedeep';
import { RootState, VSCAssetData, VSCFontAssetData } from 'js/types';
import ScribeTextElementModel from 'js/models/ScribeTextElementModel';
import { defaultFontLabel, defaultFontValue } from 'js/shared/resources/scribedefaults';
import { updateScribe } from 'js/actionCreators/scribeActions';
import { calculateFontBaselineShifts } from 'js/playback/lib/Playback/helpers/fontBaselineShift';
import { deleteFontBaselineShift } from 'js/shared/lib/LocalDatabase';
import { generateBitmapFonts } from 'js/playback/lib/Playback/helpers/generateBitmapFonts';

import { trackDeleteUserFont } from './mixpanel/saga/trackUserFontsSaga';

export function* loadUserFontsSaga({
  scribe
}: LoadUserFontsAction): Generator<SelectEffect | CallEffect | PutEffect, void, Array<VSCFontAssetData>> {
  const userFonts = yield select(state => state.fonts.userFonts);

  const userFontsList = userFonts.reduce<Array<{ fontname: string; fontUrl: string }>>((acc, current) => {
    const filename = current.filename;
    if (!filename) {
      return acc;
    }

    acc.push({ fontname: getUserFontName(filename), fontUrl: current.assetUrl });
    return acc;
  }, []);

  const currentScribeText = scribe.elements
    .filter((el): el is ScribeTextElementModel => el.type === 'Text')
    .reduce((prev, el) => {
      return prev + el.text;
    }, '');

  yield call(loadUserSelectableFonts, currentScribeText, userFontsList);
  yield put(loadUserFontsSuccess());
}

export function* uploadFontFiles({
  fontFiles,
  activeScribe
}: UploadFontFilesAction): Generator<
  SelectEffect | CallEffect | PutEffect | TakeEffect,
  void,
  Array<VSCFontAssetData>
> {
  const fontFileList = fontFiles ? Array.from(fontFiles) : [];
  let currentPercentage = 0;
  let confirmationObject;

  const userFonts = yield select(state => state.fonts.userFonts);

  if (fontFileList.length > MAX_SIMULTANEOUS_FILE_UPLOAD) {
    yield put(showUploadCustomFontsModal(false));
    yield put(
      showError({
        message: 'You have exceeded the maximum number of simultaneous uploads',
        description: 'Maximum 20 font files at a time'
      })
    );
    return;
  }

  const fontFileNames = fontFileList.map(font => getUserFontName(font.name));
  const uniqueFiles = new Set(fontFileNames);

  if (uniqueFiles.size !== fontFileNames.length) {
    yield put(showUploadCustomFontsModal(false));
    yield put(
      showError({
        message: 'You cannot upload multiple files with the same name.',
        description: 'Please check your file names and try again.'
      })
    );
    return;
  }

  yield put(updateModalStage(2));

  const correctFileTypes = fontFileList.filter(file => {
    const name = getUserFontFileExtension(file.name);
    if (!name) {
      return false;
    }

    return ALLOWED_FONT_TYPES.includes(name);
  });

  const incorrectFileTypes = fontFileList.filter(file => {
    const name = getUserFontFileExtension(file.name);
    if (!name) {
      return false;
    }
    return !ALLOWED_FONT_TYPES.includes(name);
  });

  const showSomeFilesIncompatibleError = incorrectFileTypes.length > 0;

  const incorrectFileTypeNames: Array<string> = [];

  if (showSomeFilesIncompatibleError) {
    for (const file of incorrectFileTypes) {
      incorrectFileTypeNames.push(file.name);
    }
  }

  yield put(uploadProgressStatus((currentPercentage += 10), 'Checking for duplicates'));

  const listOfFontNames = [];
  allFonts.forEach(font => {
    listOfFontNames.push(font.value);
  });

  for (const font of userFonts) {
    const filename = font.filename;
    if (filename) {
      listOfFontNames.push(getUserFontName(filename));
    }
  }

  const filesToProcess: Array<File> = [];
  const duplicateFileNames: Array<string> = [];

  for (const file of correctFileTypes) {
    if (listOfFontNames.includes(getUserFontName(file.name))) {
      duplicateFileNames.push(getUserFontName(file.name));
    } else {
      filesToProcess.push(file);
    }
  }

  yield put(uploadProgressStatus((currentPercentage += 10), 'Uploading font files'));
  if (filesToProcess.length === 0) {
    confirmationObject = {
      invalidFiles: incorrectFileTypeNames,
      duplicateFiles: duplicateFileNames,
      filesUploaded: []
    };
  }

  const percentageIncrement = 60 / filesToProcess.length;

  const uploadedFileNames: Array<string> = [];

  try {
    for (const file of filesToProcess) {
      yield put(uploadProgressStatus((currentPercentage += percentageIncrement), 'Uploading font files'));
      yield call(appServices.uploadAsset, { file, filename: file.name });
      uploadedFileNames.push(file.name);
    }

    yield put(getUserFontsAction(activeScribe.id));
    yield take(GET_USER_FONTS_SUCCESS);
    yield put(loadUserFontsAction(activeScribe));

    yield put(uploadProgressStatus(100, 'complete'));

    confirmationObject = {
      invalidFiles: incorrectFileTypeNames,
      duplicateFiles: duplicateFileNames,
      filesUploaded: uploadedFileNames
    };
    yield put(uploadConfirmationStatus(confirmationObject));
    yield put(updateModalStage(3));
  } catch (err) {
    if (err instanceof Error) {
      confirmationObject = {
        invalidFiles: [],
        duplicateFiles: [],
        filesUploaded: []
      };
      yield put(uploadConfirmationStatus(confirmationObject, err));
      yield put(updateModalStage(3));
    }
  }
}

const convertAssetDataToFontAssetData = async (assets: Array<VSCAssetData>): Promise<Array<VSCFontAssetData>> => {
  const result: Array<VSCFontAssetData> = [];
  for (const asset of assets) {
    const assetUrl = await appServices.getAssetUrl(asset.projectAssetId);
    const clonedAsset = { ...cloneDeep(asset), assetUrl };
    result.push(clonedAsset);
  }

  return result;
};

export function* getUserFonts({ scribeId }: GetUserFontsRequestedAction) {
  try {
    const fontsAsAssets: Array<VSCAssetData> = yield call(appServices.getFontsList, scribeId);
    const fonts: Array<VSCFontAssetData> = yield call(convertAssetDataToFontAssetData, fontsAsAssets);

    yield put(getUserFontsSuccess(fonts));
  } catch (err) {
    if (err instanceof Error) {
      sendErrorToSentry(err);
      yield put(showError(err));
    }
  }
}

export function* deleteUserFonts({
  fontId,
  activeScribe
}: DeleteUserFontAction): Generator<SelectEffect | CallEffect | PutEffect, void, RootState> {
  const state = yield select();
  const userFontsList = state.fonts.userFonts;

  const userFont = userFontsList.find(font => font.projectAssetId === fontId);

  if (!userFont) {
    return;
  }

  try {
    yield call(appServices.deleteAsset, fontId);
    const filename = userFont.filename;
    if (filename) {
      const fontName = getUserFontName(filename);
      document.fonts.forEach(font => {
        if (font.family === fontName) {
          document.fonts.delete(font);
        }
      });
    }
    const newScribe = cloneDeep(activeScribe);

    newScribe.elements.forEach(element => {
      if (element.type === 'Text' && element.font.assetId === fontId) {
        element.font = {
          label: defaultFontLabel,
          value: defaultFontValue
        };
      }
    });

    yield call(trackDeleteUserFont, filename);
    yield put(showDeleteCustomFontsModal(false));
    yield put(deleteUserFontSuccess(fontId));

    yield put(updateScribe(newScribe));
  } catch (err) {
    if (err instanceof Error) {
      sendErrorToSentry(err);
      yield put(showError(err));
      yield put(showDeleteCustomFontsModal(false));
    }
  }
}

export function* processUpdatedElementFontBaselineShifts(
  textElements: ScribeTextElementModel[]
): Generator<CallEffect, void, void> {
  try {
    if (textElements.length === 0) return;
    yield call(calculateFontBaselineShifts, textElements);
  } catch (error) {
    console.error(error);
  }
}

export function* processRemovedUserFontFontBaselineShiftFromDbCache({
  fontId
}: DeleteUserFontAction): Generator<SelectEffect | CallEffect<void> | TakeEffect, void, VSCFontAssetData | undefined> {
  const font = yield select((state: RootState) => state.fonts.userFonts.find(font => font.projectAssetId === fontId));

  if (!font || !font.filename) return;

  const fontFamily = getUserFontName(font.filename);
  yield take(DELETE_USER_FONT_SUCCESS);
  yield call(deleteFontBaselineShift, fontFamily);
}

export function* updateBitmapFonts({
  textElements
}: UpdateBitmapFontsAction): Generator<PutEffect | CallEffect, void, void> {
  try {
    yield call(generateBitmapFonts, textElements);
    yield call(processUpdatedElementFontBaselineShifts, textElements);
    yield put(updateBitmapFontsDone());
  } catch (error) {
    yield put(updateBitmapFontsDone());
  }
}

function* fontSagas() {
  yield takeEvery(UPLOAD_FONT_FILES, uploadFontFiles);
  yield takeEvery(GET_USER_FONTS_REQUESTED, getUserFonts);
  yield takeEvery(LOAD_USER_FONTS, loadUserFontsSaga);
  yield takeEvery(DELETE_USER_FONT_REQUESTED, deleteUserFonts);
  yield takeEvery(DELETE_USER_FONT_REQUESTED, processRemovedUserFontFontBaselineShiftFromDbCache);
  yield takeLatest(UPDATE_BITMAP_FONTS, updateBitmapFonts);
}

export default fontSagas;
