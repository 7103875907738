import { WaveformImageData } from '../worker/WaveformRenderer.worker';

/* 
  Maximum texture size varies by device
  1024*1024 or 2048*2048 are commonly used
  1024 would be a more conservative alternative
*/
const DEFAULT_TEXTURE_SIZE = 2048;

export const getMaxTextureSize = (gl: WebGLRenderingContext): number => {
  const maxTextureSize = gl?.getParameter(gl.MAX_TEXTURE_SIZE) || DEFAULT_TEXTURE_SIZE;
  return maxTextureSize;
};

type RenderOptionType = {
  width?: number;
  height?: number;
  fill?: string;
  start?: number;
  end?: number;
};

/*
  The original concept is from @pixi/sound package: https://github.com/Sparkol/Sound
*/
export const render = async function(
  audioData: Float32Array,
  { width = 512, height = 128, fill = 'black', start = 0, end = audioData.length }: RenderOptionType,
  isUseOffscreenCanvas = false
) {
  if (start < 0 || start > width) start = 0;
  if (start > width) start = width;
  if (end === 0 || end > width) end = width;

  const canvas = generateCanvas(Math.floor(end - start), height, isUseOffscreenCanvas);

  const context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D | null = canvas.getContext('2d');
  if (!context) return null;

  context.imageSmoothingEnabled = false;
  context.fillStyle = fill;
  const step: number = Math.ceil(audioData.length / width);
  const amp: number = height;
  context.fillStyle = '#FFFFFF';
  context.moveTo(start, height);
  for (let i = 0; i < width; i++) {
    let min = 0;
    let max = 0;

    for (let j = 0; j < step; j += 10) {
      const datum: number = audioData[i * step + j];

      if (datum < min) {
        min = datum;
      }
      if (datum > max) {
        max = datum;
      }
    }
    context.fillRect(i - start, height, 1, -max * amp);
  }
  const url = await canvasToUrl(canvas);
  canvas.getContext('2d')?.clearRect(0, 0, canvas.width, canvas.height);
  canvas.width = 0;
  canvas.height = 0;

  return url;
};

const isHTMLCanvasElement = (canvas: HTMLCanvasElement | OffscreenCanvas): canvas is HTMLCanvasElement => {
  return typeof HTMLCanvasElement !== 'undefined' && canvas instanceof HTMLCanvasElement;
};

const canvasToUrl = async (canvas: HTMLCanvasElement | OffscreenCanvas): Promise<string | null> => {
  let blob: Blob | null;
  if (isHTMLCanvasElement(canvas)) {
    blob = await canvasToBlob(canvas);
  } else {
    blob = await canvas.convertToBlob();
  }
  if (!blob) return null;
  return URL.createObjectURL(blob);
};

const canvasToBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error('Blob creation failed'));
      }
    });
  });
};

export const generateCanvas = (
  width: number,
  height: number,
  isUseOffscreenCanvas = false
): HTMLCanvasElement | OffscreenCanvas => {
  if (isUseOffscreenCanvas) {
    return new OffscreenCanvas(width, height);
  } else {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
  }
};

export const isOffscreenCanvasSupported = () => {
  const isSupported = typeof window !== 'undefined' && 'OffscreenCanvas' in window;
  return isSupported;
};

export type RenderWaveformImagesParams = {
  audioData: Float32Array;
  renderPieces?: number;
  pieceWidth?: number;
  canvasWidth: number;
  canvasHeight: number;
  fill: string;
  useOffscreenCanvas?: boolean;
};

export const renderWaveformImages = async ({
  audioData,
  renderPieces = 1,
  pieceWidth,
  canvasWidth,
  canvasHeight,
  fill,
  useOffscreenCanvas = false
}: RenderWaveformImagesParams): Promise<WaveformImageData[] | void> => {
  const waveformImages: WaveformImageData[] = [];
  for (let i = 0; i < renderPieces; i++) {
    const imageUrl = await render(
      audioData,
      {
        width: canvasWidth,
        height: canvasHeight,
        start: i * (pieceWidth || canvasWidth),
        end: (i + 1) * (pieceWidth || canvasWidth),
        fill
      },
      useOffscreenCanvas
    );
    if (!imageUrl) return;
    const imageStartPoint = i * (pieceWidth || canvasWidth);
    waveformImages.push({ imageUrl, imageStartPoint });
  }
  return waveformImages;
};
