import { fabric } from 'fabric';
import { DEFAULT_SHAPE_COLOR } from 'js/config/defaults';
import ScribeShapeElementModel from 'js/models/ScribeShapeElementModel';
import { BackgroundGradientType, BackgroundType, ElementType } from 'js/types';

import getLockedProperties from './helpers/getLockedProperties';
import getVisibleProperties from './helpers/getVisibleProperties';
import sanitizeAngle from './helpers/sanitizeAngle';
import getGradient from './helpers/getGradient';
import applyShapeElementOptions from './helpers/applyShapeElementOptions';
import { getPositionalProperties } from './helpers/getPositionalProperties';
import { getStyleProperties } from './helpers/getStyleProperties';
import { applyBoundingBoxStyles, setGroupTransform } from './helpers/canvasElementHelpers';
import { getElementScales } from './helpers/getElementScales';

export interface IEllipseElement extends fabric.Ellipse {
  id: string;
  locked: boolean;
  hidden: boolean;
  shapeType: 'ellipse';
  backgroundType: BackgroundType;
  backgroundColorFrom: string;
  backgroundColorTo: string;
  backgroundGradientType: BackgroundGradientType;
  elementType: ElementType;
  radius?: number;
  rx?: number;
  ry?: number;
  unlockedRatio: boolean;
  element: ScribeShapeElementModel;
}

export default class EllipseElement extends fabric.Ellipse implements IEllipseElement {
  id: string;
  locked: boolean;
  hidden: boolean;
  shapeType = 'ellipse' as const;
  backgroundType: BackgroundType;
  backgroundColorFrom: string;
  backgroundColorTo: string;
  backgroundGradientType: BackgroundGradientType;
  elementType: ElementType;
  radius?: number;
  rx?: number;
  ry?: number;
  unlockedRatio: boolean;
  element: ScribeShapeElementModel;

  constructor(props: ScribeShapeElementModel) {
    const fill =
      props.backgroundType === 'gradient'
        ? getGradient({
            type: props.backgroundGradientType,
            width: props.width,
            height: props.height,
            from: props.backgroundColorFrom,
            to: props.backgroundColorTo
          })
        : props.fill ?? DEFAULT_SHAPE_COLOR;

    super({
      ...getStyleProperties(props),
      ...getPositionalProperties(props),
      ...getLockedProperties(props.locked || false),
      ...getVisibleProperties(props.locked, props.hidden),
      fill
    });

    this.id = props.id;
    this.locked = props.locked;
    this.hidden = props.hidden;
    this.backgroundType = props.backgroundType;
    this.backgroundColorFrom = props.backgroundColorFrom;
    this.backgroundColorTo = props.backgroundColorTo;
    this.backgroundGradientType = props.backgroundGradientType;
    this.elementType = 'Shape';
    this.radius = props.radius;
    this.rx = props.rx;
    this.ry = props.ry;
    this.unlockedRatio = props.unlockedRatio;
    this.element = props;

    this.setBoundingBoxStyles(props);
  }

  // having bounding box styles per class as this will allow us to style them individually should we wish
  private setBoundingBoxStyles(props: ScribeShapeElementModel) {
    applyBoundingBoxStyles(this, props);
  }

  public updateProps(props: ScribeShapeElementModel) {
    this.locked = props.locked;
    this.hidden = props.hidden;
    this.unlockedRatio = props.unlockedRatio;

    const update = this.group
      ? {
          ...getStyleProperties(props),
          ...getLockedProperties(props.locked || false),
          ...getVisibleProperties(props.locked, props.hidden)
        }
      : {
          ...getStyleProperties(props),
          ...getPositionalProperties(props),
          ...getLockedProperties(props.locked || false),
          ...getVisibleProperties(props.locked, props.hidden)
        };

    const updatedShapeElement = applyShapeElementOptions(update, props, this);

    this.element = props;

    this.set(updatedShapeElement);
    this.setBoundingBoxStyles(props);
  }

  public toVscElement(): ScribeShapeElementModel {
    const originalGroupValues = setGroupTransform(this);

    const fill = typeof this.fill === 'string' ? this.fill : DEFAULT_SHAPE_COLOR;

    const { newScaleX, newScaleY } = getElementScales(this.scaleX, this.scaleY, this.width, this.height);

    this.scaleX = newScaleX;
    this.scaleY = newScaleY;

    const payload = new ScribeShapeElementModel({
      ...this.element,
      scaleX: newScaleX,
      scaleY: newScaleY,
      y: this.top ?? 0,
      x: this.left ?? 0,
      opacity: this.opacity,
      angle: sanitizeAngle(this.angle),
      width: this.width ?? 150,
      height: this.height ?? 150,
      flipX: !!this.flipX,
      flipY: !!this.flipY,
      originX: this.originX ?? 'left',
      originY: this.originY ?? 'top',
      locked: this.locked,
      hidden: this.hidden,
      unlockedRatio: this.unlockedRatio,
      fill,
      backgroundType: this.backgroundType,
      backgroundColorFrom: this.backgroundColorFrom,
      backgroundColorTo: this.backgroundColorTo,
      backgroundGradientType: this.backgroundGradientType,
      radius: this.radius ?? 75,
      rx: this.rx ?? 100,
      ry: this.ry ?? 50
    });

    if (originalGroupValues) {
      this.set(originalGroupValues);
    }

    return payload;
  }

  destroy() {
    this.canvas?.remove(this);
  }
}
