import { mapSceneElementIdsToElements } from 'js/shared/helpers/scenesHelpers';
import { VSElementModel, ScribeScene, PlaybackScribeModel, AnimationTimingOptions } from 'js/types';

import { getElementTotalTimesLengthMs } from './timings';

export function getElementTimings(
  elements: Array<VSElementModel>,
  target: VSElementModel,
  project: PlaybackScribeModel,
  scene: ScribeScene,
  sceneStartTime: number,
  initialCameraTimings?: AnimationTimingOptions
) {
  const elementIndex = elements.findIndex(el => el === target);
  if (elementIndex === -1) {
    return {
      startTimeMs: 0,
      endTimeMs: 0
    };
  }

  const elementStartTimeMs = mapSceneElementIdsToElements(project, scene)
    .slice(0, elementIndex)
    .reduce((prevTime, e) => {
      return prevTime + getElementTotalTimesLengthMs(e, project);
    }, sceneStartTime + (scene.settings.sceneTransitionTime ?? 0) * 1000);

  const elementEndTimeMs = elementStartTimeMs + getElementTotalTimesLengthMs(target, project);

  if (
    initialCameraTimings &&
    target.type !== 'Camera' &&
    elementStartTimeMs === initialCameraTimings.endTimeMs &&
    elementStartTimeMs - elementEndTimeMs === 0
  ) {
    return {
      startTimeMs: initialCameraTimings.startTimeMs,
      endTimeMs: elementEndTimeMs
    };
  } else {
    return {
      startTimeMs: elementStartTimeMs,
      endTimeMs: elementEndTimeMs
    };
  }
}
