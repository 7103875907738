import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import { take, put, call, select, takeLatest, fork } from 'redux-saga/effects';
import { sendErrorToSentry } from 'js/logging';
import { push } from 'connected-react-router';
import { ROUTE_CODES } from 'js/config/routes';
import ScribeModel from 'js/models/ScribeModel';
import config, { CONTENTFUL_LOCALE } from 'js/config/config';
import { updateProject } from 'js/shared/helpers/app-services/AppServices/projects';

import {
  CREATE_SCRIBE_FROM_TEMPLATE,
  createScribeFromTemplateSuccess,
  GET_SCRIBE_TEMPLATES,
  getScribeTemplatesFailed,
  getScribeTemplatesByCategorySuccess,
  SAVE_SCRIBE_AS_TEMPLATE,
  saveScribeAsTemplateSuccess,
  saveScribeAsTemplateFailed,
  CONTENTFUL_OAUTH_SUCCESS,
  contentfulOauthInvalid,
  contentfulOauthLoaded,
  createScribeFromTemplateFailed
} from '../actionCreators/templatesActions';
import { showCallout, showError } from '../actionCreators/uiActions';
import { getTemplatesByCategory } from '../shared/providers/TemplatesContentProvider';
import { getContentfulEnvironment, saveTemplate } from '../shared/providers/TemplatesManagementProvider';
import { appServices } from '../shared/helpers/app-services/AppServices';
import * as tokenHelper from '../shared/helpers/ContentfulHelper';
import { PIN_ALL_CAMERA_BY_DEFAULT } from '../config/config';

import sanitizeScribeForTransport from './sagaHelpers/sanitizeScribeForTransport';
import { getScribeById, getContentfulManagementToken } from './selectors';

const { CONTENTFUL_SPACE_ID, CONTENTFUL_ENVIRONMENT } = config;

function* getScribeTemplatesByCategory() {
  try {
    const { error, categories } = yield call(getTemplatesByCategory);
    if (!error) {
      yield put(getScribeTemplatesByCategorySuccess(categories));
    }
  } catch (error) {
    sendErrorToSentry(error);
    yield put(showError({ message: 'Sorry, there was a problem loading templates' }));
    yield put(getScribeTemplatesFailed(error));
  }
}

async function getTemplateThumbnailBuffer(template) {
  const response = await window.fetch(template.thumbnailImage);
  const buffer = await response.arrayBuffer();
  return buffer;
}

function* saveScribeAsTemplate(action) {
  const scribe = yield select(getScribeById, action.scribeId);
  const template = cloneDeep(scribe);
  try {
    const thumbnailBuffer = yield call(getTemplateThumbnailBuffer, template);
    const entry = yield call(saveTemplate, action.scribeId, template, thumbnailBuffer);

    yield put(saveScribeAsTemplateSuccess());

    const templateContentHref = `https://app.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/${CONTENTFUL_ENVIRONMENT}/entries/${entry.sys.id}`;

    yield put(
      showCallout({
        title: 'Project saved as a template',
        details: (
          <>
            <span>Contentful Template ID: </span>
            <a href={templateContentHref} target="_blank" rel="noopenner noreferrer">
              {entry.sys.id}
            </a>
            <span> Template Project ID: {entry.fields.sparkolTemplateId[CONTENTFUL_LOCALE]}</span>
          </>
        )
      })
    );
  } catch (error) {
    console.error(error);
    let description = error.message;
    yield put(saveScribeAsTemplateFailed(error));
    if (error.name === 'AccessTokenInvalid') {
      yield put(contentfulOauthInvalid());
      description = 'Access Token Invalid. Try Reauthorizing with Contentful.';
    }
    yield put(
      showError({
        message: 'Could not save project as a template',
        description
      })
    );
  }
}

function* createProjectFromServicesTemplate(servicesTemplateId, templateData) {
  const project = yield call(appServices.createProjectFromTemplate, servicesTemplateId);

  const newScribe = yield call(ScribeModel.fromObject, project, { pinAllCameras: PIN_ALL_CAMERA_BY_DEFAULT });

  if (!newScribe.source || newScribe.source.type !== 'template') {
    newScribe.source = {
      type: 'template',
      meta: {
        templateId: templateData.id,
        title: templateData.title
      }
    };
  }

  const sanitizedScribe = sanitizeScribeForTransport(newScribe);
  yield call(updateProject, sanitizedScribe, appServices.exchangeCognitoTokenForSparkolToken);

  yield put(createScribeFromTemplateSuccess(newScribe));

  yield put(push(ROUTE_CODES.EDITOR.replace(':scribeId', project.projectDataId)));
}

/**
 * This creates a blank Scribe without any element in it.
 * Needed as we have to create the Scribe first in the DB
 * before cloning the `template` elements.
 */
function* createScribeFromTemplate({ templateId }) {
  const stateTemplateData = yield select(state => {
    return state.templates.templatesByCategory
      .flatMap(category => category.templates)
      .find(template => template.id === templateId);
  });

  try {
    yield call(createProjectFromServicesTemplate, stateTemplateData.sparkolTemplateId, stateTemplateData);
  } catch (error) {
    yield put(createScribeFromTemplateFailed());
    sendErrorToSentry(error);
  }
}

function* contentfulOauthSuccess(action) {
  const accessToken = action.payload;
  yield call(getContentfulEnvironment, accessToken);
  yield call(tokenHelper.setToken, accessToken);
}

function* initialSaga() {
  let accessToken = yield select(getContentfulManagementToken);
  if (!accessToken) {
    accessToken = yield call(tokenHelper.getToken);
    if (accessToken) {
      yield put(contentfulOauthLoaded(accessToken));
    }
  }
  if (accessToken) {
    try {
      yield call(getContentfulEnvironment, accessToken);
    } catch (error) {
      console.error(error);
      if (error.name === 'AccessTokenInvalid') {
        yield put(contentfulOauthInvalid());
        yield call(tokenHelper.removeToken);
      }
    }
  }
}

// `takeLeading` is available in newer version of redux-saga but this is the low-level effects
// which are the same - as taken from https://redux-saga.js.org/docs/api/#:~:text=fetchUser)%0A%7D-,Notes,-takeLeading
const takeLeading = (patternOrChannel, saga, ...args) =>
  fork(function*() {
    while (true) {
      const action = yield take(patternOrChannel);
      yield call(saga, ...args.concat(action));
    }
  });

export default function* templatesSaga() {
  yield fork(initialSaga);
  yield takeLeading(CREATE_SCRIBE_FROM_TEMPLATE, createScribeFromTemplate);
  yield takeLatest(GET_SCRIBE_TEMPLATES, getScribeTemplatesByCategory);
  yield takeLatest(SAVE_SCRIBE_AS_TEMPLATE, saveScribeAsTemplate);
  yield takeLatest(CONTENTFUL_OAUTH_SUCCESS, contentfulOauthSuccess);
}
