import { FILE_CONTENT_TYPES } from 'js/config/consts';
import { MASK_LINE_COLOR } from 'js/config/defaults';
import { MAX_CANVAS_MULTIPLIER } from 'js/editor/EditorCanvas/EditorCanvas';
import { isImageElement } from 'js/editor/EditorCanvas/helpers/canvasElementHelpers';
import { EXPANDED_CANVAS_SIZE } from 'js/editor/EditorCanvas/ProjectCanvas';
import { initializeSceneViewport } from 'js/shared/helpers/initializeSceneViewport';
import { constructSVGAssetKey } from 'js/shared/lib/LocalDatabase/keys';
import {
  AnimationTimingOptions,
  PlaybackImageResources,
  PlaybackScribeModel,
  SceneTransitionClass,
  ScribeCameraElement,
  ScribeScene,
  Viewport,
  VSAnimationElement,
  VSElementModel
} from 'js/types';
import clamp from 'lodash.clamp';
import * as PIXI from 'pixi.js';

import { mapSceneElementIdsToElements } from '../../../../shared/helpers/scenesHelpers';
import { getElementTimings as getElementTimingsHelper } from '../helpers/getElementTimings';
import getScribeLengthMs from '../helpers/getScribeLengthMs';
import setupCursor from '../helpers/setupCursor';
import {
  getElementEmphasisLoops,
  getElementEmphasisTimeMs,
  getElementEntranceAnimationTimeMs,
  getElementExitAnimationTimeMs,
  getElementPauseTimeMs
} from '../helpers/timings';

import VSRasterImage from './VSRasterImage';
import TransitionClassFromKey from './VSSceneTransitions/VSTransitionLookup';
import VSShapeElement from './VSShapeElement';
import VSTextElement from './VSTextElement';
import VSVectorImage from './VSVectorImage';
import VSViewport from './VSViewport';

interface SceneOptions {
  scene: ScribeScene;
  scribe: PlaybackScribeModel;
  viewportDimensions: Viewport;
  maxStageDimensions: PIXI.Rectangle;
  scribeTotalLengthMs: number;
  renderer: PIXI.Renderer;
  timings: AnimationTimingOptions;
  playerRef: PIXI.Application;
  prevScene: VSScene | null;
  nextSceneTransitionTimeMs: number;
  imageResources: PlaybackImageResources;
  showWatermarksOnPremiumContent: boolean;
}

export default class VSScene {
  cursor: PIXI.Container;
  sceneModel: ScribeScene;
  scribeModel: PlaybackScribeModel;
  viewportDimensions: Viewport;
  scribeTotalLengthMs: number;
  imageResources: PlaybackImageResources;
  renderer: PIXI.Renderer;
  timings: AnimationTimingOptions;
  playerRef: PIXI.Application;
  prevScene: VSScene | null;
  nextSceneTransitionTimeMs: number;
  sceneContainer: PIXI.Container;
  sceneElements!: Array<VSAnimationElement>;
  transitionTween?: SceneTransitionClass;
  currentTime?: number;
  viewport: VSViewport;
  viewportContainer: PIXI.Container;
  maxStageDimensions: PIXI.Rectangle;
  showWatermarksOnPremiumContent: boolean;

  constructor({
    scene,
    scribe,
    viewportDimensions,
    maxStageDimensions,
    scribeTotalLengthMs,
    renderer,
    timings,
    playerRef,
    prevScene,
    imageResources,
    nextSceneTransitionTimeMs,
    showWatermarksOnPremiumContent
  }: SceneOptions) {
    this.sceneModel = scene;
    this.scribeModel = scribe;
    this.viewportDimensions = viewportDimensions;
    this.maxStageDimensions = maxStageDimensions;
    this.scribeTotalLengthMs = scribeTotalLengthMs;
    this.imageResources = imageResources;
    this.renderer = renderer;
    this.timings = timings;
    this.playerRef = playerRef;
    this.prevScene = prevScene;
    this.nextSceneTransitionTimeMs = nextSceneTransitionTimeMs;
    this.sceneContainer = this.createSceneContainer();
    this.cursor = setupCursor();
    this.showWatermarksOnPremiumContent = showWatermarksOnPremiumContent;

    const viewportBoundsMask = new PIXI.Graphics();
    viewportBoundsMask.beginTextureFill({
      color: MASK_LINE_COLOR
    });
    viewportBoundsMask.drawRect(0, 0, viewportDimensions.width, viewportDimensions.height);
    viewportBoundsMask.endFill();
    viewportBoundsMask.name = 'Viewport bounds mask';

    this.viewportContainer = new PIXI.Container();
    this.viewportContainer.name = 'Viewport Container ' + this.sceneModel.id;
    this.viewportContainer.mask = viewportBoundsMask;
    this.viewportContainer.addChild(viewportBoundsMask);

    const startingCameraId = this.sceneModel.elementIds[0];
    const startingCamera = this.scribeModel.elements.find(el => el.id === startingCameraId);
    if (!startingCamera || startingCamera.type !== 'Camera') throw new Error('No starting camera data found in scene');

    startingCamera.animationTime = 0;

    this.viewport = initializeSceneViewport(scribe, scene, startingCamera);
    this.viewport.name = 'Viewport ' + this.sceneModel.id;
    this.viewport.addChild(this.sceneContainer);
    this.viewportContainer.addChild(this.viewport);

    // Create mask for scene so nothing outside of it can be seen
  }

  async initialiseTimelines() {
    this.sceneElements = await this.createSceneElements();
    // initialise element timeline
    this.sceneElements.forEach(sceneElement => sceneElement.initialiseTimeline());

    // We must initialise the scene transitions AFTER the scene elements timelines so that
    // offScreen positions can ba calcualtes correctly in the element timeline initialisation phase
    if (!!this.sceneModel.settings.sceneTransitionType && !!this.sceneModel.settings.sceneTransitionTime) {
      const transitionClass = TransitionClassFromKey(this.sceneModel.settings.sceneTransitionType);
      if (transitionClass) {
        this.transitionTween = new transitionClass({
          previousScene: this.prevScene,
          currentScene: this,
          transitionDuration: this.sceneModel.settings.sceneTransitionTime,
          viewportDimensions: this.viewportDimensions,
          config: this.sceneModel.settings.sceneTransitionConfig
        });
      }
    }
  }

  getBackground = () => {
    const { backgroundColor } = this.sceneModel.settings;

    const padding = 100;
    const width = EXPANDED_CANVAS_SIZE.width + (EXPANDED_CANVAS_SIZE.width + padding) * MAX_CANVAS_MULTIPLIER * 2;
    const height = EXPANDED_CANVAS_SIZE.height + (EXPANDED_CANVAS_SIZE.height + padding) * MAX_CANVAS_MULTIPLIER * 2;

    const fillOpt = { color: PIXI.utils.string2hex(backgroundColor) };
    const solid = new PIXI.Graphics().beginTextureFill(fillOpt).drawRect(0, 0, 100, 100);

    solid.width = width;
    solid.height = height;

    solid.x = -(EXPANDED_CANVAS_SIZE.width + padding) * MAX_CANVAS_MULTIPLIER;
    solid.y = -(EXPANDED_CANVAS_SIZE.height + padding) * MAX_CANVAS_MULTIPLIER;

    solid.name = 'Background';
    return solid;
  };

  createSceneContainer() {
    const sceneContainer = new PIXI.Container();
    sceneContainer.x = 0;
    sceneContainer.y = 0;
    sceneContainer.visible = false;
    sceneContainer.name = 'Scene ' + this.sceneModel.id;

    return sceneContainer;
  }

  getElementTimings(
    elements: Array<VSElementModel>,
    target: VSElementModel,
    initialCameraTimings?: AnimationTimingOptions
  ) {
    return getElementTimingsHelper(
      elements,
      target,
      this.scribeModel,
      this.sceneModel,
      this.timings.startTimeMs,
      initialCameraTimings
    );
  }

  /***
   * If the element has 0 animation time (end - start === 0)
   * and the start time is the same as the initial camera end time, then we adjust the
   * element start time to match the starting camera time so it is displayed in that first camera position
   * during scene transitions and pauses on that first camera.
   */
  adjustStartingElementTimings(
    element: VSElementModel,
    elementTimings: AnimationTimingOptions,
    initialCameraTimings?: AnimationTimingOptions
  ): AnimationTimingOptions {
    if (!initialCameraTimings) return elementTimings;

    const { startTimeMs: elementStartTimeMs, endTimeMs: elementEndTimeMs } = elementTimings;

    if (
      element.type !== 'Camera' &&
      elementStartTimeMs === initialCameraTimings?.endTimeMs &&
      elementStartTimeMs - elementEndTimeMs === 0
    ) {
      return {
        startTimeMs: initialCameraTimings.startTimeMs,
        endTimeMs: elementEndTimeMs
      };
    } else {
      return elementTimings;
    }
  }

  async createSceneElements() {
    const mappedElements = mapSceneElementIdsToElements(this.scribeModel, this.sceneModel);
    const sceneElements: Array<VSAnimationElement> = [];
    const cameras = mappedElements.filter((element): element is ScribeCameraElement => element.type === 'Camera');

    // initialize cameras first
    let initialCameraTimings;
    for (const camera of cameras) {
      const { startTimeMs, endTimeMs } = this.getElementTimings(mappedElements, camera);
      if (!initialCameraTimings) {
        initialCameraTimings = { startTimeMs, endTimeMs };
      }

      this.viewport.initializeCamera({
        camera: camera,
        startTimeMs,
        endTimeMs
      });
    }

    // initialize elements
    for (const sceneElement of mappedElements) {
      let stageInstance;

      const { startTimeMs: elementStartTimeMs, endTimeMs: elementEndTimeMs } = this.getElementTimings(
        mappedElements,
        sceneElement,
        initialCameraTimings
      );

      const baseOptions = {
        element: sceneElement,
        animationTime: getElementEntranceAnimationTimeMs(sceneElement, this.scribeModel),
        emphasisAnimationTime: getElementEmphasisTimeMs(sceneElement),
        emphasisAnimationLoops: getElementEmphasisLoops(sceneElement),
        exitAnimationTime: getElementExitAnimationTimeMs(sceneElement, this.scribeModel),
        pauseTime: getElementPauseTimeMs(sceneElement, this.scribeModel),
        playerRef: this.playerRef,
        timings: {
          startTimeMs: elementStartTimeMs,
          endTimeMs: elementEndTimeMs,
          scribeTotalLengthMs: getScribeLengthMs(this.scribeModel)
        },
        scene: this,
        showWatermarksOnPremiumContent: this.showWatermarksOnPremiumContent
      };

      if (sceneElement.type === 'Text') {
        stageInstance = await VSTextElement.build({
          ...baseOptions,
          element: sceneElement
        });
      }

      if (isImageElement(sceneElement) && sceneElement._imageUrl) {
        if (sceneElement.image?.contentType === FILE_CONTENT_TYPES.SVG) {
          const imageResource = this.imageResources.svgResources[
            constructSVGAssetKey({
              assetId: sceneElement.image.assetId,
              recolorSettings: sceneElement.image.recolor,
              useContentSvgViewbox: !!sceneElement.useContentSvgViewbox
            })
          ];

          stageInstance = await VSVectorImage.create({
            ...baseOptions,
            element: sceneElement,
            imageResource
          });
        } else if (sceneElement.image?.contentType === FILE_CONTENT_TYPES.GIF) {
          stageInstance = new VSRasterImage({
            ...baseOptions,
            imageResource: this.imageResources.gifResources[sceneElement.image.assetId],
            isGif: true
          });
        } else {
          stageInstance = new VSRasterImage({
            ...baseOptions,
            imageResource: this.imageResources.rasterResources[sceneElement._imageUrl]
          });
        }
      }

      if (sceneElement.type === 'Shape') {
        stageInstance = new VSShapeElement({
          ...baseOptions
        });
      }

      if (stageInstance) {
        sceneElements.push(stageInstance);
      }
    }

    this.sceneContainer.addChild(this.getBackground());
    if (sceneElements.length > 0) {
      const children: Array<PIXI.Container> = [];
      sceneElements.forEach(e => {
        if (e.stageElement) children.push(e.stageElement);
      });
      this.sceneContainer.addChild(...children);
    }
    return sceneElements;
  }

  update = (currentTime: number) => {
    if (currentTime === this.currentTime) return;
    this.currentTime = currentTime;
    this.viewport.update(currentTime);

    if (
      currentTime < this.timings.startTimeMs ||
      currentTime > this.timings.endTimeMs + this.nextSceneTransitionTimeMs
    ) {
      this.sceneContainer.visible = false;
    } else {
      this.sceneContainer.visible = true;
    }

    if (this.transitionTween && this.sceneModel.settings.sceneTransitionTime) {
      const prog = clamp(
        (currentTime - this.timings.startTimeMs) / (this.sceneModel.settings.sceneTransitionTime * 1000),
        0,
        1
      );
      this.transitionTween.update(prog);
    }

    this.sceneElements.forEach(sceneElement => {
      sceneElement.update(
        currentTime,
        this.transitionTween && this.sceneModel.settings.sceneTransitionTime
          ? this.sceneModel.settings.sceneTransitionTime * 1000
          : 0,
        this.timings.startTimeMs
      );
    });
  };

  destroy() {
    this.sceneElements?.forEach(element => {
      element?.destroy();
    });
  }
}
