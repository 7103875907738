import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import EditPanelHeading from 'js/editor/EditPanelHeading';
import EditPanelNumberInput from 'js/editor/EditPanelNumberInput';
import EditPanelForm from 'js/editor/EditPanelForm';
import EditPanelFieldset from 'js/editor/EditPanelFieldset';
import IconIndeterminate from 'js/shared/icons/IconIndeterminate';
import { NO_ANIMATION_KEY, REVERSE_ICON_LIST, EMPHASIS_STAGE_KEY, HAND_DRAW_KEY } from 'js/config/consts';
import { animationTypeHasNoDuration } from 'js/shared/helpers/animationTypeHasNoDuration';
import {
  AllAnimationTypeKeys,
  AnimationTypeOption,
  ElementAnimationStage,
  ElementChoiceButtonProps,
  ElementMenuProps,
  ExistingScribeModel,
  VSImageShapeOrTextElementModel
} from 'js/types';

import './AnimationStagePanel.css';

import ScribelyIconButton from '../ScribelyIconButton';

import AnimationOptionMenu from './AnimationOptionMenu';
import AnimationTypeSelectorButtons, {
  AnimationTypeSelectorButtonGroup,
  AnimationTypeSelectorButtonGroupText
} from './AnimationTypeSelectorButtons';

export const AnimationStagePanelOptionsGrid = ({ children }: { children: React.ReactNode }) => (
  <div className="AnimationStagePanel__optionsGrid">{children}</div>
);

type MenuConfig = {
  anchor: Element;
  primary: boolean;
  secondary: boolean;
  tertiary: boolean;
};

const AnimationStagePanel = ({
  title,
  duration = '-',
  onDurationChange,
  loops = '-',
  onLoopsChange,
  showLoops,
  animationTypeOptions,
  animationType,
  onAnimationTypeChange,
  element,
  elements,
  scribe,
  stageKey,
  showDuration
}: {
  title: string;
  duration: number | string;
  onDurationChange: ({
    value,
    eventTrigger,
    incrementValue,
    decrementValue
  }: {
    value: number | string;
    eventTrigger?: string;
    incrementValue?: boolean;
    decrementValue?: boolean;
  }) => void;
  loops: number | string;
  onLoopsChange: ({
    value,
    eventTrigger,
    incrementValue,
    decrementValue
  }: {
    value: number | string;
    eventTrigger?: string;
    incrementValue?: boolean;
    decrementValue?: boolean;
  }) => void;
  showLoops: boolean;
  animationTypeOptions: Array<AnimationTypeOption<ElementChoiceButtonProps, ElementMenuProps>>;
  animationType: AllAnimationTypeKeys;
  onAnimationTypeChange: ({
    animationType,
    stageKey
  }: {
    animationType: AllAnimationTypeKeys;
    stageKey: ElementAnimationStage;
  }) => void;
  element: VSImageShapeOrTextElementModel;
  elements: Array<VSImageShapeOrTextElementModel>;
  scribe: ExistingScribeModel;
  stageKey: ElementAnimationStage;
  showDuration: boolean;
}) => {
  const [menuOpen, setMenuOpen] = useState<MenuConfig | null>(null);
  const allElementsHaveDrawStyleAsSecondaryOptions =
    elements?.length > 0 &&
    elements.every(element => {
      const elementSecondaryMenu = animationTypeOptions.find(
        opt => opt.type === (element.entranceAnimation?.id ?? HAND_DRAW_KEY)
      )?.secondaryMenu;
      return elementSecondaryMenu?.title === 'Draw style';
    });

  const firstElementWithAnimation = elements?.find(element => element.entranceAnimation?.id !== undefined);
  const animationId = firstElementWithAnimation?.entranceAnimation?.id;

  const defaultSecondaryMenu =
    (allElementsHaveDrawStyleAsSecondaryOptions &&
      animationId &&
      animationTypeOptions.find(opt => opt.type === animationId)?.secondaryMenu) ||
    undefined;

  const { title: animationTypeTitle, Icon, secondaryMenu, tertiaryMenu } = animationTypeOptions.find(
    opt => opt.type === animationType
  ) ?? {
    title: 'Mixed',
    Icon: () => <IconIndeterminate />,
    // Used for mixed animation types where secondary menu is available and is for 'Draw style'
    ...(defaultSecondaryMenu && { secondaryMenu: defaultSecondaryMenu }),
    tertiaryMenu: undefined
  };

  const SecondaryChoiceButton = secondaryMenu?.ChoiceButton;
  const TertiaryChoiceButton = tertiaryMenu?.ChoiceButton;

  const handleButtonClick = (menuOpenconfig: MenuConfig) => {
    setMenuOpen(menuOpenconfig);
  };

  const handleClose = () => {
    setMenuOpen(null);
  };

  const handleSecondarySelection = () => {
    setMenuOpen(null);
  };
  const handleTertiarySelection = () => {
    setMenuOpen(null);
  };
  const handleMenuItemClick = (option: AnimationTypeOption<ElementChoiceButtonProps, ElementMenuProps>) => {
    onAnimationTypeChange({ animationType: option.type, stageKey });
    handleClose();
  };

  const reverseIcons = REVERSE_ICON_LIST.includes(animationType);

  return (
    <div className="AnimationStagePanel">
      <EditPanelHeading>{title}</EditPanelHeading>
      <EditPanelForm>
        <EditPanelFieldset>
          <div className="AnimationStagePanel__fieldset">
            <AnimationTypeSelectorButtons>
              <div>
                <AnimationTypeSelectorButtonGroup
                  onClick={e =>
                    handleButtonClick({ anchor: e.currentTarget, secondary: false, primary: true, tertiary: false })
                  }
                  label="Type"
                  id={`Type-AnimationTypeSelectorButtonGroup-${stageKey}`}
                >
                  <Icon flipImage={reverseIcons} />
                  <AnimationTypeSelectorButtonGroupText>{animationTypeTitle}</AnimationTypeSelectorButtonGroupText>
                </AnimationTypeSelectorButtonGroup>

                <div className="AnimationStagePanel__subset">
                  {((animationType !== NO_ANIMATION_KEY &&
                    !animationTypeHasNoDuration(animationType) &&
                    showDuration) ||
                    stageKey === EMPHASIS_STAGE_KEY) && (
                    <EditPanelNumberInput
                      id={`${title.toLowerCase().replace(' ', '-')}-time`}
                      value={duration}
                      onChange={onDurationChange}
                      units="s"
                      label="Duration:"
                    />
                  )}
                  {!SecondaryChoiceButton && animationType !== NO_ANIMATION_KEY && showLoops && (
                    <EditPanelNumberInput
                      id={`${title.toLowerCase().replace(' ', '-')}-loops`}
                      value={loops}
                      onChange={onLoopsChange}
                      units="x"
                      label="Loops:"
                      min={1}
                      step={1}
                    />
                  )}
                </div>
              </div>

              <div>
                {SecondaryChoiceButton && (
                  <AnimationTypeSelectorButtonGroup
                    onClick={e =>
                      handleButtonClick({ anchor: e.currentTarget, tertiary: false, secondary: true, primary: false })
                    }
                    label={secondaryMenu.choiceButtonLabel}
                    id={`${secondaryMenu.choiceButtonLabel}-AnimationTypeSelectorButtonGroup-${stageKey}`}
                  >
                    <SecondaryChoiceButton
                      element={element}
                      elements={elements}
                      scribe={scribe}
                      stageKey={stageKey}
                      flipImage={reverseIcons}
                    />
                  </AnimationTypeSelectorButtonGroup>
                )}
                <div className="AnimationStagePanel__subset">
                  {SecondaryChoiceButton && animationType !== NO_ANIMATION_KEY && showLoops && (
                    <EditPanelNumberInput
                      id={`${title.toLowerCase().replace(' ', '-')}-loops`}
                      value={loops}
                      onChange={onLoopsChange}
                      units="x"
                      label="Loops:"
                      min={1}
                      step={1}
                    />
                  )}
                </div>
              </div>

              <div>
                {TertiaryChoiceButton && (
                  <AnimationTypeSelectorButtonGroup
                    onClick={e =>
                      handleButtonClick({ anchor: e.currentTarget, tertiary: true, secondary: false, primary: false })
                    }
                    label={tertiaryMenu.choiceButtonLabel}
                    id={`${tertiaryMenu.choiceButtonLabel}-AnimationTypeSelectorButtonGroup-${stageKey}`}
                  >
                    <TertiaryChoiceButton
                      element={element}
                      elements={elements}
                      scribe={scribe}
                      stageKey={stageKey}
                      flipImage={reverseIcons}
                    />
                  </AnimationTypeSelectorButtonGroup>
                )}
                <div className="AnimationStagePanel__subset">
                  {TertiaryChoiceButton && animationType !== NO_ANIMATION_KEY && showLoops && (
                    <EditPanelNumberInput
                      id={`${title.toLowerCase().replace(' ', '-')}-loops`}
                      value={loops}
                      onChange={onLoopsChange}
                      units="x"
                      label="Loops:"
                      min={1}
                    />
                  )}
                </div>
              </div>
            </AnimationTypeSelectorButtons>
          </div>

          <Popover
            className="AnimationStagePanel__menu"
            id={`${title}-animation-options-menu`}
            anchorEl={menuOpen?.anchor}
            keepMounted
            getContentAnchorEl={null}
            open={Boolean(menuOpen?.anchor)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            PaperProps={{
              style: {
                maxHeight: '50vh',
                minWidth: 200
              }
            }}
          >
            {menuOpen?.primary && (
              <AnimationOptionMenu title="Type">
                <AnimationStagePanelOptionsGrid>
                  {animationTypeOptions.map(opt => {
                    const { Icon: OptIcon, title: optTitle, type: optType } = opt;
                    return (
                      <ScribelyIconButton
                        outline={optType === animationType}
                        key={optType}
                        icon={<OptIcon flipImage={REVERSE_ICON_LIST.includes(optType)} />}
                        onClick={() => handleMenuItemClick(opt)}
                      >
                        {optTitle}
                      </ScribelyIconButton>
                    );
                  })}
                </AnimationStagePanelOptionsGrid>
              </AnimationOptionMenu>
            )}

            {menuOpen?.secondary && secondaryMenu && (
              <AnimationOptionMenu title={secondaryMenu.title}>
                <secondaryMenu.Component
                  element={element}
                  elements={elements}
                  scribe={scribe}
                  onSelection={handleSecondarySelection}
                  stageKey={stageKey}
                />
              </AnimationOptionMenu>
            )}
            {menuOpen?.tertiary && tertiaryMenu && (
              <AnimationOptionMenu title={tertiaryMenu.title}>
                <tertiaryMenu.Component
                  element={element}
                  elements={elements}
                  scribe={scribe}
                  onSelection={handleTertiarySelection}
                  stageKey={stageKey}
                />
              </AnimationOptionMenu>
            )}
          </Popover>
        </EditPanelFieldset>
      </EditPanelForm>
    </div>
  );
};

export default AnimationStagePanel;
