import { fabric } from 'fabric';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import { appendImageToTempCanvas } from 'js/shared/helpers/appendImageToTempCanvas';
import { svgRootFromText } from 'js/shared/lib/SVGConvert';
import svgToDataUrl from 'js/shared/helpers/svgToDataUrl';

import { getEditorSVGDimensions } from './helpers/getEditorSVGDimensions';
import getLockedProperties from './helpers/getLockedProperties';
import getVisibleProperties from './helpers/getVisibleProperties';
import { getPositionalProperties } from './helpers/getPositionalProperties';
import sanitizeAngle from './helpers/sanitizeAngle';
import { getSvgScales } from './helpers/getSvgScales';
import { applyBoundingBoxStyles, setGroupTransform } from './helpers/canvasElementHelpers';
import { getImageStyleProperties } from './helpers/getImageStyleProperties';

export default class SVGImageElement extends fabric.Image {
  id: string;
  locked: boolean;
  hidden: boolean;
  element: ScribeImageElementModel;
  svgUid: string;
  elementType = 'Image' as const;

  constructor(
    image: string | HTMLImageElement | HTMLCanvasElement,
    options: fabric.IImageOptions,
    element: ScribeImageElementModel
  ) {
    super(image, {
      ...options,
      originX: 'left',
      originY: 'top',
      ...getPositionalProperties(element),
      ...getEditorSVGDimensions(element),
      ...getLockedProperties(element.locked || false),
      ...getVisibleProperties(element.locked, element.hidden),
      ...getImageStyleProperties(element)
    });
    this.id = element.id;
    this.locked = element.locked;
    this.hidden = element.hidden;

    this.element = element;
    this.svgUid = element.id;

    this.setBoundingBoxStyles(element);
  }

  // having bounding box styles per class as this will allow us to style them individually should we wish
  private setBoundingBoxStyles(props: ScribeImageElementModel) {
    applyBoundingBoxStyles(this, props);
  }

  public updateProps(element: ScribeImageElementModel) {
    this.locked = element.locked;
    this.hidden = element.hidden;

    const update = this.group
      ? {
          ...getLockedProperties(element.locked || false),
          ...getVisibleProperties(element.locked, element.hidden),
          ...getImageStyleProperties(element)
        }
      : {
          ...getPositionalProperties(element),
          ...getEditorSVGDimensions(element),
          ...getLockedProperties(element.locked || false),
          ...getVisibleProperties(element.locked, element.hidden),
          ...getImageStyleProperties(element)
        };

    this.set(update);
    this.element = element;
    this.setBoundingBoxStyles(element);
  }

  public toVscElement(): ScribeImageElementModel {
    const originalGroupValues = setGroupTransform(this);

    const svgScales = getSvgScales(this.element, {
      scaleX: this.scaleX || 1,
      scaleY: this.scaleY || 1,
      height: this.height || 150,
      width: this.width || 150
    });

    const payload = new ScribeImageElementModel({
      ...this.element,
      ...svgScales,
      y: this.top ?? 0,
      x: this.left ?? 0,
      opacity: this.opacity,
      angle: sanitizeAngle(this.angle),
      width: this.element.width || 150,
      height: this.element.height || 150,
      flipX: !!this.flipX,
      flipY: !!this.flipY,
      originX: this.originX ?? 'left',
      originY: this.originY ?? 'top',
      locked: this.locked,
      hidden: this.hidden
    });

    if (originalGroupValues) {
      this.set(originalGroupValues);
    }

    this.element = payload;

    return payload;
  }

  public static VSFromURL = function(
    url: string,
    element: ScribeImageElementModel,
    callback?: (newImage: SVGImageElement) => void,
    imgOptions?: fabric.IImageOptions
  ) {
    fabric.util.loadImage(
      url,
      (img: HTMLImageElement) => {
        callback && callback(new SVGImageElement(img, (imgOptions = {}), element));
      },
      null,
      imgOptions && imgOptions.crossOrigin
    );
  };

  public static async createSVGElement(element: ScribeImageElementModel): Promise<SVGImageElement> {
    return new Promise(async resolve => {
      /*
          At time of writing scribeElement._imageUrl is a local blobURL
          A tainted canvas warning is being thrown and stopping this conversion from happening
          This is an issue with Chrome: https://bugs.chromium.org/p/chromium/issues/detail?id=294129
          To circumvent this issue we are converting the blobURL into a dataUrl instead.
        */
      if (!element._imageUrl) throw new Error('Image not found');

      const file = await fetch(element._imageUrl).then(r => r.blob());
      const svgText = await file.text();
      const svgElement = svgRootFromText(svgText);
      const svgDataUrl = svgToDataUrl(svgElement);
      const { width, height } = getEditorSVGDimensions(element);

      const canvasCtx = await appendImageToTempCanvas(svgDataUrl, width, height);

      if (!canvasCtx) {
        throw new Error('Image not found');
      }

      const string = canvasCtx.canvas.toDataURL('image/png');

      SVGImageElement.VSFromURL(string, element, resolve, {
        crossOrigin: 'anonymous'
      });
    });
  }
}
