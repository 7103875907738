import { fabric } from 'fabric';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';

import { applyBoundingBoxStyles, setGroupTransform } from './helpers/canvasElementHelpers';
import { getElementScales } from './helpers/getElementScales';
import { getImageStyleProperties } from './helpers/getImageStyleProperties';
import getLockedProperties from './helpers/getLockedProperties';
import { getPositionalProperties } from './helpers/getPositionalProperties';
import getVisibleProperties from './helpers/getVisibleProperties';
import sanitizeAngle from './helpers/sanitizeAngle';

export default class ImageElement extends fabric.Image {
  id: string;
  locked: boolean;
  hidden: boolean;
  elementType: 'Image';
  element: ScribeImageElementModel;

  constructor(
    image: string | HTMLImageElement | HTMLCanvasElement,
    options: fabric.IImageOptions,
    element: ScribeImageElementModel
  ) {
    super(image, {
      ...options,
      ...getImageStyleProperties(element),
      ...getPositionalProperties(element),
      ...getLockedProperties(element.locked || false),
      ...getVisibleProperties(element.locked, element.hidden)
    });
    this.id = element.id;
    this.locked = element.locked;
    this.hidden = element.hidden;
    this.elementType = 'Image';
    this.element = element;

    this.setBoundingBoxStyles(element);
  }

  // having bounding box styles per class as this will allow us to style them individually should we wish
  private setBoundingBoxStyles(props: ScribeImageElementModel) {
    applyBoundingBoxStyles(this, props);
  }

  public updateProps(element: ScribeImageElementModel) {
    this.locked = element.locked;
    this.hidden = element.hidden;

    const update = this.group
      ? {
          ...getImageStyleProperties(element),
          ...getLockedProperties(element.locked || false),
          ...getVisibleProperties(element.locked, element.hidden)
        }
      : {
          ...getImageStyleProperties(element),
          ...getPositionalProperties(element),
          ...getLockedProperties(element.locked || false),
          ...getVisibleProperties(element.locked, element.hidden)
        };

    this.set(update);

    this.element = element;
    this.setBoundingBoxStyles(element);
  }

  public toVscElement(): ScribeImageElementModel {
    const originalGroupValues = setGroupTransform(this);

    const { newScaleX, newScaleY } = getElementScales(this.scaleX, this.scaleY, this.width, this.height);

    this.scaleX = newScaleX;
    this.scaleY = newScaleY;

    const payload = new ScribeImageElementModel({
      ...this.element,
      scaleX: newScaleX,
      scaleY: newScaleY,
      y: this.top ?? 0,
      x: this.left ?? 0,
      opacity: this.opacity,
      angle: sanitizeAngle(this.angle),
      width: this.width ?? 1,
      height: this.height ?? 1,
      flipX: !!this.flipX,
      flipY: !!this.flipY,
      originX: this.originX ?? 'left',
      originY: this.originY ?? 'top',
      locked: this.locked,
      hidden: this.hidden
    });

    if (originalGroupValues) {
      this.set(originalGroupValues);
    }
    return payload;
  }

  public static VSFromURL = function(
    url: string,
    element: ScribeImageElementModel,
    callback?: (newImage: ImageElement) => void,
    imgOptions?: fabric.IImageOptions
  ) {
    fabric.util.loadImage(
      url,
      (img: HTMLImageElement) => {
        callback && callback(new ImageElement(img, (imgOptions = {}), element));
      },
      null,
      imgOptions && imgOptions.crossOrigin
    );
  };

  public static createImageElement = async (element: ScribeImageElementModel): Promise<ImageElement> => {
    return new Promise(resolve => {
      if (!element._imageUrl) throw new Error('Image not found');
      ImageElement.VSFromURL(element._imageUrl, element, resolve, {
        crossOrigin: 'anonymous'
      });
    });
  };

  destroy() {
    this.canvas?.remove(this);
  }
}
