import { createStore, applyMiddleware } from 'redux';
import { composeWithDevToolsLogOnlyInProduction as composeWithDevTools } from '@redux-devtools/extension';

import reducers from '../reducers';

import middlewares, { postApply } from './middlewares';
import { getHistory } from './middlewares/routes';

let store;

export const getStore = () => store;

const composeEnhancers = composeWithDevTools({
  serialize: {
    replacer: (key, value) => {
      // Truncate base64 thumbnail image urls to reduce noise/scrolling in redux devtools
      if ((key === 'thumbnailImage' || key === '_imageUrl') && value) return `${value.slice(0, 40)}...`;
      return value;
    }
  }
});

export const initializeStore = () => {
  if (store) {
    return store;
  }

  const history = getHistory();

  store = createStore(
    reducers(history),
    composeEnhancers(
      applyMiddleware(
        ...Object.values(middlewares)
          .map(middleware => middleware())
          .filter(middleware => typeof middleware === 'function')
      )
    )
  );

  postApply();

  return store;
};
