import gsap from 'gsap';
import PixiPlugin from 'gsap/dist/PixiPlugin';
import ScribeTextElementModel from 'js/models/ScribeTextElementModel';
import { PlaybackScribeModel, Viewport, VSAnimationElement, VSElementModel } from 'js/types';
import * as PIXI from 'pixi.js';

import { GRID_SIZE } from '../../../config/config';
import { hexColorToPixiColor } from '../../../shared/helpers/pixi';

import addWatermarkElements from './helpers/addWatermark';
import getScribeLengthMs from './helpers/getScribeLengthMs';
import resourceLoader from './helpers/resourceLoader';
import { getSceneStartEndTimes } from './helpers/timings';
import VSCursor from './models/VSCursor';
import VSScene from './models/VSScene';
import animationStep from './step';
import { generateBitmapFonts } from './helpers/generateBitmapFonts';
import { calculateFontBaselineShifts } from './helpers/fontBaselineShift';

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

const noop = () => {};

const isTextElement = (element: VSElementModel): element is ScribeTextElementModel => {
  return (element as ScribeTextElementModel).type === 'Text';
};

interface VSScribePlayerProps {
  addWatermark: boolean;
  endPlayback: () => void;
  endTime: number;
  hasLoadedPlaybackAssets: () => void;
  isCapturing: boolean;
  onPlayerReady: () => void;
  refAnimationCanvasEl: HTMLDivElement;
  scribe: PlaybackScribeModel;
  startTime: number;
  viewportDimensions: Viewport;
  maxStageDimensions: PIXI.Rectangle;
  onAnimationLoopStart?: () => void;
  onPlaybackError?: (error: Error) => void;
  onReachedEndTime: () => void;
  onSetupError?: (error: Error) => void;
  onStop?: () => void;
  onTick?: (view: HTMLCanvasElement, elapsedTime: number) => void;
  shouldPlay?: boolean;
  showWatermarksOnPremiumContent: boolean;
}

interface SetupPlaybackProps {
  viewportDimensions: Viewport;
  scribe: PlaybackScribeModel;
  endPlayback: () => void;
  hasLoadedPlaybackAssets: () => void;
  segmentStartTime: number;
  segmentEndTime: number;
  isCapturing: boolean;
}

let pixiApp: PIXI.Application;
export const getPixiPlaybackApp = (viewport: Viewport, backgroundColor?: string): PIXI.Application => {
  if (pixiApp && pixiApp.renderer instanceof PIXI.Renderer && !pixiApp.renderer.context.isLost) {
    if (
      pixiApp.renderer.width !== viewport.width ||
      pixiApp.renderer.height !== viewport.height ||
      pixiApp.renderer.resolution !== viewport.resolution
    ) {
      pixiApp.renderer.resize(viewport.width, viewport.height);
      pixiApp.renderer.resolution = viewport.resolution;
    }

    return pixiApp;
  }

  pixiApp = new PIXI.Application({
    width: viewport.width,
    height: viewport.height,
    backgroundColor: backgroundColor ? hexColorToPixiColor(backgroundColor) : 0xffffff,
    resolution: viewport.resolution,
    antialias: true,
    preserveDrawingBuffer: true
  });

  if (import.meta.env.VITE_DEBUG_PIXI_PLAYBACK) {
    //@ts-ignore set DEBUG_PIXI_PLAYBACK in env vars to enable Pixi dev tools
    globalThis.__PIXI_APP__ = pixiApp;
  }

  return pixiApp;
};

/**
 * In path `/editor/:scribeId/playback` the App plays the scribe-animation.
 * In path `/render/:scribeId/:startTime?/:duration?` the App plays the scribe-animation and `captures` the renderer canvas into a `png` images.
 */
export class VSScribePlayer {
  viewportDimensions: Viewport;
  scribeTotalLengthMs: number;
  onStop: () => void;
  shouldPlay: boolean;
  addWatermark: boolean;
  onAnimationLoopStart: () => void;
  onPlaybackError: (error: Error) => void;
  onTick: (view: HTMLCanvasElement, elapsedTime: number) => void;
  onReachedEndTime: () => void;
  app: PIXI.Application;
  scenes?: Array<VSScene>;
  elapsedTime?: number;
  animation?: number;
  destroyed = false;
  maxStageDimensions: PIXI.Rectangle;
  showWatermarksOnPremiumContent: boolean;

  constructor({
    viewportDimensions,
    maxStageDimensions,
    scribe,
    refAnimationCanvasEl,
    onPlayerReady,
    endPlayback,
    shouldPlay = false,
    hasLoadedPlaybackAssets,
    onTick = noop,
    startTime,
    onStop = noop,
    addWatermark,
    endTime,
    onAnimationLoopStart = noop,
    isCapturing,
    onSetupError = noop,
    onPlaybackError = noop,
    onReachedEndTime,
    showWatermarksOnPremiumContent
  }: VSScribePlayerProps) {
    if (
      !viewportDimensions ||
      !viewportDimensions.width ||
      !viewportDimensions.height ||
      !viewportDimensions.resolution
    ) {
      throw new Error(
        'Incorrect viewport dimensions supplied. Please provide correct viewport dimensions {width, height, resolution}'
      );
    }
    this.viewportDimensions = viewportDimensions;
    this.maxStageDimensions = maxStageDimensions;
    this.showWatermarksOnPremiumContent = showWatermarksOnPremiumContent;

    this.scribeTotalLengthMs = getScribeLengthMs(scribe);

    this.onStop = onStop;
    this.shouldPlay = shouldPlay;
    this.addWatermark = addWatermark;
    this.onAnimationLoopStart = onAnimationLoopStart;
    this.onPlaybackError = onPlaybackError;
    this.onTick = onTick;
    this.onReachedEndTime = onReachedEndTime;
    // Set up the PIXI application and attach it to the DOM element
    PIXI.utils.skipHello();
    this.app = getPixiPlaybackApp(viewportDimensions, scribe.settings.backgroundColor);
    this.setupPlayback({
      viewportDimensions,
      scribe,
      endPlayback,
      hasLoadedPlaybackAssets,
      segmentStartTime: startTime,
      segmentEndTime: endTime,
      isCapturing
    })
      .then(() => {
        if (!this.destroyed) {
          onPlayerReady();
          refAnimationCanvasEl.appendChild(this.app.view);
        }
      })
      .catch(e => {
        console.error(e);
        onSetupError(e);
        if (this.animation) window.cancelAnimationFrame(this.animation);
        throw e;
      });
  }

  addGridlines = () => {
    const GRID_LINE_CENTER_STYLE = [2, 0xff00ff];
    const GRID_LINE_STYLE = [1, 0x00ffff];
    const { width, height } = this.viewportDimensions;
    const horizSteps = width / GRID_SIZE;
    const vertSteps = height / GRID_SIZE;

    for (let x = 1; x < horizSteps; x++) {
      const isCenterLine = x * GRID_SIZE === width / 2;
      const line = new PIXI.Graphics();
      const style = isCenterLine ? GRID_LINE_CENTER_STYLE : GRID_LINE_STYLE;
      line
        .lineStyle(style[0], style[1])
        .moveTo(x * GRID_SIZE, 0)
        .lineTo(x * GRID_SIZE, height);
      this.app.stage.addChild(line);
    }
    for (let y = 1; y < vertSteps; y++) {
      const isCenterLine = y * GRID_SIZE === height / 2;
      const line = new PIXI.Graphics();
      const style = isCenterLine ? GRID_LINE_CENTER_STYLE : GRID_LINE_STYLE;
      line
        .lineStyle(style[0], style[1])
        .moveTo(0, y * GRID_SIZE)
        .lineTo(width, y * GRID_SIZE);
      this.app.stage.addChild(line);
    }
  };

  getElementBeingRevealedInTick = (elapsedTime: number) => {
    return this.scenes
      ?.flatMap(s => s.sceneElements)
      .find(el => {
        const startEndTime = el.timings.startTimeMs + el.animationTime;
        // Element has entrance animation
        if (elapsedTime > el.timings.startTimeMs && elapsedTime < startEndTime) {
          return true;
        }

        const emphasisEndTime = startEndTime + el.emphasisAnimationTime;

        // Element has emphasis animation & emphasis cursor Id
        if (
          elapsedTime > startEndTime &&
          elapsedTime < emphasisEndTime &&
          el.element?.emphasisAnimation?.config?.cursorId
        ) {
          return true;
        }
        // Element has exit animation & exit cursor Id

        const exitEndTime = emphasisEndTime + el.exitAnimationTime;

        if (elapsedTime > emphasisEndTime && elapsedTime < exitEndTime && el.element?.exitAnimation?.config?.cursorId) {
          return true;
        }

        return false;
      });
  };

  getAnimatingScene = (
    elementRevealing: VSAnimationElement | undefined,
    sceneList: Array<VSScene>,
    elapsedTime: number
  ) => {
    if (elementRevealing) {
      return undefined;
    }

    return sceneList.find(sce => {
      if (
        sce.sceneModel.settings?.sceneTransitionTime === undefined ||
        sce.sceneModel.settings.sceneTransitionTime === 0
      ) {
        return false;
      }

      return (
        elapsedTime > sce.timings.startTimeMs &&
        elapsedTime < sce.timings.startTimeMs + sce.sceneModel.settings.sceneTransitionTime * 1000
      );
    });
  };

  async setupPlayback({
    viewportDimensions,
    scribe,
    endPlayback,
    hasLoadedPlaybackAssets,
    segmentStartTime,
    segmentEndTime,
    isCapturing
  }: SetupPlaybackProps) {
    const { elements, canvasSize } = scribe;

    this.app.stage.removeChildren();
    // Preload all images in the scribe
    const imageResources = await resourceLoader(scribe);

    const textElements = elements.filter(isTextElement);

    try {
      await generateBitmapFonts(textElements);
      await calculateFontBaselineShifts(textElements);
    } catch (error) {
      // Continue using fallback text
      console.error('Error generating bitmap fonts', error);
    }

    const startTime = segmentStartTime || 0;
    const endTime =
      typeof segmentEndTime === 'undefined'
        ? this.scribeTotalLengthMs
        : Math.min(segmentEndTime, this.scribeTotalLengthMs);

    // Create the hand sprite to follow the animation points
    const cursor = new VSCursor(
      { x: viewportDimensions.width, y: viewportDimensions.height },
      scribe.cursorData,
      canvasSize,
      imageResources.rasterResources
    );

    // Create PIXI stage elements for each of the Scribe's scenes and add them to the stage
    let prevScene: VSScene | null;
    this.scenes = scribe.scenes
      .map((scene, sceneIndex, scribeScenesArray) => {
        const { startTime: sceneStartTime, endTime: sceneEndTime } = getSceneStartEndTimes(scribe, scene);
        const nextSceneSettings = scribeScenesArray.at(sceneIndex + 1);

        const sceneInstance = new VSScene({
          scene,
          scribe,
          viewportDimensions,
          maxStageDimensions: this.maxStageDimensions,
          scribeTotalLengthMs: this.scribeTotalLengthMs,
          imageResources,
          renderer: this.app.renderer as PIXI.Renderer,
          timings: {
            startTimeMs: sceneStartTime,
            endTimeMs: sceneEndTime
          },
          playerRef: this.app,
          prevScene,
          nextSceneTransitionTimeMs: (nextSceneSettings?.settings?.sceneTransitionTime ?? 0) * 1000,
          showWatermarksOnPremiumContent: this.showWatermarksOnPremiumContent
        });
        prevScene = sceneInstance;

        return sceneInstance;
      })
      .filter(Boolean);

    if (this.destroyed) return;
    for (const scene of this.scenes) {
      this.app.stage.addChild(scene.viewportContainer);
      await scene.initialiseTimelines();
    }

    // Add the hand to the stage last as last-in has highest Z-index
    this.app.stage.addChild(cursor.getStageElement());

    if (import.meta.env.VITE_DEBUG_PLAYBACK_GRID) {
      this.addGridlines();
    }

    if (this.addWatermark) {
      addWatermarkElements(this.app.stage, viewportDimensions.width, viewportDimensions.height);
    }

    const updateScenes = (elapsedTime: number) => {
      if (!this.app.stage || !this.scenes) {
        return;
      }

      this.scenes.forEach(scene => scene.update(elapsedTime));

      const elementBeingRevealed = this.getElementBeingRevealedInTick(elapsedTime);

      const penGlobalPosition =
        elementBeingRevealed && elementBeingRevealed.cursor
          ? elementBeingRevealed.cursor.getGlobalPosition()
          : { x: this.app.stage.width * 2, y: this.app.stage.height * 2 };

      const sceneAnimating = this.getAnimatingScene(elementBeingRevealed, this.scenes, elapsedTime);

      const positionForSceneCursor = sceneAnimating?.cursor?.getGlobalPosition?.() ?? penGlobalPosition;

      const cursorAngle = sceneAnimating ? sceneAnimating?.cursor?.angle : 0;

      cursor.updateCursorPosition(positionForSceneCursor, cursorAngle, elapsedTime);

      this.app.renderer.render(this.app.stage);
    };

    this.app.renderer.plugins.prepare.upload(this.app.stage).then(() => {
      if (this.destroyed) return;
      try {
        hasLoadedPlaybackAssets();

        this.elapsedTime = startTime;

        // *** This is not great but it works ***
        // As CCapture Requires `requestAnimationFrame` to be running before it takes control
        // it means the first loop timestamp is bogus and then on the second loop the delta
        // would be 0 resulting in 3 identical frames.
        // By having the firstTick and firstFrameDeltaAdjustment we can set up and
        // spoof the first delta so timings are correct for the capturer.
        const firstTick = true;
        const firstFrameDeltaAdjustment = isCapturing ? 40 : 0;

        updateScenes(this.elapsedTime); // set up first frame

        this.animation = window.requestAnimationFrame(
          animationStep.bind(this, {
            firstTick,
            firstFrameDeltaAdjustment,
            endTime,
            endPlayback,
            updateScenes
          })
        );
        this.onAnimationLoopStart();
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          this.onPlaybackError(error);
        }
        throw error;
      }
    });
  }

  progress = (progress: number) => {
    const elapsedTime = this.scribeTotalLengthMs * (progress / 100);
    this.elapsedTime = elapsedTime;
    this.onTick(this.app.view, this.elapsedTime);
  };

  destroy() {
    this.destroyed = true;
    if (typeof this.animation !== 'undefined') {
      window.cancelAnimationFrame(this.animation);
    }
    this.scenes?.forEach(scene => {
      scene.destroy();
    });
    this.scenes = [];
  }
}

export default VSScribePlayer;
