import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@researchgate/react-intersection-list';
import Loader from 'react-loader';

import IconTrash from '../icons/IconTrash';

import ImageBrowsePanelThumbnail from './ImageBrowsePanelThumbnail';

export const DEFAULT_PAGE_SIZE = 30;

export default class ImageList extends Component {
  static propTypes = {
    pageSize: PropTypes.number,
    onIntersection: PropTypes.func,
    images: PropTypes.arrayOf(PropTypes.object),
    totalImages: PropTypes.number,
    threshold: PropTypes.string,
    useCursor: PropTypes.bool,
    cursor: PropTypes.string,
    showRecolorIcon: PropTypes.bool,
    onSelectImage: PropTypes.func,
    onDeleteImage: PropTypes.func,
    asyncUrlProvider: PropTypes.func
  };

  static defaultProps = {
    pageSize: DEFAULT_PAGE_SIZE,
    onIntersection: () => {},
    onSelectImage: () => {},
    asyncUrlProvider: () => Promise.resolve(),
    onDeleteImage: null,
    useCursor: false,
    cursor: null,
    images: [],
    totalImages: 0,
    threshold: '250px',
    showRecolorIcon: false
  };

  renderItem = (index, key) => {
    const {
      totalImages,
      images,
      onSelectImage,
      onDeleteImage,
      asyncUrlProvider,
      useCursor,
      cursor,
      showRecolorIcon
    } = this.props;
    const img = images[index];
    const showLoader =
      index === images.length - 1 && ((useCursor && cursor) || (!useCursor && images.length < totalImages));
    return (
      <React.Fragment key={key}>
        <figure className="ImageBrowsePanel__imageContainer" title={img.title} onClick={() => onSelectImage(img)}>
          <ImageBrowsePanelThumbnail
            showLoadingSpinner={true}
            className="ImageBrowsePanel__image"
            assetId={img.id}
            bucketUrl={img.bucketUrl}
            imageFilename={img.imageFilename}
            asyncUrlProvider={asyncUrlProvider}
            thumb={true}
            showRecolorIcon={showRecolorIcon}
          />
          {typeof onDeleteImage === 'function' && (
            <button className="ImageBrowsePanel__deleteButton" onClick={e => onDeleteImage(e, img)}>
              <IconTrash />
            </button>
          )}
        </figure>
        {showLoader ? (
          <figure className="ImageBrowsePanel__imageContainer ImageBrowsePanel__imageContainer--transparent">
            <Loader loaded={false} />
          </figure>
        ) : null}
      </React.Fragment>
    );
  };

  imagesRenderer(images, ref) {
    return (
      <div className="ImageBrowsePanel__imageList" ref={ref}>
        {images}
      </div>
    );
  }

  render() {
    const { images, pageSize, onIntersection, threshold } = this.props;
    return (
      <List
        pageSize={pageSize - 1}
        threshold={threshold}
        onIntersection={onIntersection}
        items={images}
        renderItem={this.renderItem}
        itemsRenderer={this.imagesRenderer}
      />
    );
  }
}
