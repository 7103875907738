import { Userpilot } from 'userpilot';
import { call, takeEvery, take, select } from 'redux-saga/effects';
import { LOGOUT, VALIDATE_TOKEN_SUCCESS } from 'js/actionCreators/authActions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { E2E_GEN_TEST_USER_PREFIX, E2E_GEN_TEST_USER_SUFFIX } from 'js/config/e2eTestUser';
import {
  ADD_SCRIBE_SUCCESS,
  CREATE_IMAGE_ELEMENT,
  CREATE_SCENE,
  DELETE_SCRIBE_SUCCESS,
  DUPLICATE_SELECTED_SCENES,
  LOAD_SCRIBES_SUCCESS
} from 'js/actionCreators/scribeActions';
import {
  CLOSE_USERPERSONA_MODAL,
  DISMISS_USERPERSONA_MODAL,
  SUBMIT_USERPERSONA_MODAL
} from 'js/actionCreators/marketingActions';
import { TRACK_PLAY_SCRIBE, TRACK_TEMPLATE_EDIT } from 'js/actionCreators/trackingActions';
import getAudioFileDuration from 'js/shared/helpers/getAudioFileDuration';
import { GET_VIDEO_DOWNLOAD_URL_SUCCESS } from 'js/actionCreators/downloadActions';
import { DUPLICATE_SCRIBE_SUCCESS } from 'js/actionCreators/duplicateScribeActions';
import { SAVE_AUDIO } from 'js/actionCreators/audioActions';
import { getExperimentAndFlagsUserPropertiesForTracking } from 'js/shared/helpers/getExperimentUserPropertiesForTracking';
import { OPEN_RESOURCE_CENTER } from 'js/actionCreators/uiActions';

import { getUser } from '../selectors';

const debug = !!import.meta.env.VITE_DEBUG_USERPILOT;
const logger = debug ? (...args) => console.log(`%c[Userpilot Debug]: `, 'color: #f75a79', ...args) : () => {};
const isE2eTestUser = email => email.includes(E2E_GEN_TEST_USER_PREFIX) && email.includes(E2E_GEN_TEST_USER_SUFFIX);

Userpilot.initialize(import.meta.env.VITE_USERPILOT_KEY);
let identified;

function userpilotInitializeWithLogger(id, config) {
  Userpilot.identify(id, config);
  identified = true;
  logger(`Identified user ${id} with config`, config);
}

function userpilotTrackWithLogger(eventName, properties) {
  Userpilot.track(eventName, properties);
  logger(`Track event - ${eventName}`, properties);
}

function identifiedOnlyFn(fn) {
  return (...args) => {
    if (identified) {
      return fn.apply(null, args);
    }
  };
}

function* userpilotIdentify({ user }) {
  if (!isE2eTestUser(user.email)) {
    const userConfig = {
      email: user.email,
      name: user.firstname,
      'Subscription Tier': user.accountType,
      'Licence Type': user.accountType,
      'Signup Date': user.signupDate,
      ...getExperimentAndFlagsUserPropertiesForTracking()
    };

    yield call(userpilotInitializeWithLogger, user.id, userConfig);
  }
}

function logout() {
  identified = false;
}

function* userpilotReload() {
  yield call(Userpilot.reload);
  logger(`Reloading Userpilot`);
}

const updateScribeCount = identifiedOnlyFn(function*(action) {
  const user = yield select(getUser);
  const scribes = yield select(state => state.scribes.homepageProjects);
  yield call(userpilotInitializeWithLogger, user.id, {
    'Number of Scribes Created': scribes.length
  });

  if (action.type === ADD_SCRIBE_SUCCESS && action.scribe?.source?.type === 'blank') {
    const { scribe } = action;
    yield call(userpilotTrackWithLogger, 'Create Blank Scribe', {
      'Canvas Size': scribe.canvasSize
    });
  }
});

const trackDismissUserPersonaModal = identifiedOnlyFn(function*() {
  yield call(userpilotTrackWithLogger, 'Dismissed User Persona Modal');
});

const trackSubmitUserPersonaModal = identifiedOnlyFn(function*({ answers }) {
  yield take(CLOSE_USERPERSONA_MODAL);
  const organization = answers.user_type === 'other' ? `other:${answers.user_type_text}` : answers.user_type;
  yield call(userpilotTrackWithLogger, 'Completed User Persona Modal', {
    Organization: organization,
    Intentions: answers.intention
  });

  const user = yield select(getUser);
  yield call(userpilotInitializeWithLogger, user.id, {
    'User Organisation': organization,
    'User Intentions': answers.intention
  });
});

const trackEditTemplate = identifiedOnlyFn(function*({ title, category, triggerLocation }) {
  yield call(userpilotTrackWithLogger, 'Edit Template', {
    'Template Title': title,
    'Template Category': category,
    'Button Location': triggerLocation
  });
});

const trackAddingScene = identifiedOnlyFn(function*() {
  yield call(userpilotTrackWithLogger, 'Add Scene');
});

const trackDuplicateScene = identifiedOnlyFn(function*() {
  yield call(userpilotTrackWithLogger, 'Duplicate Scene');
});

const trackAudio = identifiedOnlyFn(function*({ audio, source, trackLength, audioType }) {
  let duration = trackLength;
  try {
    if (!duration) {
      duration = yield call(getAudioFileDuration, audio);
    }
  } catch (error) {
    console.error(error);
  }

  yield call(userpilotTrackWithLogger, 'Update Audio', {
    'Audio Length': duration,
    'Audio Source': source,
    'Audio Type': audioType
  });
});

const trackPlayScribe = identifiedOnlyFn(function*({ button, sceneCount }) {
  if (button === 'fromStart') {
    yield call(userpilotTrackWithLogger, 'Play From Video Start', {
      'Number Of Scenes': sceneCount
    });
  }
});

const trackDownload = identifiedOnlyFn(function*({ trackingProperties }) {
  yield call(userpilotTrackWithLogger, 'Download Succeeded', trackingProperties);
});

const trackAddImageToCanvas = identifiedOnlyFn(function*() {
  yield call(userpilotTrackWithLogger, 'Add Image To Canvas');
});

function openRescourceCenter() {
  Userpilot.trigger('Sr3G26T');
}

export default function* userpilotSagas() {
  yield takeEvery(LOCATION_CHANGE, userpilotReload);
  yield takeEvery(OPEN_RESOURCE_CENTER, openRescourceCenter);
  yield takeEvery([VALIDATE_TOKEN_SUCCESS], userpilotIdentify);
  yield takeEvery(LOGOUT, logout);
  yield takeEvery(
    [LOAD_SCRIBES_SUCCESS, ADD_SCRIBE_SUCCESS, DELETE_SCRIBE_SUCCESS, DUPLICATE_SCRIBE_SUCCESS],
    updateScribeCount
  );
  yield takeEvery(DISMISS_USERPERSONA_MODAL, trackDismissUserPersonaModal);
  yield takeEvery(SUBMIT_USERPERSONA_MODAL, trackSubmitUserPersonaModal);
  yield takeEvery(TRACK_TEMPLATE_EDIT, trackEditTemplate);
  yield takeEvery(CREATE_SCENE, trackAddingScene);
  yield takeEvery(DUPLICATE_SELECTED_SCENES, trackDuplicateScene);
  yield takeEvery(SAVE_AUDIO, trackAudio);
  yield takeEvery(TRACK_PLAY_SCRIBE, trackPlayScribe);
  yield takeEvery(GET_VIDEO_DOWNLOAD_URL_SUCCESS, trackDownload);
  yield takeEvery(CREATE_IMAGE_ELEMENT, trackAddImageToCanvas);
}
