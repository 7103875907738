import {
  UPDATE_PROJECT_TITLE,
  UpdateProjectTitleAction,
  updateProjectTitleSuccess,
  updateProjectTitleFailed
} from 'js/actionCreators/scribeActions';
import { showError } from 'js/actionCreators/uiActions';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { getProject, updateProject } from 'js/shared/helpers/app-services/AppServices/projects';
import { ScribeObject } from 'js/types';
import cloneDeep from 'lodash.clonedeep';
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

export const ERROR_MESSAGE = 'There was an issue renaming your project. Please try again.';

export function* updateProjectTitle({
  title,
  scribeId
}: UpdateProjectTitleAction): Generator<CallEffect | PutEffect, void, ScribeObject> {
  // get scribe from network to ensure we have the latest version to prevent overriding changes in other tabs
  const { projectModel } = yield call(getProject, scribeId, appServices.exchangeCognitoTokenForSparkolToken);

  if (projectModel) {
    const previousTitle = projectModel.title;
    const scribeClone = cloneDeep(projectModel);
    scribeClone.title = title;
    scribeClone.id = scribeClone.id ? scribeClone.id : scribeId;

    try {
      yield call(updateProject, scribeClone, appServices.exchangeCognitoTokenForSparkolToken);
      yield put(updateProjectTitleSuccess({ scribeId, title }));
    } catch (error) {
      yield put(updateProjectTitleFailed({ scribeId, previousTitle }));
      yield put(showError({ message: ERROR_MESSAGE }));
    }
  }
}

export default function* updateProjectTitleSaga() {
  yield takeLatest(UPDATE_PROJECT_TITLE, updateProjectTitle);
}
