export function svgToImageBitmap(svgString) {
  if (!svgString) return Promise.reject('Invalid svg');

  return new Promise(resolve => {
    const img = new Image();
    img.onload = function() {
      createImageBitmap(this).then(bitmap => {
        resolve(bitmap);
      });
    };
    img.src = 'data:image/svg+xml;charset=utf8,' + encodeURIComponent(svgString);
  });
}
