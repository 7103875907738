import ScribeModel from 'js/models/ScribeModel';
import { ScribeScene, VSElementModel } from 'js/types';
import cloneDeep from 'lodash.clonedeep';

export const getActiveScene = (scribe: ScribeModel): ScribeScene => {
  const activeScene = scribe.scenes.find(scene => scene.active);
  if (!activeScene) {
    return scribe.scenes[0];
  }
  return activeScene;
};

export const getActiveSceneIndex = (scribe: ScribeModel): number => {
  return scribe.scenes.findIndex(scene => scene.active);
};
export const getScenesById = (sceneIds: string[], scribe: ScribeModel): ScribeScene[] => {
  const toReturn: ScribeScene[] = [];
  scribe.scenes.forEach((scene: ScribeScene) => {
    if (sceneIds.includes(scene.id)) {
      toReturn.push(scene);
    }
  });

  if (toReturn.length === 0) {
    const activeScene = getActiveScene(scribe);

    if (!activeScene) {
      return toReturn;
    }

    toReturn.push(activeScene);
  }

  return toReturn;
};

export const filteredSceneElements = (scribe: ScribeModel, elementIds: Array<string>) => {
  const elementsPossiblyInScene = elementIds.map(elId => scribe.elements.find(el => el.id === elId));
  const result = elementsPossiblyInScene.filter((element): element is VSElementModel => !!element);
  return result;
};

export const mapSceneElementIdsToElements = (scribe: ScribeModel, scene: ScribeScene): VSElementModel[] => {
  if (!scene) {
    return [];
  }
  return filteredSceneElements(scribe, scene.elementIds);
};

export const mapActiveSceneElementIdsToElements = (scribe: ScribeModel) => {
  const scene = getActiveScene(scribe);
  if (!scene) {
    return undefined;
  }
  return mapSceneElementIdsToElements(scribe, scene);
};

export const getSceneSettings = (scene?: ScribeScene) => {
  if (!scene) {
    return undefined;
  }
  return cloneDeep(scene.settings);
};

export const getActiveSceneSettings = (scribe: ScribeModel) => {
  return getSceneSettings(getActiveScene(scribe));
};

export const getHidableElements = (scribe: ScribeModel) => {
  if (!scribe) return [];
  const activeScene = getActiveScene(scribe);
  if (!activeScene) return [];
  return activeScene.elementIds.reduce((acc, elementId) => {
    const element = scribe.elements.find(el => el.id === elementId);
    if (!element || element.type === 'Camera') return acc;
    return [...acc, element];
  }, [] as ScribeModel['elements']);
};
