import * as PIXI from 'pixi.js';
export const getBitmapRect = (height: number, color: number) => {
  let gfx: GraphicsPoolItem | undefined;
  let poolIndex = -1;
  if (gfxPool.length > 0) {
    gfx = gfxPool.find((gfx, index) => {
      if (gfx.name === color.toString()) {
        poolIndex = index;
        return gfx;
      }
    });
    if (gfx) {
      gfxPool.splice(poolIndex, 1);
    }
  }
  if (gfx === undefined) {
    gfx = new GraphicsPoolItem();

    gfx.beginFill(color);

    gfx.drawRect(0, 0, 100, 100);
    gfx.endFill();
    gfx.cacheAsBitmap = true;
    gfx.cacheAsBitmapMultisample = PIXI.MSAA_QUALITY.NONE;
    gfx.name = color.toString();
  }
  gfx.alpha = 1;
  gfx.height = height;
  return gfx;
};
export const gfxPool: GraphicsPoolItem[] = [];
export class GraphicsPoolItem extends PIXI.Graphics {
  constructor() {
    super();
  }

  destroy() {
    if (!gfxPool.includes(this)) {
      gfxPool.push(this);
    }
  }
}
