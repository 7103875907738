import { SetShowElementModalAction, SET_SHOW_ELEMENT_MODAL } from 'js/actionCreators/scribeActions';
import { ImageState, UserImageLibraryCollection, VSCAssetImageSourceName } from 'js/types';
import cloneDeep from 'lodash.clonedeep';
import { DEFAULT_PAGE_SIZE } from 'js/shared/components/ImageList';

import {
  GET_IMAGE_LIBRARIES_SUCCESS,
  GET_IMAGE_LIBRARY_SUCCESS,
  GET_USER_IMAGES_REQUESTED,
  GET_USER_IMAGES_FAILURE,
  GET_USER_IMAGES_SUCCESS,
  DELETE_USER_IMAGE_SUCCESS,
  SEARCH_IMAGE,
  SEARCH_IMAGE_FAILURE,
  SEARCH_IMAGE_SUCCESS,
  GetImageLibrariesSuccessAction,
  GetImageLibrarySuccessAction,
  GetUserImagesFailureAction,
  GetUserImagesSuccessAction,
  DeleteUserImageSuccessAction,
  SearchImagesAction,
  SearchImagesSuccessAction,
  SearchImagesFailureAction,
  LOAD_USER_IMAGES_SLICE_SUCCESS,
  LOAD_MORE_USER_IMAGES,
  LoadUserImagesSliceSuccessAction,
  LoadMoreUserImagesAction,
  CLOSE_IMAGE_LIBRARY,
  CloseImageLibraryAction,
  SET_SEARCH_MEMO,
  SetSearchMemoAction,
  LOAD_MORE_USER_AI_IMAGES,
  LOAD_USER_AI_IMAGES_SLICE_SUCCESS,
  LoadMoreUserAiImagesAction,
  LoadUserAiImagesSliceSuccessAction,
  GET_IMAGE_LIBRARIES,
  GetImageLibrariesFailureAction,
  GET_IMAGE_LIBRARIES_FAILURE,
  GetImageLibrariesAction,
  PrependUserImagesListAction,
  PREPEND_USER_IMAGES_LIST,
  LoadPageOfUserImagesSuccessAction,
  LOAD_PAGE_OF_USER_IMAGES_SUCCESS,
  LOAD_PAGE_OF_USER_IMAGES,
  LoadPageOfUserImagesAction,
  ADD_USER_IMAGE_TO_SCRIBE,
  AddUserImageToScribeAction,
  START_REPLACE_IMAGE,
  StartReplaceImageAction,
  CLEAR_REPLACE_IMAGE,
  ClearReplaceImageAction
} from '../actionCreators/imagesActions';

import { filterSourceAssetsIntoImageLibraries } from './reducerHelpers/filterSourceAssetsIntoImageLibraries';

const initializeUserLibrary = () => {
  const userLibrary = {} as UserImageLibraryCollection;

  Object.values(VSCAssetImageSourceName).forEach(sourceName => {
    userLibrary[sourceName] = {
      loading: false,
      loadingSortedByLastUsed: false,
      sourceAssets: [],
      images: [],
      imagesSortedByLastUsed: []
    };
  });

  return userLibrary;
};

const initialState: ImageState = {
  loadingLibraries: false,
  libraries: [],
  nounProjectLibraries: [],
  userImagesLoading: false,
  userImageAssets: [],
  userImages: [],
  search: { results: [] },
  searchNounProject: { results: [] },
  userImagePage: 1,
  userImagePageSize: DEFAULT_PAGE_SIZE,
  userAiImagesLoading: false,
  userAiImageAssets: [],
  userAiImages: [],
  userAiImagePage: 1,
  userLibrary: initializeUserLibrary(),
  replacingImageElementId: null
};

type ImagesAction =
  | GetImageLibrariesSuccessAction
  | GetImageLibrarySuccessAction
  | { type: typeof GET_USER_IMAGES_REQUESTED }
  | GetUserImagesFailureAction
  | GetUserImagesSuccessAction
  | DeleteUserImageSuccessAction
  | SearchImagesAction
  | SearchImagesSuccessAction
  | SearchImagesFailureAction
  | SetShowElementModalAction
  | LoadUserImagesSliceSuccessAction
  | LoadMoreUserImagesAction
  | CloseImageLibraryAction
  | SetSearchMemoAction
  | LoadUserAiImagesSliceSuccessAction
  | LoadMoreUserAiImagesAction
  | GetImageLibrariesAction
  | GetImageLibrariesFailureAction
  | PrependUserImagesListAction
  | LoadPageOfUserImagesAction
  | LoadPageOfUserImagesSuccessAction
  | AddUserImageToScribeAction
  | StartReplaceImageAction
  | ClearReplaceImageAction;

export default function imagesReducer(state = initialState, action: ImagesAction): ImageState {
  switch (action.type) {
    case SET_SEARCH_MEMO:
      return {
        ...state,
        searchMemo: action.searchMemo
      };

    case GET_IMAGE_LIBRARIES: {
      return {
        ...state,
        loadingLibraries: true
      };
    }

    case GET_IMAGE_LIBRARIES_SUCCESS:
      return {
        ...state,
        loadingLibraries: false,
        libraries: action.libraries,
        nounProjectLibraries: action.libraries
      };

    case GET_IMAGE_LIBRARIES_FAILURE: {
      return {
        ...state,
        loadingLibraries: false
      };
    }

    case GET_IMAGE_LIBRARY_SUCCESS:
      return {
        ...state,
        libraries:
          action.searchSource !== 'videoscribe'
            ? state.libraries
            : state.libraries.map(library => {
                if (library.id !== action.libraryId) return library;
                return {
                  ...library,
                  images: [...(library.images || []), ...action.library],
                  cursor: action.cursor,
                  searchSource: action.searchSource
                };
              }),
        nounProjectLibraries:
          action.searchSource !== 'noun-project'
            ? state.nounProjectLibraries
            : state.nounProjectLibraries.map(library => {
                if (library.id !== action.libraryId) return library;
                return {
                  ...library,
                  images: [...(library.images || []), ...action.library],
                  cursor: action.cursor,
                  searchSource: action.searchSource
                };
              })
      };

    case GET_USER_IMAGES_REQUESTED: {
      return {
        ...state,
        userImagesLoading: true,
        userAiImagesLoading: true
      };
    }

    case GET_USER_IMAGES_FAILURE: {
      return {
        ...state,
        userImagesLoading: false,
        userAiImagesLoading: false
      };
    }

    case GET_USER_IMAGES_SUCCESS: {
      const userLibrary = filterSourceAssetsIntoImageLibraries(state.userLibrary, action.payload);

      return {
        ...state,
        userImagesLoading: !!action.payload.length,
        userAiImagesLoading: false,
        userImageAssets: action.payload.filter(
          asset => !asset.sourceName || asset.sourceName !== VSCAssetImageSourceName.AI
        ),
        userAiImageAssets: action.payload.filter(asset => asset.sourceName === VSCAssetImageSourceName.AI),
        userLibrary
      };
    }

    case LOAD_USER_IMAGES_SLICE_SUCCESS: {
      return {
        ...state,
        userImagesLoading: false,
        userImages: [...state.userImages, ...action.images]
      };
    }

    case LOAD_PAGE_OF_USER_IMAGES: {
      const loadingKey = action.sortedByLastUsed ? 'loadingSortedByLastUsed' : 'loading';

      return {
        ...state,
        userLibrary: {
          ...state.userLibrary,
          [action.imageType]: {
            ...state.userLibrary[action.imageType],
            [loadingKey]: true
          }
        }
      };
    }

    case LOAD_PAGE_OF_USER_IMAGES_SUCCESS: {
      const loadingKey = action.sortedByLastUsed ? 'loadingSortedByLastUsed' : 'loading';
      const imageListKey = action.sortedByLastUsed ? 'imagesSortedByLastUsed' : 'images';
      const imageList = state.userLibrary[action.imageType][imageListKey];
      const updatedImageList = imageList.concat(action.images);

      return {
        ...state,
        userLibrary: {
          ...state.userLibrary,
          [action.imageType]: {
            ...state.userLibrary[action.imageType],
            [imageListKey]: updatedImageList,
            [loadingKey]: false
          }
        }
      };
    }

    case LOAD_USER_AI_IMAGES_SLICE_SUCCESS: {
      return {
        ...state,
        userAiImagesLoading: false,
        userAiImages: [...state.userAiImages, ...action.images]
      };
    }

    case LOAD_MORE_USER_IMAGES: {
      return {
        ...state,
        userImagesLoading: true,
        userImagePage: state.userImagePage + 1
      };
    }

    case LOAD_MORE_USER_AI_IMAGES: {
      return {
        ...state,
        userAiImagesLoading: true,
        userAiImagePage: state.userAiImagePage + 1
      };
    }

    case CLOSE_IMAGE_LIBRARY: {
      return {
        ...state,
        userImagePage: 1,
        userImages: [],
        userAiImages: []
      };
    }

    case DELETE_USER_IMAGE_SUCCESS: {
      if (action.targetLibrary) {
        const targetLibrary = cloneDeep(state.userLibrary[action.targetLibrary]);
        targetLibrary.images = targetLibrary.images.filter(img => img.id !== action.imageId);
        targetLibrary.imagesSortedByLastUsed = targetLibrary.imagesSortedByLastUsed.filter(
          img => img.id !== action.imageId
        );
        targetLibrary.sourceAssets = targetLibrary.sourceAssets.filter(img => img.id !== action.imageId);

        return {
          ...state,
          userLibrary: {
            ...state.userLibrary,
            [action.targetLibrary]: targetLibrary
          }
        };
      } else {
        // TODO Remove this block once new image library is released - maintained for legacy image library UI
        const userImages = state.userImages.filter(img => img.id !== action.imageId);
        const userAiImages = state.userAiImages.filter(img => img.id !== action.imageId);
        const userImageAssets = state.userImageAssets.filter(img => img.id !== action.imageId);
        const userAiImageAssets = state.userAiImageAssets.filter(img => img.id !== action.imageId);

        return {
          ...state,
          userImages,
          userAiImages,
          userImageAssets,
          userAiImageAssets
        };
      }
    }

    case SEARCH_IMAGE: {
      if (!action.offset) {
        return {
          ...state,
          search: {
            ...state.search,
            query: action.searchSource === 'videoscribe' ? action.query : state.search.query,
            results: action.query === state.search.query ? state.search.results : null
          },
          searchNounProject: {
            ...state.searchNounProject,
            query: action.searchSource === 'noun-project' ? action.query : state.searchNounProject.query,
            results: action.query === state.searchNounProject.query ? state.searchNounProject.results : null
          }
        };
      }
      return {
        ...state,
        search: {
          ...state.search
        }
      };
    }

    case SEARCH_IMAGE_SUCCESS: {
      const searchResultKey = action.searchSource === 'videoscribe' ? 'search' : 'searchNounProject';
      return {
        ...state,
        [searchResultKey]: {
          ...state[searchResultKey],
          results: [...(state[searchResultKey].results || []), ...action.results],
          totalImages: action.totalImages,
          query: action.query,
          next: action.next
        }
      };
    }

    case SEARCH_IMAGE_FAILURE:
      if (action.searchSource === 'videoscribe') {
        return {
          ...state,
          search: {
            ...state.search
          }
        };
      } else {
        return {
          ...state,
          searchNounProject: {
            ...state.search
          }
        };
      }

    case SET_SHOW_ELEMENT_MODAL: {
      if (action.replaceElement) {
        const { libraries: originalLibraries } = state;
        const libraries = cloneDeep(originalLibraries);
        libraries.forEach(lib => {
          delete lib.cursor;
        });
        return {
          ...state,
          libraries
        };
      }
      return state;
    }

    case PREPEND_USER_IMAGES_LIST: {
      const userUploadLibrary = cloneDeep(state.userLibrary[action.targetLibrary]);

      userUploadLibrary.images = [...action.images, ...userUploadLibrary.images];
      userUploadLibrary.imagesSortedByLastUsed = [...action.images, ...userUploadLibrary.imagesSortedByLastUsed];
      userUploadLibrary.sourceAssets = [...action.images, ...userUploadLibrary.sourceAssets];

      return {
        ...state,
        userLibrary: {
          ...state.userLibrary,
          [action.targetLibrary]: userUploadLibrary
        }
      };
    }

    case ADD_USER_IMAGE_TO_SCRIBE: {
      const { imageLibrarySource, actionTime, imageId } = action;

      if (imageLibrarySource) {
        const updateTime = actionTime.toISOString();
        const library = cloneDeep(state.userLibrary[imageLibrarySource]);
        const sourceAsset = library.sourceAssets.find(asset => asset.projectAssetId === imageId);
        const imageLibAsset = library.images.find(asset => asset.projectAssetId === imageId);
        const imagesSortedByLastUsedAsset = library.imagesSortedByLastUsed.find(
          asset => asset.projectAssetId === imageId
        );

        if (sourceAsset) {
          sourceAsset.lastUsed = updateTime;
        }

        if (imageLibAsset) {
          imageLibAsset.lastUsed = updateTime;
        }

        if (imagesSortedByLastUsedAsset) {
          imagesSortedByLastUsedAsset.lastUsed = updateTime;
        }

        const resortedImages = library.imagesSortedByLastUsed.toSorted((a, b) => {
          const dateA = new Date(a.lastUsed || a.createdDate);
          const dateB = new Date(b.lastUsed || b.createdDate);
          return dateB.getTime() - dateA.getTime();
        });

        library.imagesSortedByLastUsed = resortedImages;

        return {
          ...state,
          userLibrary: {
            ...state.userLibrary,
            [imageLibrarySource]: library
          }
        };
      }

      return state;
    }

    case START_REPLACE_IMAGE: {
      return {
        ...state,
        replacingImageElementId: action.elementId
      };
    }

    case CLEAR_REPLACE_IMAGE: {
      return {
        ...state,
        replacingImageElementId: null
      };
    }

    case ADD_USER_IMAGE_TO_SCRIBE: {
      const { imageLibrarySource, actionTime, imageId } = action;

      if (imageLibrarySource) {
        const updateTime = actionTime.toISOString();
        const library = cloneDeep(state.userLibrary[imageLibrarySource]);
        const sourceAsset = library.sourceAssets.find(asset => asset.projectAssetId === imageId);
        const imageLibAsset = library.images.find(asset => asset.projectAssetId === imageId);
        const imagesSortedByLastUsedAsset = library.imagesSortedByLastUsed.find(
          asset => asset.projectAssetId === imageId
        );

        if (sourceAsset) {
          sourceAsset.lastUsed = updateTime;
        }

        if (imageLibAsset) {
          imageLibAsset.lastUsed = updateTime;
        }

        if (imagesSortedByLastUsedAsset) {
          imagesSortedByLastUsedAsset.lastUsed = updateTime;
        }

        const resortedImages = library.imagesSortedByLastUsed.toSorted((a, b) => {
          const dateA = new Date(a.lastUsed || a.createdDate);
          const dateB = new Date(b.lastUsed || b.createdDate);
          return dateB.getTime() - dateA.getTime();
        });

        library.imagesSortedByLastUsed = resortedImages;

        return {
          ...state,
          userLibrary: {
            ...state.userLibrary,
            [imageLibrarySource]: library
          }
        };
      }

      return state;
    }

    default:
      return state;
  }
}
