import Cookies from 'js-cookie';

export const WEBSITE_UPGRADE_KEY = 'videoscribe-website-upgrade';

export function hasUpgradedOnWebsite(userId: string): boolean {
  try {
    const hasUpgraded = !!Cookies.get(`${userId}-${WEBSITE_UPGRADE_KEY}`);
    return hasUpgraded;
  } catch (error) {
    return false;
  }
}

export function getUpgradedSubscriptionTier(userId: string): string | undefined {
  try {
    const cookieValue = Cookies.get(`${userId}-${WEBSITE_UPGRADE_KEY}`);
    return cookieValue === 'true' ? undefined : cookieValue;
  } catch (error) {
    return undefined;
  }
}

export async function clearUpgradedOnWebsiteStorage(userId?: string) {
  if (userId) {
    Cookies.remove(`${userId}-${WEBSITE_UPGRADE_KEY}`);
  }
}
