import { EXPANDED_CANVAS_SIZE } from 'js/editor/EditorCanvas/ProjectCanvas';
import { push, replace } from 'connected-react-router';
import { GET_USER_FONTS_SUCCESS, getUserFonts, loadUserFonts } from 'js/actionCreators/fontActions';
import { uploadImageAssetOnLoad } from 'js/actionCreators/imagesActions';
import { loadCursorsSuccess } from 'js/actionCreators/loadCursorsSuccessAction';
import { showElementsPanel } from 'js/actionCreators/uiActions';
import isEqual from 'lodash.isequal';
import {
  trackOpenScribe,
  trackSelectAll,
  trackSetActiveElementsVisibility,
  UpgradeSubscriptionClickedEventTrigger
} from 'js/actionCreators/trackingActions';
import {
  EMPHASIS_ANIMATION_DURATION_DOCUMENT_KEY,
  ENTRANCE_ANIMATION_DURATION_DOCUMENT_KEY,
  EXIT_ANIMATION_DURATION_DOCUMENT_KEY
} from 'js/config/animationOptions';
import { FREE_ELEMENTS_LIMIT, MAX_CHARS_IN_TEXT, MAX_SCROLL_MULTIPLIER, canvasSizes } from 'js/config/defaults';
import { ROUTE_CODES } from 'js/config/routes';
import { getEditorCanvas } from 'js/editor/EditorCanvas/EditorCanvas';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import { allCamerasPinned } from 'js/shared/allCamerasPinned';
import { animationTypeHasNoDuration } from 'js/shared/helpers/animationTypeHasNoDuration';
import { selectionContainsStartingCamera } from 'js/shared/helpers/elementsPanelHelper';
import { getActiveScene, getHidableElements } from 'js/shared/helpers/scenesHelpers';
import { rendererFatalFailure, rendererLog } from 'js/shared/lib/CloudRenderer';
import { getProviderForType } from 'js/shared/providers';
import cloneDeep from 'lodash.clonedeep';
import { call, delay, fork, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { updateBitmapFonts } from 'js/actionCreators/fontActions';
import { LeftHandPanel } from 'js/types';

import {
  EDITOR_CUT,
  EDITOR_PASTE,
  EDITOR_REDO,
  EDITOR_UNDO,
  editorCopy as copyAction,
  editorRedoSuccess,
  editorSetEditPanelMode,
  editorUndoSuccess,
  toggleFirstArrowPress
} from '../actionCreators/editorActions';
import {
  ADD_SCRIBE_REQUESTED,
  CHANGE_SCENE_ORDER,
  CREATE_ELEMENT,
  CREATE_IMAGE_ELEMENT,
  CREATE_SCENE,
  CREATE_SHAPE_ELEMENT,
  CREATE_TEXT_ELEMENT,
  DELETE_ACTIVE_ELEMENT,
  DELETE_MULTIPLE_ELEMENTS_BY_ID,
  DELETE_SCRIBE,
  DELETE_SELECTED_SCENES,
  DUPLICATE_SELECTED_SCENES,
  LOAD_CURSORS,
  LOAD_SCRIBES,
  LOAD_SCRIBE_BY_ID,
  LOAD_SCRIBE_BY_ID_FAILED,
  LOAD_SCRIBE_BY_ID_SUCCESS,
  LOCK_ACTIVE_ELEMENTS,
  LOCK_ELEMENTS,
  MOVE_ELEMENT_ON_TIMELINE,
  ON_MOVE_WITH_KEYS,
  PIN_ACTIVE_CAMERAS,
  PIN_CAMERAS,
  SELECT_ALL,
  SET_ACTIVE_ELEMENTS_VISIBILITY,
  SET_ELEMENTS_VISIBILITY,
  SET_ACTIVE_SCRIBE,
  SET_ELEMENTS_LOOPS,
  SET_ELEMENTS_TIMINGS,
  SORT_SCRIBES,
  TOGGLE_CAMERA_PINS,
  UNLOCK_ACTIVE_ELEMENTS,
  UNLOCK_ELEMENTS,
  UNPIN_ACTIVE_CAMERAS,
  UNPIN_CAMERAS,
  UPDATE_SCENE,
  UPDATE_SCENE_ELEMENTS,
  UPDATE_SCENE_THUMBNAIL,
  UPDATE_SCRIBE_CURSOR,
  UPDATE_SCRIBE_FAILED,
  UPDATE_SCRIBE_SUCCESS,
  addScribeSuccess,
  addToActiveElements,
  createElement as createElementAction,
  createTextElement as createTextElementAction,
  deleteMultipleElementsById as deleteMultipleElementsByAction,
  deleteScribeSuccess,
  deleteSelectedScenes as deleteSelectedScenesAction,
  loadScribeById as loadScribeByIdAction,
  loadScribeByIdFailed,
  loadScribeByIdSuccess,
  loadScribes as loadScribesAction,
  loadScribesSuccess,
  lockElements as lockElementsAction,
  pinCameras as pinCamerasAction,
  prepScribeForEditorSuccess,
  setActiveElements,
  setActiveScene,
  setSelectedScenes,
  setSelectedScenes as setSelectedScenesAction,
  unlockElements as unlockElementsAction,
  unpinCameras as unpinCamerasAction,
  updateScribe as updateScribeAction,
  setSelectedAudioClip,
  SET_SELECTED_AUDIO_CLIP
} from '../actionCreators/scribeActions';
import { upgradeAccount } from '../actionCreators/userAccountAction';
import { PIN_ALL_CAMERA_BY_DEFAULT } from '../config/config';
import {
  applicationFontsLoaded as applicationFontsLoadedAction,
  hideModal,
  setEditorLoadingMessage,
  showLeftHandPanel,
  showCallout,
  showError
} from '../actionCreators/uiActions';
import {
  ACCOUNT_TYPES,
  ALLOWED_CURSORS,
  ALLOWED_DRAG_CURSORS,
  ALLOWED_ERASE_CURSORS,
  CAMERA_EASING_NONE,
  DEFAULT_TEXT_ELEMENT_COPY,
  EMPHASIS_TWEEN_DOCUMENT_KEY,
  FILE_CONTENT_TYPES,
  IMAGE_PROVIDER_TYPES,
  PROJECT_DELETED_FLASH_TITLE
} from '../config/consts';
import { sendErrorToSentry } from '../logging';
import ScribeModel from '../models/ScribeModel';
import ScribeShapeElementModel from '../models/ScribeShapeElementModel';
import ScribeTextElementModel from '../models/ScribeTextElementModel';
import { appServices } from '../shared/helpers/app-services/AppServices';
import {
  createProject,
  deleteProject,
  getProject,
  getProjectList
} from '../shared/helpers/app-services/AppServices/projects';
import { getCenterOfViewport } from '../shared/helpers/element';
import { getScribeLink } from '../shared/helpers/linkHelper';
import preloadScribeFonts, { loadAllSelectableFonts } from '../shared/helpers/preloadScribeFonts';
import { getElementIndexById } from '../shared/helpers/scribeHelper';
import { getTextDimensions } from '../shared/helpers/text-draw';

import { loadUserFontsSaga } from './fontSagas';
import applyImageHeightAndWidth from './sagaHelpers/applyImageHeightAndWidth';
import checkScribeFonts from './sagaHelpers/checkScribeFonts';
import createNewScene from './sagaHelpers/createNewScene';
import { cursorAllowedListFilter } from './sagaHelpers/cursorAllowedListFilter';
import deleteScenesFromScribe from './sagaHelpers/deleteScenesFromScribe';
import { duplicateScenesIntoScribe } from './sagaHelpers/duplicateScenesIntoScribe';
import { findIndexOfLastActiveElement } from './sagaHelpers/findIndexOfLastActiveElement';
import multiDragAwareReorder from './sagaHelpers/multiDragAwareReorder';
import pasteElementsIntoScribe from './sagaHelpers/pasteElementsIntoScribe';
import pasteScenesIntoScribe from './sagaHelpers/pasteScenesIntoScribe';
import duplicateScribeSagas from './scribeSagas/duplicateScribeSagas';
import { processImageFileOnLoad } from './sagaHelpers/processImageFileOnLoad';
import processImageOnChange from './sagaHelpers/processImageOnChange';
import replaceActiveScene from './sagaHelpers/replaceActiveScene';
import { appliesBrokenImage } from './sagaHelpers/replaceImageUrl';
import sanitiseScribe from './sagaHelpers/sanitiseScribe';
import { updateElementsInScene } from './sagaHelpers/updateElementsInScene';
import { isValidClipboardObject, writeToClipboard } from './sagaHelpers/writeToClipboard';
import { getActiveElements, getScribeById, getUndoObjects, getUserAccountType } from './selectors';
import updateProjectTitleSaga from './updateProjectTitle';
import { updateCameraCoverage } from './sagaHelpers/updateCameraCoverage';

export function* addScribe(action) {
  try {
    const model = new ScribeModel({
      title: action.title,
      canvasSize: action.canvasSize,
      createdOn: action.date,
      updatedOn: action.date,
      source: action.source,
      rawElements: action.elements,
      thumbnailImage: action.thumbnail,
      settings: action.settings,
      cursor: action.cursor,
      scenes: action.scenes,
      audioClips: action.audioClips,
      pinAllCameras: PIN_ALL_CAMERA_BY_DEFAULT,
      projectAudioLayerIds: action.projectAudioLayerIds
    });

    const scribe = yield call(createProject, model);

    yield put(addScribeSuccess(scribe));
    yield put(hideModal(scribe.id));

    if (action.replaceHistory) {
      yield put(replace(getScribeLink(scribe)));
    } else {
      yield put(push(getScribeLink(scribe)));
    }
  } catch (error) {
    sendErrorToSentry(error);
    yield put(showError(error));
  }
}

function* createTextElement(action) {
  const { scribeId, canvasSize, initialText } = action;
  const scribe = yield select(getScribeById, scribeId);
  const scribeTextSettings = scribe.settings?.textStylingConfig;
  const text = initialText?.substring(0, MAX_CHARS_IN_TEXT) || DEFAULT_TEXT_ELEMENT_COPY;
  const elem = new ScribeTextElementModel({
    canvasSize,
    text,
    ...(scribeTextSettings && {
      align: scribeTextSettings.align,
      font: scribeTextSettings.font,
      fontWeight: scribeTextSettings.fontWeight,
      fontStyle: scribeTextSettings.fontStyle,
      fill: scribeTextSettings.fill,
      opacity: scribeTextSettings.opacity,
      fontSize: scribeTextSettings.fontSize
    })
  });

  const { height, width } = getTextDimensions(elem);

  elem.scaleX = 1;
  elem.scaleY = 1;

  elem.updateAnimationTime = true;
  elem.isNew = true;
  elem.height = height;
  elem.width = width;

  yield put(createElementAction(elem, scribeId, canvasSize));

  const existingTextElements = scribe.elements.filter(element => element.type === 'Text');
  yield put(updateBitmapFonts([...existingTextElements, elem]));
}

function* createShapeElement(action) {
  const { scribeId, shapeType, canvasSize } = action;
  const scribe = yield select(getScribeById, scribeId);
  const elem = new ScribeShapeElementModel({ shapeType, canvasSize });

  yield put(createElementAction(elem, scribeId, canvasSize));
  yield put(push(getScribeLink(scribe)));
}

function* createImageElement(action) {
  const { scribeId, canvasSize, initialData = {} } = action;
  const newElement = Object.assign(new ScribeImageElementModel({ canvasSize }), initialData);

  yield processImageOnChange(newElement);

  yield put(createElementAction(newElement, scribeId, canvasSize));
}

function* createElement(action) {
  const { scribeId } = action;

  const state = yield select();

  const activeScribe = getScribeById(state, scribeId);
  const newActiveScribe = cloneDeep(activeScribe);
  const newElement = cloneDeep(action.element);
  const activeScene = newActiveScribe.scenes.find(scene => scene.active);

  // Add the elements to the scribe
  newActiveScribe.elements.push(newElement);

  const indexOfLastActiveElement = findIndexOfLastActiveElement(state.scribes.activeElements, activeScene?.elementIds);

  // Add element id to active scene
  if (typeof indexOfLastActiveElement === 'number') {
    activeScene.elementIds.splice(indexOfLastActiveElement + 1, 0, newElement.id);
  } else {
    activeScene.elementIds = [...activeScene.elementIds, newElement.id];
  }
  const { x, y } = getCenterOfViewport(newElement.width * newElement.scaleX, newElement.height * newElement.scaleY);
  newElement.x = x;
  newElement.y = y;
  // Update the scribe
  yield put(updateScribeAction(newActiveScribe, false, [newElement.id]));
}

function* userCanAddElements(scribeId, clipboard) {
  const scribe = yield select(state => getScribeById(state, scribeId));
  const elementsInCurrentScribe = scribe.elements.length;
  const elementsInClipboard = clipboard.data.length;

  const userAccountType = yield select(getUserAccountType);

  return elementsInCurrentScribe + elementsInClipboard <= FREE_ELEMENTS_LIMIT || userAccountType !== ACCOUNT_TYPES.FREE;
}

function* handleEditorPaste({ scribeId, inPlace, intialContent }) {
  if (!intialContent) return;
  const scribe = yield select(getScribeById, scribeId);
  try {
    const contentInObj = JSON.parse(intialContent);
    if (!isValidClipboardObject(contentInObj)) {
      return yield call(pasteText, scribe, intialContent);
    }
    yield call(pasteSceneOrElement, scribe, contentInObj, inPlace);
  } catch (e) {
    if (e instanceof SyntaxError) {
      yield call(pasteText, scribe, intialContent);
    }
  }
}

function* pasteSceneOrElement(scribe, clipboardPayload, inPlace) {
  if (clipboardPayload.type === 'scene') {
    if (clipboardPayload.metadata.canvasSize !== scribe.canvasSize) return;

    const selectedSceneIds = yield select(state => state.scribes.selectedSceneIds);
    const { leftHandPanel } = yield select(state => state.ui);
    const { updatedScribe, lastSceneId } = pasteScenesIntoScribe(scribe, clipboardPayload, selectedSceneIds);

    if (leftHandPanel !== LeftHandPanel.SCENES) yield put(showLeftHandPanel(LeftHandPanel.SCENES));
    yield put(updateScribeAction(updatedScribe));
    yield put(setActiveScene(scribe.id, lastSceneId));

    const isContainTextElements = clipboardPayload.data.some(scene => {
      return scene.elementIds.some(elementId => {
        return clipboardPayload.metadata.elements.find(element => element.tempId === elementId).type === 'Text';
      });
    });
    if (isContainTextElements) {
      const updatedTextElements = updatedScribe.elements.filter(element => element.type === 'Text');
      yield put(updateBitmapFonts(updatedTextElements));
    }
  }

  if (clipboardPayload.type === 'element') {
    const userCanAddElement = yield call(userCanAddElements, scribe.id, clipboardPayload);
    if (userCanAddElement) {
      const activeElementIds = yield select(getActiveElements);
      const { scribe: updatedScribe, newElementIds, clipboard: updatedClipboardPayload } = pasteElementsIntoScribe(
        scribe,
        clipboardPayload,
        activeElementIds,
        inPlace || clipboardPayload.metadata.wasCut
      );
      yield put(updateScribeAction(updatedScribe, false, newElementIds));
      yield call(writeToClipboard, updatedClipboardPayload);
      yield put(setSelectedScenes([]));

      const isContainTextElements = clipboardPayload.data.some(element => element.type === 'Text');
      if (isContainTextElements) {
        const updatedTextElements = updatedScribe.elements.filter(element => element.type === 'Text');
        yield put(updateBitmapFonts(updatedTextElements));
      }
    } else {
      yield put(upgradeAccount(UpgradeSubscriptionClickedEventTrigger.ELEMENT_LIMIT));
    }
  }
}

function* pasteText(scribe, text) {
  const canvasSize = canvasSizes[scribe.canvasSize];
  yield put(createTextElementAction(scribe.id, canvasSize, text));
}

function* deleteMultipleElementsById({ elements: elementIds, scribeId }) {
  const activeScribe = yield select(getScribeById, scribeId);
  const newActiveScribe = cloneDeep(activeScribe);
  // elements might be a string, so convert it to an array
  [].concat(elementIds).forEach(element => {
    const indexToRemove = getElementIndexById(element, newActiveScribe);
    if (indexToRemove !== -1) {
      newActiveScribe.elements.splice(indexToRemove, 1);
    }
  });

  newActiveScribe.scenes.forEach(scene => {
    scene.elementIds = scene.elementIds.filter(elId => !elementIds.includes(elId));
  });

  yield put(updateScribeAction(newActiveScribe));
}

function* cut({ scribeId }) {
  const scribe = yield select(getScribeById, scribeId);
  const activeElementIds = yield select(getActiveElements);
  let displayNoDeleteMessage = false;
  const filteredElements = activeElementIds.filter(element => {
    if (scribe.scenes.some(scene => scene.elementIds[0] === element)) {
      displayNoDeleteMessage = true;
      return false;
    }
    return true;
  });

  if (displayNoDeleteMessage) {
    yield put(
      showError({
        message: "A scene's starting camera cannot be deleted or rearranged."
      })
    );
  }

  const selectedSceneIds = yield select(state => state.scribes.selectedSceneIds);
  const hasScenesSelected = !!selectedSceneIds.length;
  const hasElementsSelected = !!filteredElements.length;

  if (
    !scribe ||
    (!hasScenesSelected && !hasElementsSelected) ||
    (hasScenesSelected && !(selectedSceneIds.length < scribe.scenes.length))
  )
    return;

  yield put(copyAction(scribeId, true));

  if (hasScenesSelected) {
    yield put(deleteSelectedScenesAction(scribeId));
  } else {
    yield put(setActiveElements([]));
    yield put(deleteMultipleElementsByAction(filteredElements, scribeId));
  }
}

async function clearCanvasSelectionReadyForUpdates() {
  const editorCanvas = getEditorCanvas();
  if (editorCanvas) {
    await editorCanvas.discardActiveObjectAndWaitForNextEventLoop();
  }
}

function areSameActiveElements(prevActiveElements = [], newActiveElements = []) {
  return !isEqual(prevActiveElements, newActiveElements);
}

function isTextElementsChanged(newTextElements, prevTextElements) {
  if (newTextElements.length !== prevTextElements.length) return true;
  for (let i = 0; i < newTextElements.length; i++) {
    const prevTextElement = prevTextElements.find(e => e.id === newTextElements[i].id);
    if (prevTextElement && prevTextElement.text === newTextElements[i].text) continue;
    return true;
  }
  return false;
}

function* undo(action) {
  const { scribeId } = action;
  const { activeScribe, activeScribePast, activeScribeFuture } = yield select(getUndoObjects, scribeId);

  // Do nothing if there is nothing in the undo history
  if (!activeScribePast.length) return;

  yield call(clearCanvasSelectionReadyForUpdates);

  const newFuture = [cloneDeep(activeScribe), ...activeScribeFuture];
  const newActiveScribe = activeScribePast[activeScribePast.length - 1];

  const newPast = activeScribePast.slice(0, activeScribePast.length - 1);

  const newSelectedAudioClipId = newActiveScribe.selectedAudioClipId;
  const newSelectedAudioClip = newActiveScribe.audioClips?.find(audioClip => audioClip.id === newSelectedAudioClipId);
  yield put(editorUndoSuccess(newPast, newFuture));
  yield put(updateScribeAction(newActiveScribe, true));

  const areActiveElementsChanged = areSameActiveElements(
    (activeScribe.selectedElementIds, newActiveScribe.selectedElementIds)
  );
  if (areActiveElementsChanged) {
    yield put(setActiveElements(newActiveScribe.selectedElementIds));
  }
  yield put(setSelectedAudioClip(newSelectedAudioClip));

  const newTextElements = newActiveScribe.elements.filter(e => e.type === 'Text');
  const prevTextElements = activeScribe.elements.filter(e => e.type === 'Text');
  const isNeedUpdateBitmapFonts = isTextElementsChanged(newTextElements, prevTextElements);
  if (isNeedUpdateBitmapFonts) yield put(updateBitmapFonts(newTextElements));
}

function* redo(action) {
  const { scribeId } = action;
  const { activeScribe, activeScribePast, activeScribeFuture } = yield select(getUndoObjects, scribeId);

  // Do nothing if there is nothing in the undo future
  if (!activeScribeFuture.length) return;

  yield call(clearCanvasSelectionReadyForUpdates);

  const newActiveScribe = activeScribeFuture[0];
  const newFuture = activeScribeFuture.slice(1);
  const newPast = [...activeScribePast, cloneDeep(activeScribe)];

  const nextSelectedAudioClipId = newActiveScribe.nextSelectedAudioClipId;
  const newSelectedAudioClip = newActiveScribe.audioClips?.find(audioClip => audioClip.id === nextSelectedAudioClipId);

  yield put(editorRedoSuccess(newPast, newFuture));
  yield put(updateScribeAction(newActiveScribe, true));

  const areActiveElementsChanged = areSameActiveElements(
    (activeScribe.selectedElementIds, newActiveScribe.nextSelectedElementIds)
  );
  if (areActiveElementsChanged) {
    yield put(setActiveElements(newActiveScribe.nextSelectedElementIds));
  }
  yield put(setSelectedAudioClip(newSelectedAudioClip));

  const newTextElements = newActiveScribe.elements.filter(e => e.type === 'Text');
  const prevTextElements = activeScribe.elements.filter(e => e.type === 'Text');
  const isNeedUpdateBitmapFonts = isTextElementsChanged(newTextElements, prevTextElements);
  if (isNeedUpdateBitmapFonts) yield put(updateBitmapFonts(newTextElements));
}

function* moveElementOnTimeline({ scribeId, source, destination, draggableId }) {
  const activeScribe = yield select(getScribeById, scribeId);
  const activeElements = yield select(getActiveElements);
  const activeScene = activeScribe.scenes.find(scene => scene.active);
  const elementIdsList = activeScene.elementIds.map(id => ({ id }));

  const newActiveScribe = cloneDeep(activeScribe);
  const newActiveScene = newActiveScribe.scenes.find(scene => scene.active);

  const containsStartingCamera = selectionContainsStartingCamera(activeScribe, activeElements);

  let startingCameraId;

  if (containsStartingCamera) {
    const activeSceneElementIds = activeScene.elementIds;
    activeElements.sort((a, b) => activeSceneElementIds.indexOf(a) - activeSceneElementIds.indexOf(b));

    startingCameraId = activeElements.shift();
  }

  const updatedElements = multiDragAwareReorder(elementIdsList, source, destination, draggableId, activeElements);

  newActiveScene.elementIds = updatedElements.map(element => {
    return element.id;
  });

  if (startingCameraId) {
    yield put(addToActiveElements(startingCameraId));
  }
  yield put(updateScribeAction(newActiveScribe));
}

function* deleteActiveElement({ scribeId }) {
  const activeScribe = yield select(getScribeById, scribeId);
  const activeElements = yield select(getActiveElements);
  let displayNoDeleteMessage = false;
  const filteredElements = activeElements.filter(element => {
    if (activeScribe.scenes.some(scene => scene.elementIds[0] === element)) {
      displayNoDeleteMessage = true;
      return false;
    }
    return true;
  });

  if (displayNoDeleteMessage) {
    yield put(
      showError({
        message: "A scene's starting camera cannot be deleted or rearranged."
      })
    );
  }
  yield put(deleteMultipleElementsByAction(filteredElements, scribeId));
}

const createHomepageProject = projectData => ({
  id: projectData.id,
  title: projectData.title,
  createdOn: new Date(projectData.createdDate),
  updatedOn: new Date(projectData.updatedDate ?? projectData.createdDate),
  canvasSize: projectData.canvasSize,
  thumbnailImage: projectData.thumbnail,
  viewOnly: projectData.viewOnly
});

function* loadScribes(action = {}) {
  const userAccountType = yield select(getUserAccountType);
  const { sortBy = 'modifiedDate', sortOrder = 'desc' } = action;
  const queryParams = new URLSearchParams({
    sortBy,
    sortOrder,
    trimmedContent: 'true'
  });
  try {
    const PAGE_SIZE = 50;
    const allProjects = [];
    let finished = false;
    let offset = 0;
    while (!finished) {
      queryParams.set('limit', PAGE_SIZE);
      queryParams.set('offset', offset);
      const page = yield call(getProjectList, appServices.exchangeCognitoTokenForSparkolToken, queryParams);
      const projects = page.map(project => {
        if (userAccountType && userAccountType !== ACCOUNT_TYPES.FREE) {
          // The view only flag from the API is determined by a user's session and when a user has upgraded the
          // session isn't renewed until they log out and log in again.
          // This ensures when the user upgrades they are able to edit their scribes in their current session
          project.viewOnly = false;
        }
        return createHomepageProject(project);
      });
      allProjects.push(...projects);
      offset += PAGE_SIZE;
      finished = projects.length < PAGE_SIZE;
    }

    yield put(loadScribesSuccess(allProjects));
  } catch (error) {
    sendErrorToSentry(error);
    yield put(showError(error));
  }
}

function* deleteScribe(action) {
  try {
    yield call(deleteProject, action.scribe, appServices.exchangeCognitoTokenForSparkolToken);
    yield put(deleteScribeSuccess(action.scribe.id, action.scribe.title));
    yield put(loadScribesAction());
    yield put(
      showCallout({
        title: PROJECT_DELETED_FLASH_TITLE,
        details: action.scribe.title
      })
    );
  } catch (error) {
    sendErrorToSentry(error);
    yield put(showError(error));
  }
}

function* sortScribes(action) {
  try {
    let sortBy = 'title';
    let sortOrder;
    switch (action.sortOrder) {
      case 'alphaAscend':
        sortOrder = 'asc';
        break;
      case 'alphaDescend':
        sortOrder = 'desc';
        break;
      default:
        sortBy = 'modifiedDate';
        sortOrder = 'desc';
    }
    const query = {
      sortBy,
      sortOrder
    };

    yield call(loadScribes, { query });
  } catch (error) {
    sendErrorToSentry(error);
    yield put(showError(error));
  }
}

export function* loadScribeById({ scribeId }) {
  try {
    rendererLog(`REQUEST: project data for project id: `, scribeId);
    const scribeObject = yield call(getProject, scribeId, appServices.exchangeCognitoTokenForSparkolToken);
    rendererLog(`SUCCESS: project data for project id: `, scribeId);

    const userAccountType = yield select(getUserAccountType);

    if (userAccountType && userAccountType !== ACCOUNT_TYPES.FREE) {
      // The view only flag from the API is determined by a user's session and when a user has upgraded the
      // session isn't renewed until they log out and log in again.
      // This ensures when the user upgrades they are able to edit their scribes in their current session
      scribeObject.viewOnly = false;
    }

    const scribe = yield call(ScribeModel.fromObject, scribeObject);

    yield put(getUserFonts(scribeId));
    yield take(GET_USER_FONTS_SUCCESS);
    yield put(loadUserFonts(scribe));

    if (import.meta.env.VITE_DEBUG_RENDERING) {
      yield call(preloadScribeFonts, scribe);
    }

    yield put(loadScribeByIdSuccess({ scribe }));
    yield put(trackOpenScribe(scribeId));
  } catch (error) {
    rendererFatalFailure(`Project id: ${scribeId} - Failed to load data: ${error.message}`);
    yield put(loadScribeByIdFailed({ scribeId, error }));
    console.error(error);
    sendErrorToSentry(error);
  }
}

export function* loadCursors() {
  rendererLog(`REQUEST: loading cursors (hands)`);
  const cursors = yield call(appServices.getCursors);
  rendererLog(`SUCCESS: loading cursors (hands)`);

  const allowedCursors = cursors.filter(cursor => cursorAllowedListFilter(cursor, ALLOWED_CURSORS));

  const allowedEraseCursors = cursors.filter(cursor => cursorAllowedListFilter(cursor, ALLOWED_ERASE_CURSORS));

  const allowedDragCursors = cursors.filter(cursor => cursorAllowedListFilter(cursor, ALLOWED_DRAG_CURSORS));

  yield put(loadCursorsSuccess({ allowedCursors, allowedEraseCursors, allowedDragCursors }));
}

function* updateScribeCursor({ scribeId, cursorId }) {
  const activeScribe = yield select(state => getScribeById(state, scribeId));
  const updatedScribe = {
    ...activeScribe,
    cursor: cursorId
  };
  yield put(updateScribeAction(updatedScribe));
}

function* updateElementsLock({ type, elementIds, scribeId }) {
  const lockFlag = type === LOCK_ELEMENTS;
  const elementsToUpdate = Array.isArray(elementIds) ? elementIds : [elementIds];

  const scribe = yield select(state => getScribeById(state, scribeId));

  if (scribe) {
    const scribeClone = cloneDeep(scribe);
    scribeClone.elements.forEach(el => {
      if (elementsToUpdate.includes(el.id)) {
        el.locked = lockFlag;
      }
    });

    yield put(updateScribeAction(scribeClone));
  }
}

function* updateLockOnActiveElements({ type, scribeId, eventTrigger }) {
  const actionCreator = type === LOCK_ACTIVE_ELEMENTS ? lockElementsAction : unlockElementsAction;
  const activeElements = yield select(getActiveElements);
  yield put(actionCreator(activeElements, scribeId, eventTrigger));
}

function* toggleActiveSceneCameraPins({ scribeId }) {
  const scribe = yield select(state => getScribeById(state, scribeId));

  if (scribe) {
    const scribeClone = cloneDeep(scribe);
    const scribeElements = scribeClone.elements;
    const allCameras = scribeElements.filter(element => element.type === 'Camera');

    const activeSceneCamerasPinned = allCamerasPinned(scribeClone);

    allCameras.forEach(el => {
      el.cameraPinned = !activeSceneCamerasPinned;
    });
    yield put(updateScribeAction(scribeClone, true));
  }
}

function* updateCamerasPinned({ type, elementIds, scribeId }) {
  const cameraPinFlag = type === PIN_CAMERAS;
  const elementsToUpdate = Array.isArray(elementIds) ? elementIds : [elementIds];

  const scribe = yield select(state => getScribeById(state, scribeId));

  if (scribe) {
    const scribeClone = cloneDeep(scribe);
    scribeClone.elements.forEach(el => {
      if (elementsToUpdate.includes(el.id)) {
        el.cameraPinned = cameraPinFlag;
      }
    });

    yield put(updateScribeAction(scribeClone));
  }
}

function* updateCamerasPinnedOnActiveElements({ type, scribeId, eventTrigger }) {
  const actionCreator = type === PIN_ACTIVE_CAMERAS ? pinCamerasAction : unpinCamerasAction;
  const activeElements = yield select(getActiveElements);
  yield put(actionCreator(activeElements, scribeId, eventTrigger));
}

function* updateElementsLoops({ scribeId, elementIds, property, value }) {
  const scribe = yield select(state => getScribeById(state, scribeId));
  const scribeClone = cloneDeep(scribe);
  scribeClone.elements.forEach(el => {
    if (elementIds.includes(el.id)) {
      el[property] = Math.round(value);
    }
  });
  yield put(updateScribeAction(scribeClone));
}

function* updateElementsTimings({ scribeId, elementIds, propertyValuePair }) {
  const scribe = yield select(state => getScribeById(state, scribeId));
  const scribeClone = cloneDeep(scribe);
  for (const property in propertyValuePair) {
    let value = propertyValuePair[property];
    if (property === EXIT_ANIMATION_DURATION_DOCUMENT_KEY) {
      scribeClone.elements.forEach(el => {
        if (elementIds.includes(el.id)) {
          if (!animationTypeHasNoDuration(el?.exitTween?.id)) {
            el[property] = value;
          }
        }
      });
    } else if (property === EMPHASIS_ANIMATION_DURATION_DOCUMENT_KEY) {
      scribeClone.elements
        .filter(element => elementIds.includes(element.id))
        .forEach(element => {
          element[property] = value;
          if (value === 0) {
            element[EMPHASIS_TWEEN_DOCUMENT_KEY] = undefined;
          }
        });
    } else {
      scribeClone.elements.forEach(el => {
        if (elementIds.includes(el.id)) {
          el[property] = value;
          if (el.type === 'Camera') {
            if (property === ENTRANCE_ANIMATION_DURATION_DOCUMENT_KEY) {
              if (value === 0) {
                el.easingType = CAMERA_EASING_NONE;
              }
            }
          }
        }
      });
    }
  }

  yield put(updateScribeAction(scribeClone));
}

function* waitForAutosaveQueueToFinish(scribeId) {
  let done = false;

  while (!done) {
    yield take(UPDATE_SCRIBE_SUCCESS);
    const queue = yield select(state => state.autoSave.pendingSaveQueue[scribeId]);
    if (queue && !queue.pendingSave && !queue.isSaving) {
      done = true;
    }
  }
}

function* prepScribeForEditor({ scribeId }) {
  const queue = yield select(state => state.autoSave.pendingSaveQueue[scribeId]);
  if (queue?.pendingSave || queue?.isSaving) {
    yield put(setEditorLoadingMessage('Waiting for previous changes to save'));
    const { timeout: pendingSaveTimeout } = yield race({
      timeout: delay(30000),
      saved: waitForAutosaveQueueToFinish(scribeId)
    });

    if (pendingSaveTimeout) {
      yield put(
        showError({
          message: 'There was an issue waiting for the previous changes to save',
          description: 'Please wait a moment and try loading your project again'
        })
      );
      return yield put(replace(ROUTE_CODES.HOME));
    }
  }

  yield put(loadScribeByIdAction(scribeId));

  const { response, failed } = yield race({
    failed: take(LOAD_SCRIBE_BY_ID_FAILED),
    response: take(LOAD_SCRIBE_BY_ID_SUCCESS)
  });

  if (failed) {
    let description;
    if (failed.error?.httpStatusCode === 404) {
      description = `That project is not in your list of available projects`;
    } else {
      description = `There was an issue loading your project. Error: ${failed.error.message}`;
    }

    yield put(
      showError({
        message: `Unable to load project with id ${scribeId}`,
        description
      })
    );
    yield put(replace(ROUTE_CODES.HOME));
    return;
  }

  const { scribe } = response;
  const isRenderer = (yield select(state => state.router?.location?.pathname || '')).includes('/render');

  let updatedScribe = scribe;

  if (!isRenderer) {
    let errorLoadingFontAssets = false;

    try {
      yield put(setEditorLoadingMessage('Loading your fonts'));
      yield put(getUserFonts(scribeId));
      yield take(GET_USER_FONTS_SUCCESS);
      yield call(loadUserFontsSaga, { scribe });
    } catch (error) {
      errorLoadingFontAssets = true;
      console.error(error);
      sendErrorToSentry(error);
    }

    if (!errorLoadingFontAssets) {
      const userFonts = yield select(state => state.fonts?.userFonts);
      updatedScribe = checkScribeFonts(updatedScribe, userFonts);
    } else {
      yield put(
        showError({
          message: 'There was an issue loading some of your fonts',
          description: 'Please try refreshing.'
        })
      );
    }

    const applicationFontsLoaded = yield select(state => state.ui?.applicationFontsLoaded);
    if (!applicationFontsLoaded) {
      yield put(setEditorLoadingMessage('Loading application fonts'));
      yield call(loadAllSelectableFonts);
      yield put(applicationFontsLoadedAction());
    }

    yield put(setEditorLoadingMessage('Loading project fonts'));
    yield call(preloadScribeFonts, updatedScribe);
  }

  const activeElements = yield select(getActiveElements);
  for (const scene of updatedScribe.scenes) {
    updatedScribe = yield updateCameraCoverage(activeElements, scene, updatedScribe);
  }
  const sanitisedScribe = sanitiseScribe(updatedScribe);

  const { elements = [] } = sanitisedScribe;
  const imageElements = elements.filter(el => el.type === 'Image');

  const assetIdsToSend = imageElements.reduce((acc, el) => {
    if (el.image.provider === IMAGE_PROVIDER_TYPES.USER && !!el.image.assetId && typeof el.image.assetId === 'number') {
      return [...acc, el.image.assetId];
    }
    return acc;
  }, []);

  let objectUrls;
  if (assetIdsToSend.length > 0) {
    try {
      yield put(
        setEditorLoadingMessage(
          `Requesting ${assetIdsToSend.length} project image${assetIdsToSend.length > 1 ? 's' : ''}`
        )
      );
      objectUrls = yield call(appServices.getAssetUrls, assetIdsToSend);
    } catch (error) {
      console.error(error);
      sendErrorToSentry(error);
    }
  }

  let errorLoadingAssets = false;
  let imageLoadedCount = 0;

  yield put(
    setEditorLoadingMessage(`Loading 1/${assetIdsToSend.length} project image${assetIdsToSend.length > 1 ? 's' : ''}`)
  );

  for (const imageEl of imageElements) {
    if (imageEl.image.provider === IMAGE_PROVIDER_TYPES.UPLOAD && imageEl._imageLoaded === false) {
      appliesBrokenImage(imageEl, imageEl.id);
    } else {
      try {
        const providerFn = getProviderForType(imageEl.image.provider);

        let url;

        if (imageEl.image.provider === IMAGE_PROVIDER_TYPES.USER && objectUrls?.length > 0) {
          const blobUrl = objectUrls.find(oUrl => oUrl.assetId.toString() === imageEl.image.assetId.toString()).blobUrl;

          if (blobUrl) {
            url = blobUrl;
          } else {
            url = yield call(providerFn, imageEl.image.assetId);
          }
        } else {
          url = yield call(providerFn, imageEl.image.assetId);
        }

        if (imageEl.image.contentType === FILE_CONTENT_TYPES.SVG) {
          const resp = yield call({ fn: window.fetch, context: window }, url);

          if (!resp.ok) throw new Error(`Unable to fetch image file. Status: ${resp.staus} ${resp.statusText}`);

          const blob = yield call({ fn: resp.blob, context: resp });
          const {
            file,
            recolorScribeElementProperties,
            viewboxScribeElementProperties,
            containsEmbeddedImage
          } = yield processImageFileOnLoad({
            file: blob,
            scribeElement: imageEl
          });

          imageEl._imageUrl = URL.createObjectURL(file);
          imageEl.containsEmbeddedImage = containsEmbeddedImage;

          if (recolorScribeElementProperties) {
            const { _recolorDefaults, recolorAvailable } = recolorScribeElementProperties;
            imageEl._recolorDefaults = _recolorDefaults;
            imageEl.recolorAvailable = recolorAvailable;
          }

          if (viewboxScribeElementProperties) {
            imageEl.viewboxAttributes = viewboxScribeElementProperties.viewboxAttributes;
          }

          if (!imageEl.height || !imageEl.width) {
            yield applyImageHeightAndWidth(imageEl, canvasSizes[scribe.canvasSize]);
          }
        } else {
          imageEl._imageUrl = url;
        }

        imageEl._imageLoaded = true;
        imageEl._imageLoading = false;
        imageLoadedCount += 1;
        yield put(
          setEditorLoadingMessage(
            `Loading ${imageLoadedCount}/${assetIdsToSend.length} project image${assetIdsToSend.length > 1 ? 's' : ''}`
          )
        );
      } catch (error) {
        imageEl._imageLoaded = false;
        imageEl._imageLoading = false;
        imageEl._imageUrl = null;
        errorLoadingAssets = true;
        console.error(error);
        sendErrorToSentry(error);
      }
    }
  }

  const libraryElements = isRenderer
    ? []
    : imageElements.filter(imageEl =>
        [IMAGE_PROVIDER_TYPES.LIBRARY, IMAGE_PROVIDER_TYPES.NOUN_PROJECT].includes(imageEl.image.provider)
      );

  yield put(uploadImageAssetOnLoad(libraryElements, scribeId));

  yield put(prepScribeForEditorSuccess(sanitisedScribe));

  if (errorLoadingAssets) {
    yield put(
      showError({
        message: 'There was an issue loading some of your images',
        description: 'If refreshing does not load the images correctly please try replacing the broken images.'
      })
    );
  }

  const textElements = elements.filter(el => el.type === 'Text');
  yield put(updateBitmapFonts(textElements));
}

function* updateSceneElements({ elements, scribeId, thumbnail, sceneId }) {
  const scribe = yield select(state => getScribeById(state, scribeId));
  const updatedScribe = cloneDeep(scribe);
  let newActiveScribe = yield updateElementsInScene(updatedScribe, elements);

  const sceneToUpdate = newActiveScribe.scenes.find(scene => scene.id === sceneId);
  sceneToUpdate.thumbnailImage = thumbnail;
  if (sceneToUpdate === newActiveScribe.scenes[0]) {
    newActiveScribe.thumbnailImage = thumbnail;
  }
  yield put(updateScribeAction(newActiveScribe));
  yield put(updateBitmapFonts(newActiveScribe.elements.filter(element => element.type === 'Text')));
}

function* updateSceneThumbnail({ scribeId, thumbnail }) {
  const scribe = yield select(state => getScribeById(state, scribeId));
  const updatedScribe = cloneDeep(scribe);
  const skipUndoHistory = true;

  const activeScene = updatedScribe.scenes.find(scene => scene.active);
  activeScene.thumbnailImage = thumbnail;
  if (activeScene === updatedScribe.scenes[0]) {
    updatedScribe.thumbnailImage = thumbnail;
  }
  yield put(updateScribeAction(updatedScribe, skipUndoHistory));
}

function* createScene({ scribeId }) {
  const scribe = yield select(state => getScribeById(state, scribeId));
  const sceneIds = yield select(state => state.scribes.selectedSceneIds);

  const { newActiveScribe, sceneId } = createNewScene(scribe, sceneIds);
  yield put(updateScribeAction(newActiveScribe));

  yield put(setSelectedScenesAction([sceneId]));
}

function* duplicateScenes({ scribeId }) {
  const sceneIds = yield select(state => state.scribes.selectedSceneIds);
  const scribe = yield select(state => getScribeById(state, scribeId));

  const { newActiveScribe, clonedSelectedScenes } = duplicateScenesIntoScribe(scribe, sceneIds);

  yield put(updateScribeAction(newActiveScribe));
  yield put(setSelectedScenesAction(clonedSelectedScenes));
}

function* deleteSelectedScenes({ scribeId }) {
  const scribe = yield select(state => getScribeById(state, scribeId));
  const sceneIdsToDelete = yield select(state => state.scribes.selectedSceneIds);

  const updatedScribe = deleteScenesFromScribe(scribe, sceneIdsToDelete);

  yield put(updateScribeAction(updatedScribe));
  yield put(setSelectedScenesAction());
}

function* updateScene({ scene, scribeId }) {
  const activeScribe = yield select(getScribeById, scribeId);

  const newActiveScribe = replaceActiveScene(scene, activeScribe);

  yield put(updateScribeAction(newActiveScribe));
}

function* changeSceneOrder({ scribeId, source, destination, draggableId }) {
  const scribe = yield select(getScribeById, scribeId);
  const selectedSceneIds = yield select(state => state.scribes.selectedSceneIds);

  const updatedScribe = cloneDeep(scribe);
  const { scenes } = updatedScribe;
  const updatedScenes = multiDragAwareReorder(scenes, source, destination, draggableId, selectedSceneIds);
  updatedScribe.scenes = updatedScenes;
  updatedScribe.thumbnailImage = updatedScenes[0].thumbnailImage;

  yield put(updateScribeAction(updatedScribe));
}

function* selectAll({ scribeId, eventTrigger }) {
  const state = yield select();

  const scribe = getScribeById(state, scribeId);

  const hasScenesSelected = state.scribes.selectedSceneIds.length > 0;

  if (hasScenesSelected) {
    const sceneIds = scribe.scenes.map(({ id }) => id);
    yield put(setSelectedScenes(sceneIds));
  } else {
    const scene = getActiveScene(scribe);
    if (!scene) {
      return;
    }
    const elementIds = scene.elementIds.concat();
    yield put(setActiveElements(elementIds));
  }

  yield put(trackSelectAll({ scribeId, eventTrigger, hasScenesSelected }));
}

function* setActiveElementsVisibility({ scribeId, eventTrigger, hidden }) {
  const scribe = yield select(getScribeById, scribeId);
  const activeElementIds = yield select(getActiveElements);
  if (!scribe || !activeElementIds) return;

  const elementsToUpdate = Array.isArray(activeElementIds) ? activeElementIds : [activeElementIds];

  const newScribe = cloneDeep(scribe);

  newScribe.elements.forEach(el => {
    if (elementsToUpdate.includes(el.id)) {
      el.hidden = hidden;
    }
  });

  yield put(
    trackSetActiveElementsVisibility({
      scribeId,
      elementIds: elementsToUpdate,
      eventTrigger,
      eventName: hidden ? 'Hide Element' : 'Unhide Element'
    })
  );
  yield put(updateScribeAction(newScribe));
}

function* setElementsVisibility({ scribeId, hidden }) {
  const scribe = yield select(getScribeById, scribeId);
  const hidableElements = getHidableElements(scribe);
  if (!scribe || hidableElements.length <= 0) return;

  const newScribe = cloneDeep(scribe);
  newScribe.elements.forEach(newElement => {
    const shouldHideElement = hidableElements.some(hideElement => hideElement.id === newElement.id);
    if (shouldHideElement) newElement.hidden = hidden;
  });
  yield put(updateScribeAction(newScribe));
}

function* updateScribeFailed({ error }) {
  yield put(
    showError({
      message: `${error}`,
      description: 'Please wait a moment and try loading your project again'
    })
  );
}

function* audioClipSelected({ selectedAudioClip }) {
  if (!selectedAudioClip) {
    yield put(editorSetEditPanelMode(null));
    return;
  }
  yield put(editorSetEditPanelMode('audio'));
  // yield put(updateScribe({ selectedAudioClipId: selectedAudioClip.assetId }));

  const isElementsPanelOpen = yield select(state => state.ui.showElementsPanel);
  if (isElementsPanelOpen) return;
  yield put(showElementsPanel(true));
}

// eslint-disable-next-line no-unused-vars
let delayedLastArrowCheckCall;

function* moveElementsWithKeys({ scribeId, axis, modifier }) {
  const scribe = yield select(getScribeById, scribeId);
  const activeElementIds = yield select(getActiveElements);

  if (!scribe || !activeElementIds) return;

  const elementsToUpdate = Array.isArray(activeElementIds) ? activeElementIds : [activeElementIds];
  const newScribe = cloneDeep(scribe);

  const canvasWidth = canvasSizes[scribe.canvasSize].width;
  const canvasHeight = canvasSizes[scribe.canvasSize].height;

  const expandedCanvasSize = EXPANDED_CANVAS_SIZE.width;
  const minCanvas = 0 - expandedCanvasSize * MAX_SCROLL_MULTIPLIER;
  const maxCanvas = expandedCanvasSize + expandedCanvasSize * MAX_SCROLL_MULTIPLIER;

  newScribe.elements.forEach(el => {
    if (elementsToUpdate.includes(el.id)) {
      let { x, y, width, height, scaleX, scaleY } = el;

      if (el.type === 'Camera') {
        height = canvasHeight / el.scale;
        width = canvasWidth / el.scale;
        scaleX = scaleY = 1;

        if (axis === 'x') {
          if (x + modifier < minCanvas) {
            modifier = minCanvas;
          } else if (x + width * scaleX + modifier > maxCanvas) {
            modifier = maxCanvas - width * scaleX - x;
          }
        } else if (axis === 'y') {
          if (y + modifier < minCanvas) {
            modifier = minCanvas;
          } else if (y + height * scaleY + modifier > maxCanvas) {
            modifier = maxCanvas - height * scaleX - y;
          }
        }
      }

      if (axis === 'x') {
        if (x + modifier < minCanvas) {
          modifier = minCanvas - x;
        } else if (x + width * scaleX + modifier > maxCanvas) {
          modifier = maxCanvas - width * scaleX - x;
        }
      } else if (axis === 'y') {
        if (y + modifier < minCanvas) {
          modifier = minCanvas - y;
        } else if (y + height * scaleY + modifier > maxCanvas) {
          modifier = maxCanvas - height * scaleX - y;
        }
      }
    }
  });

  newScribe.elements.forEach((el, index) => {
    if (elementsToUpdate.includes(el.id)) {
      newScribe.elements[index][axis] = newScribe.elements[index][axis] + modifier;
    }
  });

  let firstArrowPressed = yield select(state => state.ui.firstArrowPressed);
  if (!firstArrowPressed) {
    yield put(toggleFirstArrowPress());
    yield put(updateScribeAction(newScribe));
  } else {
    yield put(updateScribeAction(newScribe, true));
  }

  delayedLastArrowCheckCall = yield delay(500);
  yield delayedLastArrowPressCheck();
}

function* delayedLastArrowPressCheck() {
  yield put(toggleFirstArrowPress());
}

function* scribeSagas() {
  yield takeLatest(ADD_SCRIBE_REQUESTED, addScribe);
  yield takeEvery(CREATE_ELEMENT, createElement);
  yield takeEvery(CREATE_TEXT_ELEMENT, createTextElement);
  yield takeEvery(CREATE_SHAPE_ELEMENT, createShapeElement);
  yield takeEvery(CREATE_IMAGE_ELEMENT, createImageElement);
  yield takeEvery(CREATE_SCENE, createScene);
  yield takeLatest(EDITOR_PASTE, handleEditorPaste);
  yield takeLatest(EDITOR_CUT, cut);
  yield takeLatest(EDITOR_UNDO, undo);
  yield takeLatest(EDITOR_REDO, redo);
  yield takeLatest(MOVE_ELEMENT_ON_TIMELINE, moveElementOnTimeline);
  yield takeLatest(DELETE_ACTIVE_ELEMENT, deleteActiveElement);
  yield takeLatest(LOAD_SCRIBES, loadScribes);
  yield takeLatest(DELETE_SCRIBE, deleteScribe);
  yield takeLatest(SORT_SCRIBES, sortScribes);
  yield takeLatest(DELETE_MULTIPLE_ELEMENTS_BY_ID, deleteMultipleElementsById);
  yield takeLatest(LOAD_SCRIBE_BY_ID, loadScribeById);
  yield takeLatest(LOAD_CURSORS, loadCursors);
  yield takeLatest(UPDATE_SCRIBE_CURSOR, updateScribeCursor);
  yield takeLatest([LOCK_ELEMENTS, UNLOCK_ELEMENTS], updateElementsLock);
  yield takeLatest([UNLOCK_ACTIVE_ELEMENTS, LOCK_ACTIVE_ELEMENTS], updateLockOnActiveElements);
  yield takeLatest([PIN_CAMERAS, UNPIN_CAMERAS], updateCamerasPinned);
  yield takeLatest([PIN_ACTIVE_CAMERAS, UNPIN_ACTIVE_CAMERAS], updateCamerasPinnedOnActiveElements);
  yield takeLatest(TOGGLE_CAMERA_PINS, toggleActiveSceneCameraPins);
  yield takeLatest(SET_ELEMENTS_TIMINGS, updateElementsTimings);
  yield takeLatest(SET_ELEMENTS_LOOPS, updateElementsLoops);
  yield takeLatest(SET_ACTIVE_SCRIBE, prepScribeForEditor);
  yield takeLatest(UPDATE_SCENE_ELEMENTS, updateSceneElements);
  yield takeLatest(UPDATE_SCENE_THUMBNAIL, updateSceneThumbnail);
  yield takeLatest(DUPLICATE_SELECTED_SCENES, duplicateScenes);
  yield takeLatest(DELETE_SELECTED_SCENES, deleteSelectedScenes);
  yield takeLatest(UPDATE_SCENE, updateScene);
  yield takeLatest(CHANGE_SCENE_ORDER, changeSceneOrder);
  yield takeLatest(SELECT_ALL, selectAll);
  yield takeLatest(SET_ACTIVE_ELEMENTS_VISIBILITY, setActiveElementsVisibility);
  yield takeLatest(SET_ELEMENTS_VISIBILITY, setElementsVisibility);
  yield takeLatest(ON_MOVE_WITH_KEYS, moveElementsWithKeys);
  yield takeLatest(SET_SELECTED_AUDIO_CLIP, audioClipSelected);
  yield fork(duplicateScribeSagas);
  yield fork(updateProjectTitleSaga);
  yield takeLatest(UPDATE_SCRIBE_FAILED, updateScribeFailed);
}

export default scribeSagas;
