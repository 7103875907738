import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { LoadPageOfUserImagesAction, loadPageOfUserImagesSuccess } from 'js/actionCreators/imagesActions';
import { RootState, UserImageLibrary, VSCAssetImageSourceName } from 'js/types';
import { loadPageOfUserImages as loadPageOfUserImagesAction } from 'js/actionCreators/imagesActions';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { showError } from 'js/actionCreators/uiActions';

interface GetAssetUrlsResponse {
  assetId: string;
  blobUrl: string;
}

export function* initialLoadOfUserLibrary() {
  for (const imageType of Object.values(VSCAssetImageSourceName)) {
    yield put(loadPageOfUserImagesAction({ imageType, sortedByLastUsed: false }));
  }
  for (const imageType of Object.values(VSCAssetImageSourceName)) {
    yield put(loadPageOfUserImagesAction({ imageType, sortedByLastUsed: true }));
  }
}

const PAGE_SIZE = 30;
export function* loadPageOfUserLibrary({
  imageType,
  sortedByLastUsed
}: LoadPageOfUserImagesAction): Generator<
  SelectEffect | PutEffect | CallEffect,
  void,
  UserImageLibrary | GetAssetUrlsResponse[]
> {
  try {
    const { sourceAssets: assets, images, imagesSortedByLastUsed } = (yield select(
      (state: RootState) => state.images.userLibrary[imageType]
    )) as UserImageLibrary;

    if (!assets || !assets.length) {
      yield put(loadPageOfUserImagesSuccess(imageType, []));
    }

    const alreadyLoadedImages = sortedByLastUsed ? imagesSortedByLastUsed : images;
    const assetsToSlice = sortedByLastUsed
      ? assets.toSorted((a, b) => {
          const dateA = new Date(a.lastUsed || a.createdDate);
          const dateB = new Date(b.lastUsed || b.createdDate);
          return dateB.getTime() - dateA.getTime();
        })
      : assets;

    const start = alreadyLoadedImages.length;
    const end = Math.min(start + PAGE_SIZE, assets.length);
    const assetSlice = assetsToSlice.slice(start, end);
    const assetIds = assetSlice.map(asset => asset.projectAssetId);

    const signedUrls = (yield call(
      { fn: appServices.getAssetUrls, context: appServices },
      assetIds,
      true
    )) as GetAssetUrlsResponse[];

    const newImages = assetSlice.map((asset, index) => ({ ...asset, url: signedUrls[index].blobUrl }));

    yield put(loadPageOfUserImagesSuccess(imageType, newImages, sortedByLastUsed));
  } catch (error) {
    console.error(error);
    yield put(
      showError({
        message: 'There was an issue loading your images.',
        description: `Error: ${(error as Error).message}`
      })
    );
  }
}
