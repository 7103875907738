import { MAX_NUMBER_OF_ELEMENTS, MAX_NUMBER_OF_SCENES, MAX_SCRIBE_LENGTH_LONG_IN_MS } from 'js/config/defaults';
import { ExistingScribeModel } from 'js/types';

import { appServices } from './app-services/AppServices';

export const getNumberOfImageElements = (project: ExistingScribeModel) => {
  return project.elements.reduce((acc, el) => {
    return el.type === 'Image' ? acc + 1 : acc;
  }, 0);
};

export const numberOfElementsExceedsLimits = (numberOfElements: number, limit = MAX_NUMBER_OF_ELEMENTS) => {
  return numberOfElements > limit;
};

export const numberOfScenesExceedsLimits = (numberOfScenes: number, limit = MAX_NUMBER_OF_SCENES) => {
  return numberOfScenes > limit;
};

export const projectLengthExceedsLimits = (projectLengthInMs: number, limit = MAX_SCRIBE_LENGTH_LONG_IN_MS) => {
  return projectLengthInMs > limit;
};

export const percentage = (min: number, max: number) => Math.ceil((min / max) * 100);

export const textForDisplay = (min: number | string, max: number | string) => `${min} / ${max}`;

export const getLimitInfo = (min: number, max: number) => ({
  textForDisplay: textForDisplay(min, max),
  percentage: percentage(min, max)
});

type IconTimeMap = {
  [key: number]: string;
};

export const getDurationLimitIcon = (currentDuration: number, iconMap: IconTimeMap) => {
  const keys = Object.keys(iconMap)
    .map(Number)
    .sort();
  let icon: string | undefined = undefined;
  for (const threshold of keys) {
    if (currentDuration > threshold) {
      icon = iconMap[threshold];
    } else {
      return icon;
    }
  }
};

export const getAudioAssetsSize = async (assetIds: number[]) => {
  try {
    const assetUrls = await appServices.getAssetUrls(assetIds);
    const sizes = await Promise.all(
      assetUrls.map(asset => {
        return fetch(asset.blobUrl)
          .then(res => res.blob())
          .then(blob => blob.size)
          .catch(() => 0);
      })
    );

    const total = sizes.reduce((acc, size) => acc + size, 0);
    return total;
  } catch (error) {
    console.error('Error calculating asset sizes', error);
    return 0;
  }
};
