import { AudioClip } from 'js/types';
import * as PIXI from 'pixi.js';
import { getActiveScene } from 'js/shared/helpers/scenesHelpers';
import { getSceneStartEndTimes } from 'js/playback/lib/Playback/helpers/timings';
import IconSceneAudio from 'imgs/pixi_icons/IconSceneAudio.svg';
import IconProjectAudio from 'imgs/pixi_icons/IconProjectAudio.svg';

import TimelineAudioWaveform, { soundsPool } from './TimelineAudioWaveform';
import TimelineWrapper, { TIMELINE_LAYER_ACTIVE_LINE_WIDTH } from './TimelineWrapper';
import timelineBus, {
  TOOLTIP_HIDE,
  TOOLTIP_SHOW,
  SHOW_AUDIO_LENGTH_ADJUST_INDICATOR,
  HIDE_AUDIO_LENGTH_ADJUST_INDICATOR,
  UPDATE_AUDIO_LENGTH_ADJUST_INDICATOR,
  UPDATE_AUDIO_DURATION
} from './TimelineControlBus';
import { TOOLTIP_ALIGN_CENTRAL } from './TimelineLayerTooltip';
import TimelineHelpers from './Utils/TimelineHelpers';

const ICON_PADDING = 1;
const ICON_SIZE = 16;
export default class TimelineAudioClip extends PIXI.Container {
  showSplitMode() {
    this.updateSelectedSurround();
  }
  tooltipShowing: boolean;
  leftHandle?: PIXI.Graphics;
  rightHandle?: PIXI.Graphics;
  selectedColour = 0xffb434;
  selectedOutline?: PIXI.Graphics;
  draggedHandle?: PIXI.Graphics;
  isSelected: boolean;
  icon?: PIXI.Sprite;
  audioClip: AudioClip;
  waveform: TimelineAudioWaveform;
  wrapperRef: TimelineWrapper;
  background: PIXI.Graphics;
  onAudioStartOffsetChanged: (clip: AudioClip, startOffset: number) => void;
  onAudioLengthChanged: (clip: AudioClip, instanceDuration: number) => void;

  mouseoverTarget: PIXI.Graphics;
  startOffset = 0;
  waveformMask: PIXI.Graphics;
  otherClipsInLayer: TimelineAudioClip[] | undefined;
  constructor(
    audioClip: AudioClip,
    wrapperRef: TimelineWrapper,
    onAudioLengthChanged: (clip: AudioClip, instanceDuration: number) => void,
    onAudioStartOffsetChanged: (clip: AudioClip, startOffset: number) => void
  ) {
    super();

    this.onAudioLengthChanged = onAudioLengthChanged;
    this.onAudioStartOffsetChanged = onAudioStartOffsetChanged;
    this.audioClip = audioClip;
    this.wrapperRef = wrapperRef;
    this.background = new PIXI.Graphics();
    this.addChild(this.background);
    this.updateBackground();

    this.waveform = new TimelineAudioWaveform(
      this.audioClip.assetId.toString(),
      wrapperRef.currentZoom,
      (wrapperRef.app.renderer as PIXI.Renderer).gl
    );
    this.waveform.on('waveformGenerated', () => {
      if (this.audioClip.duration === undefined && soundsPool[this.audioClip.assetId.toString()]) {
        timelineBus.emit(UPDATE_AUDIO_DURATION, this.audioClip, soundsPool[this.audioClip.assetId.toString()].duration);
      }
      this.waveform.off('waveformGenerated');
    });

    this.waveform.init();

    this.addChild(this.waveform);

    this.updatePosition();
    this.y = 0;
    this.waveform.generate();
    this.mouseoverTarget = new PIXI.Graphics();
    this.waveformMask = new PIXI.Graphics();
    this.updateWaveformMask();

    this.tooltipShowing = false;

    this.interactive = true;
    this.interactiveChildren = true;
    this.addChild(this.mouseoverTarget);
    this.isSelected = false;
    this.updateProps(audioClip);
  }
  get type() {
    return this.audioClip.type;
  }
  get sceneOffsets() {
    if (!this.wrapperRef.props.activeScribe) return { startTime: 0, endTime: 0 };
    const scene = getActiveScene(this.wrapperRef.props.activeScribe);
    return getSceneStartEndTimes(this.wrapperRef.props.activeScribe, scene);
  }

  tintWaveform(color: number | undefined) {
    const colorMatrix = new PIXI.filters.ColorMatrixFilter();

    // Set the tint to a desired color, for example, red
    if (color) {
      colorMatrix.tint(color);
      this.waveform.filters = [colorMatrix];
    } else {
      this.waveform.filters = [];
    }
    this.updateIcon();
  }

  updateIcon() {
    if (this.icon) {
      this.removeChild(this.icon);
      this.icon?.destroy();
    }
    if (this.wrapperRef.currentZoom.y < ICON_PADDING + ICON_SIZE || this.waveformMask.width < ICON_PADDING + ICON_SIZE)
      return;
    this.icon = new PIXI.Sprite(TimelineHelpers.importSVG(this.type === 'scene' ? IconSceneAudio : IconProjectAudio));
    this.addChild(this.icon);
    this.icon.width = this.icon.height = ICON_SIZE;
    this.icon.x = this.icon.y = ICON_PADDING;
  }

  updatePosition() {
    if (this.type === 'scene') {
      this.x = (this.sceneOffsets.startTime / 1000 + (this.audioClip.startTime ?? 0)) * this.wrapperRef.currentZoom.x;
    } else {
      this.x = (this.audioClip.startTime ?? 0) * this.wrapperRef.currentZoom.x;
    }
  }
  updateWaveformMask() {
    this.waveformMask.clear();

    this.waveformMask.beginFill(0xff0000);
    this.waveformMask.drawRect(
      0,
      0,
      this.wrapperRef.currentZoom.x * (this.audioClip.instanceDuration ?? this.audioClip.duration ?? 0),
      this.wrapperRef.currentZoom.y - 1
    );
    this.waveformMask.endFill();

    this.addChild(this.waveformMask);
    this.waveform.mask = this.waveformMask;
    this.waveform.x = -this.startOffset;
  }

  showTooltip(e: PIXI.InteractionEvent) {
    this.updateTooltip(e);
    this.tooltipShowing = true;
  }

  hideTooltip() {
    if (!this.tooltipShowing) return;

    this.tooltipShowing = false;
    timelineBus.emit(TOOLTIP_HIDE);
  }
  onPointerMove(e: PIXI.InteractionEvent) {
    if (this.tooltipShowing) {
      this.updateTooltip(e);
    }
  }
  updateZoom(zoom: PIXI.Point) {
    this.waveform.updateZoom(zoom);
    this.updateWaveformMask();
    this.draw();
    this.updateSelectedSurround();
    if (this.leftHandle && this.rightHandle) {
      this.updateHandleGraphics(true, this.leftHandle);
      this.updateHandleGraphics(false, this.rightHandle);
    }
    if (this.dragGraphic?.visible) {
      this.getDragGraphic();
    }
  }
  removeDragGraphic() {
    if (this.dragGraphic) {
      this.dragGraphic.visible = false;
      if (this.dragGraphic.parent) {
        this.dragGraphic.parent.removeChild(this.dragGraphic);
      }
    }
  }
  private dragGraphic: PIXI.Graphics | undefined;
  getDragGraphic(): PIXI.Graphics {
    if (!this.dragGraphic) {
      this.dragGraphic = new PIXI.Graphics();
    }
    const width = this.wrapperRef.currentZoom.x * (this.audioClip.instanceDuration ?? this.audioClip.duration ?? 0);
    const height = this.wrapperRef.currentZoom.y - 1;
    if (this.dragGraphic.width !== width || this.dragGraphic.height !== height) {
      this.dragGraphic.clear();
      this.dragGraphic.beginFill(this.selectedColour, 0.3);
      this.dragGraphic.lineStyle({
        width: 1,
        color: this.selectedColour,
        native: true
      });
      this.dragGraphic.drawRoundedRect(
        0,
        0,
        this.wrapperRef.currentZoom.x * (this.audioClip.instanceDuration ?? this.audioClip.duration ?? 0),
        this.wrapperRef.currentZoom.y - 1,
        1
      );
    }
    this.dragGraphic.visible = true;
    return this.dragGraphic;
  }
  updateTooltip(e: PIXI.InteractionEvent) {
    if (this.wrapperRef.audioClipManager?.splitTrackManager.splitMode) return;
    const position = this.toLocal(e.data.global);
    this.mouseoverTarget.x = position.x;
    this.mouseoverTarget.y = position.y - 20;

    e.target = this.mouseoverTarget;

    timelineBus.emit(
      TOOLTIP_SHOW,
      e,
      [
        this.type === 'scene' ? 'Scene audio' : 'Project audio',
        this.audioClip.filename,
        'Duration: ' + TimelineHelpers.roundNumber(this.audioClip.instanceDuration ?? this.audioClip.duration) + 's'
      ],
      TOOLTIP_ALIGN_CENTRAL,
      this.wrapperRef.currentZoom.y,
      null
    );
  }

  updateDragIndicator(position: PIXI.Point, isStart: boolean) {
    timelineBus.emit(UPDATE_AUDIO_LENGTH_ADJUST_INDICATOR, position, this.audioClip, isStart);
  }
  showDragIndicator() {
    if (!this.draggedHandle) return;
    const indicatorPosition = this.toGlobal(this.draggedHandle.position);
    indicatorPosition.y += this.wrapperRef.currentZoom.y / 2;
    timelineBus.emit(
      SHOW_AUDIO_LENGTH_ADJUST_INDICATOR,
      indicatorPosition,
      this.audioClip,
      this.draggedHandle === this.leftHandle
    );
  }
  hideDragIndicator() {
    timelineBus.emit(
      HIDE_AUDIO_LENGTH_ADJUST_INDICATOR,
      this.draggedHandle?.position,
      this.audioClip,
      this.draggedHandle === this.leftHandle
    );
  }
  updateBackground() {
    if (
      this.background.width ===
        this.wrapperRef.currentZoom.x * (this.audioClip.instanceDuration ?? this.audioClip.duration ?? 0) &&
      this.background.height === this.wrapperRef.currentZoom.y - 1
    ) {
      return;
    }

    this.background.clear();
    this.background.beginFill(0x707070);
    this.background.drawRect(
      0,
      0,
      this.wrapperRef.currentZoom.x * (this.audioClip.instanceDuration ?? this.audioClip.duration ?? 0),
      this.wrapperRef.currentZoom.y - 1
    );
  }
  updateProps(clipModel: AudioClip | undefined) {
    this.updateAudioClip(clipModel ?? this.audioClip);
    this.removeAllListeners();
    this.on('pointerover', this.showTooltip)
      .on('pointerout', this.hideTooltip)
      .on('pointerdown', this.hideTooltip)
      .on('pointermove', this.onPointerMove);
    this.hideDragIndicator();
    this.hideSelected();
  }

  updateAudioClip(audioClip: AudioClip) {
    this.audioClip = audioClip;
    this.updatePosition();
    this.draw();
  }

  draw() {
    if (this.type === 'scene') {
      this.x = (this.sceneOffsets.startTime / 1000 + (this.audioClip.startTime ?? 0)) * this.wrapperRef.currentZoom.x;
    } else {
      this.x = (this.audioClip.startTime ?? 0) * this.wrapperRef.currentZoom.x;
    }
    this.y = 0;
    this.startOffset = (this.audioClip.startOffset ?? 0) * this.wrapperRef.currentZoom.x;
    this.waveform.x = -this.startOffset;
    this.updateBackground();
    if (this.isSelected) {
      this.addResizeHandles();
      this.updateSelectedSurround();
      if (this.selectedOutline) {
        this.addChild(this.selectedOutline);
      }
    }
  }

  showSelected() {
    if (this.isSelected === false) {
      this.isSelected = true;
      this.addResizeHandles();
      if (!this.selectedOutline) {
        this.selectedOutline = new PIXI.Graphics();
      }
      this.updateSelectedSurround();
      this.addChild(this.selectedOutline);
    }
  }

  updateSelectedSurround() {
    if (!this.selectedOutline) this.selectedOutline = new PIXI.Graphics();
    if (
      this.selectedOutline.width !==
        this.wrapperRef.currentZoom.x * (this.audioClip.instanceDuration ?? this.audioClip.duration ?? 0) ||
      this.selectedOutline.height !== this.wrapperRef.currentZoom.y - 1
    ) {
      this.selectedOutline.clear();
      this.selectedOutline.lineStyle(TIMELINE_LAYER_ACTIVE_LINE_WIDTH, this.selectedColour);
      this.selectedOutline.drawRoundedRect(
        0,
        0,
        this.wrapperRef.currentZoom.x * (this.audioClip.instanceDuration ?? this.audioClip.duration ?? 0),
        this.wrapperRef.currentZoom.y - 1,
        1
      );
    }
  }
  addResizeHandles() {
    if (!this.leftHandle) this.leftHandle = new PIXI.Graphics();
    if (!this.rightHandle) this.rightHandle = new PIXI.Graphics();
    this.updateHandleGraphics(true, this.leftHandle);
    this.addHandleListeners(this.leftHandle);

    this.updateHandleGraphics(false, this.leftHandle);
    this.addHandleListeners(this.rightHandle);

    this.addChild(this.leftHandle);
    this.addChild(this.rightHandle);
  }
  addHandleListeners(handle: PIXI.Graphics) {
    handle.removeAllListeners();
    handle.interactive = true;
    handle.buttonMode = true;
    handle.cursor = 'audioDrag';
    handle.on('pointerdown', this.handleStartDrag);
  }
  handleStartDrag = (e: PIXI.InteractionEvent) => {
    this.draggedHandle = (e.target as unknown) as PIXI.Graphics;
    this.showDragIndicator();

    //adding to top level timeline display object to ensure it always gets the pointermove event
    this.wrapperRef.timelineHolder?.on('pointermove', this.handleDoDrag);
    this.wrapperRef.timelineHolder?.on('pointerup', this.handleStopDrag);
    this.wrapperRef.timelineHolder?.on('pointerupoutside', this.handleStopDrag);
    this.draggedHandle.on('pointerup', this.handleStopDrag);
    this.draggedHandle.on('pointerupoutside', this.handleStopDrag);

    this.otherClipsInLayer = this.wrapperRef.audioClipManager
      ?.getAudioClipLayer(this)
      ?.timelineAudioClips.filter(clip => clip !== this);
  };

  handleSplitDrag = (e: PIXI.InteractionEvent) => {
    const indicatorPosition = e.data.global;
    this.updateDragIndicator(indicatorPosition, false);
  };

  handleDoDrag = (e: PIXI.InteractionEvent) => {
    const clipsToTheRight = this.otherClipsInLayer?.filter(clip => clip.x > this.x);
    clipsToTheRight?.sort((a, b) => a.x - b.x);
    const clipsToTheLeft = this.otherClipsInLayer?.filter(clip => clip.x < this.x);
    clipsToTheLeft?.sort((a, b) => b.x - a.x);
    const nextClipToTheRight = clipsToTheRight?.[0];
    const nextClipToTheLeft = clipsToTheLeft?.[0];

    const minDragLength = 0.1 * this.wrapperRef.currentZoom.x;
    if (this.draggedHandle) {
      const position = this.toLocal(e.data.global);
      if (this.draggedHandle === this.rightHandle) {
        const max = Math.min(
          (nextClipToTheRight?.x ?? Infinity) - this.x,
          this.wrapperRef.currentZoom.x * ((this.audioClip.duration ?? 0) - (this.audioClip.startOffset ?? 0))
        );
        const min = minDragLength;
        if (position.x > max) position.x = max;
        if (position.x < min) position.x = min;
      }
      if (this.draggedHandle === this.leftHandle) {
        const max = this.wrapperRef.currentZoom.x * (this.audioClip.instanceDuration ?? 0) - minDragLength;
        const min = Math.max((nextClipToTheLeft?.x ?? 0) + (nextClipToTheLeft?.width ?? 0) - this.x, -this.startOffset);
        if (position.x > max) position.x = max;
        if (position.x < min) {
          position.x = min;
        }
      }

      this.draggedHandle.x = position.x;
      const indicatorPosition = this.toGlobal(this.draggedHandle.position);
      indicatorPosition.y += this.wrapperRef.currentZoom.y / 2;
      this.updateDragIndicator(indicatorPosition, this.draggedHandle === this.leftHandle);
    }
  };

  handleStopDrag = (_e: PIXI.InteractivePointerEvent) => {
    this.wrapperRef.timelineHolder?.off('pointermove', this.handleDoDrag);
    this.wrapperRef.timelineHolder?.off('pointerup', this.handleStopDrag);
    this.wrapperRef.timelineHolder?.off('pointerupoutside', this.handleStopDrag);
    if (this.draggedHandle) {
      this.draggedHandle.off('pointerup', this.handleStopDrag);
      this.draggedHandle.off('pointerupoutside', this.handleStopDrag);
      if (this.draggedHandle === this.rightHandle) {
        this.onAudioLengthChanged(this.audioClip, this.draggedHandle.x / this.wrapperRef.currentZoom.x);
      } else if (this.draggedHandle === this.leftHandle) {
        this.onAudioStartOffsetChanged(
          this.audioClip,
          (this.draggedHandle.x + this.startOffset) / this.wrapperRef.currentZoom.x
        );
      }
    }

    this.hideDragIndicator();
    this.draggedHandle = undefined;
  };

  updateHandleGraphics(leftHand: boolean, currentGfx: PIXI.Graphics) {
    let positionOffset = 0;
    if (this.background.width < 21) {
      positionOffset = 8;
    }
    const handle = currentGfx;
    if (currentGfx.height !== this.wrapperRef.currentZoom.y) {
      handle.clear();
      handle.beginFill(this.selectedColour, 0.3);
      handle.drawRoundedRect(leftHand ? 0 : -8, 0, 8, this.wrapperRef.currentZoom.y, 1);
      handle.moveTo(leftHand ? 4 : -4, this.wrapperRef.currentZoom.y / 3);
      handle.lineStyle({ width: 1, color: this.selectedColour, native: true });
      handle.lineTo(leftHand ? 4 : -4, this.wrapperRef.currentZoom.y * (2 / 3));
    }
    if (leftHand) handle.x = -positionOffset;
    if (!leftHand) handle.x = this.background.width + positionOffset;
  }

  hideSelected() {
    if (this.dragGraphic) this.removeChild(this.dragGraphic);
    if (this.leftHandle) this.removeChild(this.leftHandle);
    if (this.rightHandle) this.removeChild(this.rightHandle);
    if (this.selectedOutline) {
      this.removeChild(this.selectedOutline);
    }
    this.isSelected = false;
  }
}
